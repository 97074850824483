import React, { useContext, useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import MainSidebar from "../components/sidebar";
import myContext from "../context/myContext";
import HeaderComponent from "../components/HeaderComponent";
import CustomTable from "../components/CustomTable";
import { Outlet } from "react-router-dom";
import useScreenSize from "../utils/useScreenSize";
const { Header, Sider, Content } = Layout;

const MainLayouts = () => {
  const { collapsed, setCollapsed } = useContext(myContext);
  const {isMobile}=useScreenSize();
  console.log(isMobile)
  useEffect(()=>{
    if(isMobile)setCollapsed(true);
  },[isMobile])

  return (
    <Layout className="h-screen w-screen flex fixed font-calibri">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="sticky hidden md:block top-0"
        width={250}
        collapsedWidth={85}
        theme="light"
      >
        <div
          className="demo-logo-vertical"
          style={{ backgroundColor: "#FFFFFF" }}
        />
        <MainSidebar />
      </Sider>
      <Layout>
        <Header className="bg-[#fff] p-0 m-0">
          <HeaderComponent />
        </Header>
        <Content
          className="overflow-auto"
          style={{
            padding: "24px 16px",
            // minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayouts;
