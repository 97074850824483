import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Button, Input, Modal, Select } from "antd";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchLead_list, fetchService_list } from "../../redux/slice/dataSlice";
import CustomInput, { InputInline } from "../../components/CustomInput";
import { BiTrash } from "react-icons/bi";
import QuotationFormat from "../../components/QuotationFomat";
import { generateQuotationNumber } from "../../utils/utils";
import { GoPlus } from "react-icons/go";
import { CustomModal } from "../../components/modals";
import CustomTable from "../../components/CustomTable";

const Quotation = () => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const quotationRef = useRef();
  const navigate = useNavigate();
  const { lead_list, lead_loading, services_list } = useSelector(
    (state) => state.allData
  );
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [quotation_detail, setQuotation_detail] = useState({});
  const [errors, setErrors] = useState({});
  const [selected_services, setSelected_services] = useState([]);
  const [show_preview, setShow_preview] = useState(false);
  const [singleData, setSingleData] = useState({
    service: {},
    value: "",
    qty: "",
    price: "",
  });

  const headerData = ["S.No", "Service Description", "Qty", "Price", "Actions"];

  const bodyData = selected_services.map((item, index) => (
    <tr key={index}>
      <td className="py-2 px-4 border-b">{index + 1}</td>
      <td className="py-2 px-4 border-b">{item.service?.label}</td>
      <td className="py-2 px-4 border-b">{item.qty}</td>
      <td className="py-2 px-4 border-b">{item.price}</td>
      <td className="py-2 px-4 border-b">
        <BiTrash
          size={20}
          color="#000"
          className="cursor-pointer"
          onClick={() => removeService(index)}
        />
      </td>
    </tr>
  ));

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Basic validations
    const validations = {
      COMPANY_NAME: /^[A-Za-z\s]+$/.test(value),
      CONTACT_PERSON: /^[A-Za-z\s]+$/.test(value),
      EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      CONTACT_NUMBER: /^\d{10,10}$/.test(value),
      POSTCODE: /^\d{5,6}$/.test(value),
    };
  
    if (validations[name] !== undefined) {
      if (!validations[name]) {
        setErrors((prev) => ({
          ...prev,
          [name]: `Invalid ${name.replace("_", " ").toLowerCase()}`,
        }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    }
  
    setQuotation_detail((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
  };

  useEffect(() => {
    store.dispatch(fetchLead_list());
  }, []);
  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({
      ID: [detail.ID],
    });
    const res = await postApiToken(`leads/delete-lead`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchLead_list());
      setIsModalOpen(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  useEffect(() => {
    store.dispatch(fetchService_list());
  }, []);

  const allData = () => {
    const options = [];
    for (let i = 0; i < services_list?.length; i++) {
      if (
        !selected_services
          ?.map((item) => item?.value)
          .includes(services_list?.ID)
      ) {
        options.push({
          value: services_list[i]?.ID,
          label: services_list[i]?.SERVICE_NAME,
          desc: services_list[i]?.DESCRIPTION,
        });
      }
    }
    return options;
  };
  const getSubServicePriceById = (subServiceId, qty) => {
    for (let i = 0; i < services_list.length; i++) {
      if (services_list[i].ID === subServiceId) {
        return services_list[i].PRICE * qty;
      }
    }
    return null; // Return null if no matching sub-service is found
  };

  const removeService = (index) => {
    setSelected_services((prev) => prev.filter((item, i) => i !== index));
  };

  const onAdd = () => {
    if (!singleData.value || !singleData.price || !singleData.qty) {
      openNotification("info", "Info", "Please fill all the fields");
      return;
    }
    let flag = false;
    for (let i = 0; i < selected_services.length; i++) {
      if (selected_services[i]?.service === singleData?.service) {
        flag = true;
        break;
      }
    }
    if (!flag) {
      setSelected_services([...selected_services, singleData]);
      setSingleData({
        service: {},
        value: "",
        qty: "",
        price: "",
      });
    }
  };
  const quotationNumber = generateQuotationNumber({
    invoiceNumber: 1,
    consumerFirstName: quotation_detail?.CONTACT_PERSON,
    consumerLastName: "L",
    date: new Date(),
    quotationCountForUser: 1,
  });
  const handlePreview = () => {
    console.log(selected_services, quotation_detail, "sldfj");

    if (
      !quotation_detail.EMAIL ||
      !quotation_detail?.COMPANY_NAME ||
      !quotation_detail?.CONTACT_PERSON ||
      !quotation_detail?.FULL_ADDRESS ||
      !quotation_detail?.STATE ||
      !quotation_detail?.POSTCODE ||
      !quotation_detail?.CONTACT_NUMBER ||
      !quotation_detail?.CITY ||
      !quotation_detail?.REFERENCE
    ) {
      openNotification("info", "Info", "Please fill all the fields");
      return;
    }

    let flag = false;
    for (let i = 0; i < selected_services.length; i++) {
      if (selected_services[i]?.service === singleData?.service) {
        flag = true;
        break;
      }
    }
    if (singleData?.service && !flag) {
      setSelected_services([...selected_services, singleData]);
      setSingleData({
        service: {},
        value: "",
        qty: "",
        price: "",
      });
    }

    setShow_preview(true);
  };

  return (
    <div className="bg-white p-4">
      <h1 className="text-[20px] font-semibold mb-4 text-center">
        QUICK QUOTATION
      </h1>
      <div className="gap-3 flex flex-col">
        <InputInline
          label={"Company Name"}
          placeholder={"Enter Company Name"}
          value={quotation_detail?.COMPANY_NAME || ""}
          error={errors?.COMPANY_NAME}
          name={"COMPANY_NAME"}
          onChange={handleChange}
        />
        <InputInline
          label={"Full Address"}
          placeholder={"Enter Full Address"}
          value={quotation_detail?.FULL_ADDRESS || ""}
          error={errors?.FULL_ADDRESS}
          name={"FULL_ADDRESS"}
          onChange={handleChange}
        />
        <InputInline
          label={"Contact Person"}
          placeholder={"Enter Contact Person"}
          value={quotation_detail?.CONTACT_PERSON || ""}
          error={errors?.CONTACT_PERSON}
          name={"CONTACT_PERSON"}
          onChange={handleChange}
        />
        <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
          <InputInline
            label={"State"}
            placeholder={"Enter State"}
            value={quotation_detail?.STATE || ""}
            error={errors?.STATE}
            name={"STATE"}
            onChange={handleChange}
          />
          <InputInline
            label={"Postcode"}
            placeholder={"Enter Postcode"}
            value={quotation_detail?.POSTCODE || ""}
            error={errors?.POSTCODE}
            name={"POSTCODE"}
            onChange={handleChange}
          />
          <InputInline
            label={"City / Country"}
            placeholder={"Enter City / Country"}
            value={quotation_detail?.CITY || ""}
            error={errors?.CITY}
            name={"CITY"}
            onChange={handleChange}
          />
          <InputInline
            label={"Contact No"}
            placeholder={"Enter Contact No"}
            value={quotation_detail?.CONTACT_NUMBER || ""}
            error={errors?.CONTACT_NUMBER}
            name={"CONTACT_NUMBER"}
            onChange={handleChange}
          />

          <InputInline
            label={"E-Mail"}
            placeholder={"Enter E-Mail"}
            value={quotation_detail?.EMAIL || ""}
            error={errors?.EMAIL}
            name={"EMAIL"}
            onChange={handleChange}
          />
          <InputInline
            label={"Reference"}
            placeholder={"Enter Reference"}
            value={quotation_detail?.REFERENCE || ""}
            error={errors?.REFERENCE}
            name={"REFERENCE"}
            onChange={handleChange}
          />
        </div>
        <div>
          {selected_services?.length>0&&<CustomTable
            headerData={headerData}
            bodyData={bodyData}
            list={selected_services}
            check={false} // Disable checkbox column
          />}
          <div className="flex items-center w-full gap-4 mb-2 mt-4">
            <div className="w-[25px] min-w-fit font-calibri">
              {selected_services?.length + 1}
            </div>
            <div className="flex-[0.7] max-w-[90px] md:max-w-full">
              <Select
                showSearch
                variant="borderless"
                style={{
                  width: "100%",
                }}
                placeholder="Select Services"
                className="w-full flex-1 bg-[#f1f1f1] rounded-md"
                options={allData()}
                onChange={(v, item) =>
                  setSingleData({ ...singleData, service: item, value: v })
                }
                value={singleData.value}
                size="middle"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                dropdownStyle={{ minWidth: "230px" }}
              />
            </div>
            <div className="flex-[0.5]">
              <Input
                className="bg-[#f1f1f1]"
                placeholder="Qty"
                size="middle"
                variant="borderless"
                type="number"
                onChange={(v) =>
                  setSingleData({
                    ...singleData,
                    qty: v.target.value,
                    price: getSubServicePriceById(
                      singleData.value,
                      v.target.value
                    ),
                  })
                }
                value={singleData.qty}
              />
            </div>
            <div className="flex-[0.5]">
              <Input
                className="bg-[#f1f1f1]"
                placeholder="Price"
                size="middle"
                variant="borderless"
                type="number"
                onChange={(v) =>
                  setSingleData({ ...singleData, price: v.target.value })
                }
                value={singleData.price}
              />
            </div>
            <div className="flex-[0.3]">
              <GoPlus
                size={25}
                className="cursor-pointer text-[#0363B0]"
                onClick={() => onAdd()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" flex justify-center mt-4">
        <CustomButton
          title="PreView"
          onClick={() => {
            console.log("akldlfj");
            handlePreview();
          }}
        />
        {/* <CustomButton title="Submit" onClick={() => generatePDF()} /> */}
      </div>

      <CustomModal
        A4={true}
        title=""
        isModalOpen={show_preview}
        setIsModalOpen={setShow_preview}
      >
        <QuotationFormat
          quotationRef={quotationRef}
          quotation_detail={quotation_detail}
          list={selected_services}
          mail={quotation_detail?.EMAIL}
          company_name={quotation_detail?.COMPANY_NAME}
          person_name={quotation_detail?.CONTACT_PERSON}
          quotation_number={quotationNumber}
          address={`${quotation_detail?.FULL_ADDRESS}\n,${quotation_detail?.STATE},\n${quotation_detail?.CITY},\n${quotation_detail?.POSTCODE}`}
          reference={quotation_detail?.REFERENCE}
          setClose={setShow_preview}
          setAddClose={setIsModalOpen1}
          setDetail={setQuotation_detail}
        />
      </CustomModal>
    </div>
  );
};

export default Quotation;
