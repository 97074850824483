import { Popover, Modal } from "antd";
import React, { useRef, useState } from "react";
import { BiLogOut, BiSolidFilePdf, BiUser } from "react-icons/bi";
import { BsDownload, BsFileExcel } from "react-icons/bs";
import { FiFilter, FiSearch } from "react-icons/fi";
import { GoChevronDown } from "react-icons/go";
import { GrNext, GrPrevious } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { exportPDF, handleExcel } from "../utils/pdfandexcel";
import { FaTrash } from "react-icons/fa";
import { MdOutlineDelete, MdOutlineSettings } from "react-icons/md";
import CustomButton from "./CustomButton";

const TableHeader = ({
  TOTAL_NUMBER = 10,
  value,
  onChange,
  tableHeaders,
  tableData,
  tableName,
  fileName,
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
  onLimitChange,
  indexOfFirstItem,
  indexOfLastItem,
  onDeleteAll,
  onRetrieveAll,
  recycle,
  docs,
}) => {
  const inputRef = useRef();
  const [LIMIT, setLIMIT] = useState(itemsPerPage);
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recBin, setRecBin] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setRecBin(false);
    if (!recBin) onDeleteAll();
    else onRetrieveAll();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRecBin(false);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  return (
    <div className="flex flex-wrap gap-4 items-center justify-between mb-6">
      <div className="flex gap-4 items-center">
        {!docs && (
          <Popover
            placement="rightTop"
            trigger="click"
            title={""}
            content={
              <>
                <div
                  onClick={showModal}
                  className="cursor-pointer mb-2 font-semibold"
                >
                  Delete Selected
                </div>
                {recycle && (
                  <div
                    onClick={() => {
                      showModal();
                      setRecBin(true);
                    }}
                    className="cursor-pointer mb-2 font-semibold"
                  >
                    Retrieve Selected
                  </div>
                )}
              </>
            }
            open={open}
            onOpenChange={handleOpenChange}
          >
            <MdOutlineSettings className="cursor-pointer" size={25} />
          </Popover>
        )}
        {!docs && <div className="w-[1px] h-8 bg-[#D9D9DC]" />}
        <div
          className="bg-[#F6F6F7] max-w-[300px] w-full border border-[#C5C5C7] rounded-lg flex items-center pl-2 overflow-hidden"
          onClick={() => inputRef.current.focus()}
        >
          <FiSearch size={20} className="" />
          <input
            ref={inputRef}
            placeholder="Search "
            className="py-2 px-3 w-full bg-transparent focus:outline-none"
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
      {!docs && (
        <div className="flex gap-4 flex-wrap items-center">
          <div className="relative">
            <select
              value={LIMIT}
              onChange={(e) => {
                console.log(e.target.value,(typeof e.target.value));
                console.log(tableData)
                setLIMIT(e.target.value!=='all'?e.target.value:totalItems);
                onLimitChange(e.target.value!=='all'?e.target.value:totalItems);
              }}
              className="bg-white  flex w-[100px] rounded-lg border-[0.5px] border-[#0363B0] text-[16px] text-[#333333] appearance-none font-calibri font-light px-2 py-1 pr-10  focus:outline-none"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value={totalItems}>All</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <GoChevronDown color="#333333" size={20} />
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <p className="font-calibri text-[#6E6E71] ">
              {indexOfFirstItem + 1} - {indexOfLastItem} of {totalItems}
            </p>
            <div className="flex gap-4">
              <GrPrevious
                size={20}
                color="#6E6E71"
                className={`cursor-pointer ${
                  currentPage === 1 && "opacity-50 pointer-events-none"
                }`}
                onClick={handlePreviousPage}
              />
              <GrNext
                size={20}
                className={`cursor-pointer ${
                  currentPage === totalPages && "opacity-50 pointer-events-none"
                }`}
                onClick={handleNextPage}
              />
            </div>
          </div>
          <div className="w-[1px] h-8 bg-[#D9D9DC]" />
          <FiFilter size={20} />

          <Popover
            placement="bottomRight"
            title={""}
            content={
              <ul className={"min-w-[180px] gap-1 flex flex-col"}>
                <li className="group ">
                  <p
                    onClick={() => {
                      exportPDF({
                        table_headers: tableHeaders,
                        table_data: tableData,
                        tableName: tableName,
                        fileName: fileName,
                      });
                    }}
                    className={`flex gap-2 px-4 font-calibri font-medium py-2 rounded-md group-hover:bg-[#0363B0] bg-gray-200 text-[#333333]`}
                  >
                    <BiSolidFilePdf
                      className="group-hover:text-white"
                      size={20}
                    />
                    <span className="group-hover:text-white">Pdf Download</span>
                  </p>
                </li>
                <li className="group">
                  <div
                    onClick={() => {
                      handleExcel(tableData, fileName);
                    }}
                    className={`flex gap-2  px-4 font-calibri font-medium py-2 rounded-md group-hover:bg-[#0363B0] bg-gray-200 text-[#333333]`}
                  >
                    <BsFileExcel className="group-hover:text-white" size={20} />
                    <span className="group-hover:text-white">
                      Excel Download
                    </span>
                  </div>
                </li>
              </ul>
            }
          >
            <BsDownload size={20} />
          </Popover>
        </div>
      )}
      {/* <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete the selected items?</p>
      </Modal> */}
      <Modal
        title={recBin ? "Retrieve" : "Delete"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={
          <div className="flex gap-4 justify-end">
            <CustomButton
              title={"Cancel"}
              className={"h-[28px] min-w-[80px]"}
              onClick={handleCancel}
            />
            <CustomButton
              loading={loading}
              onClick={() => handleOk("single")}
              title={recBin ? "Retrieve" : "Delete"}
              className={`h-[28px] min-w-[80px] ${recBin?'bg-green-600':'bg-[#F00]'}`}
            />
          </div>
        }
      >
        <p className="font-calibri font-normal text-[16px]">
          Are you sure you want to {recBin ? "Retrieve" : "delete"} detail
        </p>
      </Modal>
    </div>
  );
};

export default TableHeader;
