import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import CustomButton from "../../components/CustomButton";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import { CiEdit, CiTrash } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import TableHeader from "../../components/TableHeader";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchService_list } from "../../redux/slice/dataSlice";
import Loader from "../../components/Loader";
import { CustomModal, Delete_Modal } from "../../components/modals";
import CustomInput from "../../components/CustomInput";
import axios from "axios";
import CustomDropdown from "../../components/CustomDropdown";

const user = JSON.parse(localStorage.getItem("lavin"));
const SubService = () => {
  const navigate = useNavigate();
  const { services_list } = useSelector((state) => state.allData);
  const [selected, setSelected] = useState([]);
  const [add_modal, setAdd_modal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [index, setIndex] = useState(0);
  const [sub_list, setSub_list] = useState([]);
  const [main_loading, setMain_loading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  useEffect(() => {
    store.dispatch(fetchService_list());
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [index]);
  useEffect(() => {
    getData();
  }, [services_list]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  const handleSubmit = async () => {
    if (!data.SERVICE_NAME || !data.DESCRIPTION || !data.PRICE) {
      setErrors((prev) => ({
        ...prev,
        ...(!data.SERVICE_NAME && {
          SERVICE_NAME: "Please enter service name",
        }),
        ...(!data.DESCRIPTION && { DESCRIPTION: "Please enter description" }),
        ...(!data.PRICE && { PRICE: "Please enter price" }),
      }));
      return; // Exit early to prevent further processing
    }
    setLoading(true);
    const rawData = {
      ...(edit && { ID: data?.ID }),
      MAIN_SERVICE_ID: services_list[index]?.ID,
      USER_ID: user?.USER_ID,
      SERVICE_NAME: data.SERVICE_NAME,
      DESCRIPTION: data.DESCRIPTION,
      PRICE: data.PRICE,
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: edit
        ? `${process.env.REACT_APP_API_KEY}sub-services/update`
        : `${process.env.REACT_APP_API_KEY}sub-services/create`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.TOKEN}`,
      },
      data: rawData,
    };

    try {
      const response = await axios.request(config);
      const res = response.data;
      setLoading(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        store.dispatch(fetchService_list());
        setAdd_modal(false);
        setEdit(false);
        getData();
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("API call error:", error);
      openNotification(
        "error",
        "Error",
        "Something went wrong. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({
      ID: [data.ID],
    });
    const res = await postApiToken(`sub-services/delete-service`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchService_list());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };
  const getData = async () => {
    setMain_loading(true);

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}sub-services/list?PAGE=1&LIMIT=10&MAIN_SERVICE_ID=${services_list[index]?.ID}`,
      headers: {
        Authorization: `Bearer ${user?.TOKEN}`,
      },
    };

    try {
      const response = await axios.request(config);
      const res = response.data;
      setMain_loading(false);
      if (res.success) {
        setSub_list(res.data);
      } else {
        setSub_list([]);
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setMain_loading(false);
      console.error("API call error:", error);
      openNotification(
        "error",
        "Error",
        "Something went wrong. Please try again."
      );
    } finally {
      setMain_loading(false);
    }
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>Services</HeaderText>
        <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Service"}
          onClick={() => {
            setData({});
            setErrors({});
            setEdit(false);
            setAdd_modal(true);
          }}
        />
      </div>
      <div className="max-w-[300px]">
        <CustomDropdown
          value={index}
          onChange={(e) => {
            setIndex(e.target.value);
          }}
        >
          {services_list?.map((item, index) => (
            <option key={index} value={index}>
              {item.SERVICE_NAME}
            </option>
          ))}
        </CustomDropdown>
      </div>
      {main_loading && sub_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 mt-4">
          <TableHeader />

          <CustomTable
            list={sub_list}
            selected={selected}
            setSelected={setSelected}
            headerData={["Sub Service Name", "Description", "Price", "Actions"]}
            bodyData={sub_list?.map((item, index) => (
              <tr key={item.ID}>
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.ID)}
                    onChange={() => handleSelect(item.ID)}
                  />
                </td>

                <td className="py-2 px-4 border-b ">{item.SERVICE_NAME}</td>
                <td className="py-2 px-4 border-b">{item.DESCRIPTION}</td>
                <td className="py-2 px-4 border-b">{item.PRICE}</td>
                <td className="py-2 px-4 border-b ">
                  <div className="flex gap-4">
                    <CiEdit
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setData(item);
                        setEdit(true);
                        setAdd_modal(true);
                      }}
                    />
                    <CiTrash
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setData(item);
                        setDelete_modal(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          />
        </div>
      )}

      <CustomModal
        isModalOpen={add_modal}
        setIsModalOpen={setAdd_modal}
        title={edit ? "Update Service" : "Add Service"}
      >
        <div className="flex gap-4 flex-wrap">
          <CustomDropdown
            value={index}
            label={"Select Service"}
            onChange={(e) => {
              setIndex(e.target.value);
            }}
          >
            {services_list?.map((item, index) => (
              <option key={index} value={index}>
                {item.SERVICE_NAME}
              </option>
            ))}
          </CustomDropdown>
          <CustomInput
            value={data?.SERVICE_NAME || ""}
            error={errors?.SERVICE_NAME}
            name={"SERVICE_NAME"}
            onChange={handleChange}
            label={"Service Name"}
            placeholder={"Enter Service Name"}
          />
          <CustomInput
            value={data?.PRICE || ""}
            error={errors?.PRICE}
            name={"PRICE"}
            onChange={handleChange}
            label={"Service Price"}
            type={"number"}
            placeholder={"Enter Service Price"}
          />
          <CustomInput
            value={data?.DESCRIPTION || ""}
            error={errors?.DESCRIPTION}
            name={"DESCRIPTION"}
            onChange={handleChange}
            label={"Description"}
            placeholder={"Enter Description"}
            area={true}
          />
        </div>
        <div className="flex justify-center mt-8">
          <CustomButton
            title={edit ? "Update" : "Submit"}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </CustomModal>
      <Delete_Modal
        handleDelete={handleDelete}
        isModalOpen={delete_modal}
        setIsModalOpen={setDelete_modal}
        loading={loading}
      ></Delete_Modal>
    </>
  );
};

export default SubService;
