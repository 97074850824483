import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { styled } from "@mui/system";
import { putApiToken } from "../../utils/apis";
import { openNotification } from "../../App";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "20px",
  gap: "20px",
  justifyContent: "center",
  alignItems: "flex-start",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ProfileContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  maxWidth: "200px",
  width: "100%",
  height: "100% !important",
  padding: "20px 0",
});

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  flex: 1,
});

const ProfileImage = styled("img")({
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  marginBottom: "20px",
});

const ProfileInput = styled(TextField)({
  marginBottom: "16px",
});

const SaveButton = styled(Button)({
  alignSelf: "flex-end",
  marginTop: "20px",
});

const EditUserProfile = ({ item, setModalOpen }) => {
  const userInfo = item.userInfo[0];
  console.log(item);

  const [formValues, setFormValues] = useState({
    firstName: userInfo.FIRST_NAME || "",
    lastName: userInfo.LAST_NAME || "",
    email: userInfo.EMAIL_ADDRESS || "",
    phoneNumber: userInfo.PHONE_NUMBER || "",
    address: userInfo.ADDRESS || "",
    country: userInfo.COUNTRY || "",
    state: userInfo.STATE || "",
    city: userInfo.CITY || "",
    zip: userInfo.POSTCODE || "",
    about:
      "Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      const body = {
        USER_ID: item?.USER_ID,
        FIRST_NAME: formValues.firstName,
        LAST_NAME: formValues.lastName,
        PHONE_NUMBER: formValues.phoneNumber,
        ADDRESS: formValues.address,
        COUNTRY: formValues.country,
        STATE: formValues.state,
        CITY: formValues.city,
        POSTCODE: formValues.zip,
        ABOUT: formValues.about,
        ROLE: item?.ROLE,
      };

      const res = await putApiToken(
        "team-structure/profile-update",
        JSON.stringify(body)
      );
      if (res.success) {
        openNotification("success", "Success", res.message);
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
      setError("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <ProfileContainer>
        <ProfileImage
          src={
            userInfo.IMAGE_URL ||
            "https://img.freepik.com/free-photo/female-web-designer-office-with-notebook_23-2149749868.jpg"
          }
          alt="Profile"
        />
      </ProfileContainer>

      <FormContainer>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1">
            <ProfileInput
              size="small"
              label="First Name"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              label="Last Name"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              label="Email Address"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              label="Phone Number"
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              label="Address"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div className="flex-1">
            <ProfileInput
              size="small"
              label="Country"
              name="country"
              value={formValues.country}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              label="State/Region"
              name="state"
              value={formValues.state}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              style={{ marginTop: "" }}
              label="City"
              name="city"
              value={formValues.city}
              onChange={handleChange}
              fullWidth
            />
            <ProfileInput
              size="small"
              label="Zip/Code"
              name="zip"
              value={formValues.zip}
              onChange={handleChange}
              fullWidth
            />
          </div>
        </div>
        <ProfileInput
          size="small"
          label="About"
          name="about"
          value={formValues.about}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
        />
        {error && (
          <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
        )}
        {success && (
          <div style={{ color: "green", marginTop: "10px" }}>{success}</div>
        )}
        <SaveButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save changes"}
        </SaveButton>
      </FormContainer>
    </Container>
  );
};

export default EditUserProfile;
