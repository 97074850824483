import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchLead_list, fetchService_list } from "../redux/slice/dataSlice";
import { postApiToken } from "../utils/apis";
import { openNotification } from "../App";
import { generateQuotationNumber } from "../utils/utils";
import CustomButton from "../components/CustomButton";
import CustomInput, { InputInline } from "../components/CustomInput";
import { CustomModal } from "../components/modals";
import { BiTrash } from "react-icons/bi";
import { GoPlus } from "react-icons/go";
import QuotationFormat from "./QuotationFomat";
import CustomTable from "./CustomTable";
import ServicesList from "../pages/services";

const QuotationModal = ({
  isMainModalOpen,
  setIsMainModalOpen,
  CONTACT_INFO,
  COMPANY_DETAIL,
  lead_detail,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quotationRef = useRef();
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [detail, setDetail] = useState({});
  const [serviceModal, setServiceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quotationDetail, setQuotationDetail] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [singleData, setSingleData] = useState({
    service: {},
    value: [],
    qty: "",
    price: "",
  });

  const { lead_list, lead_loading, services_list } = useSelector(
    (state) => state.allData
  );

  useEffect(() => {
    dispatch(fetchLead_list());
    dispatch(fetchService_list());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotationDetail((prev) => ({ ...prev, [name]: value.trimStart() }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSelect = (id) => {
    setSelected(
      selected.includes(id)
        ? selected.filter((item) => item !== id)
        : [...selected, id]
    );
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({ ID: [detail.ID] });
    const res = await postApiToken(`leads/delete-lead`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      dispatch(fetchLead_list());
      setIsModalOpen(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const allData = () => {
    return services_list
      .filter(
        (service) => !selectedServices.some((item) => item.value === service.ID)
      )
      ?.map((service) => ({
        value: service.ID,
        label: service.SERVICE_NAME,
        desc: service.DESCRIPTION,
      }));
  };

  const allData1 = () => {
    return CONTACT_INFO?.map((contact) => ({
      value: contact.ID,
      label: contact.CONTACT_PERSON,
      detail: contact,
    }));
  };

  const getSubServicePriceById = (subServiceId, qty) => {
    const service = services_list.find(
      (service) => service.ID === subServiceId
    );
    return service ? service.PRICE * qty : null;
  };

  const removeService = (index) => {
    setSelectedServices((prev) => prev.filter((_, i) => i !== index));
  };

  const onAdd = () => {
    if (!singleData.value.length || !singleData.price || !singleData.qty) {
      openNotification("info", "Info", "Please fill all the fields");
      return;
    }
    setSelectedServices([...selectedServices, singleData]);
    setSingleData({ service: {}, value: [], qty: "", price: "" });
  };

  const handlePreview = () => {
    setShowPreview(true);
  };

  const handleContactChange = (value, items) => {
    setSelectedContacts(items.map((item) => item.detail));
  };

  const quotationNumber = generateQuotationNumber({
    invoiceNumber: 1,
    consumerFirstName: selectedContacts[0]?.CONTACT_PERSON,
    consumerLastName: "L",
    date: new Date(),
    quotationCountForUser: 1,
  });

  return (
    <div className="bg-white p-4">
      <CustomModal
        small
        isModalOpen={isMainModalOpen}
        setIsModalOpen={setIsMainModalOpen}
        A4={true}
      >
        <div className="md:px-6">
          <h1 className="text-[20px] font-semibold mb-4 text-center">
            GENERATE QUOTATION
          </h1>

          <CustomTable
            list={[lead_detail]}
            check={false}
            headerData={["Company Type", "Company Name", "City", "Location"]}
            bodyData={[lead_detail].map((item, index) => (
              <tr key={item.ID}>
                <td className="py-2 px-4 border-b">{item.COMPANY_TYPE}</td>
                <td className="py-2 px-4 border-b">{item.COMPANY_NAME}</td>
                <td className="py-2 px-4 border-b">{item.CITY}</td>
                <td className="py-2 px-4 border-b">{item.LOCATION}</td>
              </tr>
            ))}
          />
          <div className="gap-3 flex flex-col">
            <div className="flex mt-6 items-center gap-16">
              <p className="text-[12px]">Select Contact Person</p>
              <Select
                showSearch
                mode="multiple"
                variant="borderless"
                style={{ width: "100%" }}
                placeholder="Select Contact Person"
                className="w-full bg-[#f1f1f1] rounded-md"
                options={allData1()}
                onChange={handleContactChange}
                value={selectedContacts.map((contact) => contact.ID)}
                size="middle"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </div>
            <div className="mt-4">
              {selectedContacts.length > 0 && (
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b">Contact Person</th>
                      <th className="py-2 px-4 border-b">Email</th>
                      <th className="py-2 px-4 border-b">Mobile Number</th>
                      <th className="py-2 px-4 border-b">State</th>
                      <th className="py-2 px-4 border-b">City</th>
                      <th className="py-2 px-4 border-b">Postcode</th>
                      <th className="py-2 px-4 border-b">Reference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedContacts.map((contact, index) => (
                      <tr key={index}>
                        <td className="py-2 px-4 border-b">
                          {contact.CONTACT_PERSON}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {contact.EMAIL_ID}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {contact.MOBILE_NUMBER}
                        </td>
                        <td className="py-2 px-4 border-b">{contact.STATE}</td>
                        <td className="py-2 px-4 border-b">{contact.CITY}</td>
                        <td className="py-2 px-4 border-b">
                          {contact.POST_CODE}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {contact.REFERENCE}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="flex items-center w-full gap-4 mt-2 mb-1">
              <div className="w-[50px] text-center font-calibri">S.No</div>
              <div className="flex-[1.5] font-calibri text-center">Service Description</div>
              <div className="flex-[0.5] text-left">Qty</div>
              <div className="flex-[0.5] text-left">Price</div>
            </div>
            {selectedServices?.map((item, index) => (
              <div className="flex items-center w-full gap-4 mb-2" key={index}>
                <div className="w-[50px] text-center font-calibri">
                  {index + 1}
                </div>
                <div className="flex-[1.5]">
                  {item?.SERVICE_NAME} :- {item?.DESCRIPTION}
                </div>
                <div className="flex-[0.5] text-center">
                  <input
                    type="text"
                    className="w-full text-center"
                    value={item?.QUANTITY ? item?.QUANTITY : 1}
                    onChange={(v) => {
                      // Handle quantity change
                    }}
                  />
                </div>
                <div className="flex-[0.5] text-center">{item.PRICE}</div>
                <div className="flex-[0.3] text-center">
                  <BiTrash
                    size={20}
                    color="#000"
                    className="cursor-pointer"
                    onClick={() => removeService(index)}
                  />
                </div>
              </div>
            ))}

            <div className="flex items-center w-full gap-4 mb-2">
              <div className="flex-[0.2] font-calibri">
                {selectedServices.length + 1}
              </div>
              <div className="flex-1">
                <Select
                  onClick={() => setServiceModal(true)}
                  showSearch
                  mode="multiple"
                  variant="borderless"
                  style={{ width: "100%" }}
                  placeholder="Select Services"
                  className="w-full bg-[#f1f1f1] rounded-md"
                  options={allData()}
                  onChange={(v, item) =>
                    setSingleData({ ...singleData, service: item, value: v })
                  }
                  value={singleData.value}
                  size="middle"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                />
              </div>
              <div className="flex-[0.5]">
                <Input
                  className="bg-[#f1f1f1]"
                  placeholder="Qty"
                  size="middle"
                  variant="borderless"
                  type="number"
                  onChange={(v) =>
                    setSingleData({
                      ...singleData,
                      qty: v.target.value,
                      price: getSubServicePriceById(
                        singleData.value[0], // assuming the first selected service
                        v.target.value
                      ),
                    })
                  }
                  value={singleData.qty}
                />
              </div>
              <div className="flex-[0.5]">
                <Input
                  className="bg-[#f1f1f1]"
                  placeholder="Price"
                  size="middle"
                  variant="borderless"
                  type="number"
                  onChange={(v) =>
                    setSingleData({ ...singleData, price: v.target.value })
                  }
                  value={singleData.price}
                />
              </div>
              {/* <div className="flex-[0.3]">
                <GoPlus
                  size={25}
                  className="cursor-pointer text-[#0363B0]"
                  onClick={onAdd}
                />
              </div> */}
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <CustomButton title="Preview" onClick={handlePreview} />
          </div>
          <CustomModal
            A4={true}
            title=""
            isModalOpen={showPreview}
            setIsModalOpen={setShowPreview}
          >
            <QuotationFormat
              quotationRef={quotationRef}
              quotation_detail={quotationDetail}
              list={selectedServices}
              leadid={lead_detail?.ID}
              mail={selectedContacts[0]?.EMAIL_ID}
              company_name={quotationDetail?.COMPANY_NAME}
              person_name={selectedContacts[0]?.CONTACT_PERSON}
              quotation_number={quotationNumber}
              address={`${quotationDetail?.FULL_ADDRESS}\n${quotationDetail?.STATE}\n${quotationDetail?.CITY}\n${quotationDetail?.POSTCODE}`}
              reference={selectedContacts[0]?.REFERENCE}
              setClose={setShowPreview}
              setAddClose={setIsModalOpen1}
              leadOrNot={true}
              setDetail={setQuotationDetail}
              selectedContacts={selectedContacts}
            />
          </CustomModal>
          <CustomModal
            large
            title=""
            isModalOpen={serviceModal}
            setIsModalOpen={setServiceModal}
          >
            <ServicesList
              serviceModal={serviceModal}
              setServiceModal={setServiceModal}
              selectedServices={selectedServices}
              setSelectedServices={setSelectedServices}
              type={"quotation"}
            />
          </CustomModal>
        </div>
      </CustomModal>
    </div>
  );
};

export default QuotationModal;
