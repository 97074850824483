import React from "react";
import HeaderText from "../../components/HeaderText";
import CustomButton from "../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const LeadDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const detail = location.state;
  return (
    <div>
      <div className="flex items-center justify-between">
        <HeaderText>Lead Detail</HeaderText>
        <CustomButton
          icon={<IoArrowBack size={20} />}
          title={"Back"}
          onClick={() => navigate(-1)}
        />
      </div>
      <p className="my-4 text-[#333333] font-semibold text-[20px]">
        Company Detail
      </p>
      <div className="flow-root rounded-lg border border-gray-100 bg-white py-3 shadow-sm">
        <dl className="-my-3 divide-y divide-gray-100 text-sm">
          {/* <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Key</dt>
            <dd className="text-gray-700 sm:col-span-2">Value</dd>
          </div> */}

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Company Type</dt>
            <dd className="text-gray-700 sm:col-span-2">
              {detail?.COMPANY_TYPE}
            </dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Company Name</dt>
            <dd className="text-gray-700 sm:col-span-2">
              {detail?.COMPANY_NAME}
            </dd>
          </div>
          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">ADDRESS</dt>
            <dd className="text-gray-700 sm:col-span-2">{detail?.ADDRESS}</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">COUNTRY</dt>
            <dd className="text-gray-700 sm:col-span-2">{detail?.COUNTRY}</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">STATE</dt>
            <dd className="text-gray-700 sm:col-span-2">{detail?.STATE}</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">CITY</dt>
            <dd className="text-gray-700 sm:col-span-2">{detail?.CITY}</dd>
          </div>
          <div className="grid grid-cols-1 gap-1 p-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">BRANCH</dt>
            <dd className="text-gray-700 sm:col-span-2">{detail?.BRANCH}</dd>
          </div>
        </dl>
      </div>
      <p className="mt-4 text-[#333333] font-semibold text-[20px]">
        Contact Person Detail
      </p>
      <div className="flex gap-4 overflow-scroll">
        {detail?.CONTACT_INFO?.map((v, i) => (
          <div className="flow-root rounded-lg border border-gray-100 bg-white py-3 shadow-sm mt-4 min-w-[500px]">
            <dl className="-my-3 text-sm">
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">CONTACT_PERSON</dt>
                <dd className="text-gray-700 sm:col-span-2">
                  : {v?.CONTACT_PERSON}
                </dd>
              </div>
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">MOBILE_NUMBER</dt>
                <dd className="text-gray-700 sm:col-span-2">
                  : {v?.MOBILE_NUMBER}
                </dd>
              </div>
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">EMAIL_ID</dt>
                <dd className="text-gray-700 sm:col-span-2">: {v?.EMAIL_ID}</dd>
              </div>
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">DESIGNATION</dt>
                <dd className="text-gray-700 sm:col-span-2">
                  : {v?.DESIGNATION}
                </dd>
              </div>
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">DEPARTMENT</dt>
                <dd className="text-gray-700 sm:col-span-2">
                  : {v?.DEPARTMENT}
                </dd>
              </div>
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">COMMENT</dt>
                <dd className="text-gray-700 sm:col-span-2">: {v?.COMMENT}</dd>
              </div>
              <div className="grid grid-cols-1 gap-1 p-3  sm:grid-cols-3 sm:gap-4">
                <dt className="font-medium text-gray-900">NOTES</dt>
                <dd className="text-gray-700 sm:col-span-2">: {v?.NOTES}</dd>
              </div>
            </dl>
          </div>
        ))}
      </div>
      <p className="my-4 text-[#333333] font-semibold text-[20px]">
        Service Required Detail
      </p>
      <div className="flow-root rounded-lg border border-gray-100 bg-white overflow-hidden py-3 shadow-sm">
        {detail?.SERVICE_REQUIRD?.map((v, i) => (
          <dl className="-my-3 divide-y divide-gray-100 text-sm">
            <div
              className={`grid grid-cols-1 gap-1 p-3 ${
                i % 2 == 0 ? "bg-gray-50" : "bg-transparent my-3"
              } sm:grid-cols-3 sm:gap-4`}
            >
              <dt className="font-medium text-gray-900">{v.NAME}</dt>
              <dd className="text-gray-700 sm:col-span-2">{v.VALUE}</dd>
            </div>
          </dl>
        ))}
      </div>
    </div>
  );
};

export default LeadDetail;
