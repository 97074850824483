import { Modal } from "antd";
import CustomButton from "./CustomButton";
import HeaderText from "./HeaderText";
import useScreenSize from "../utils/useScreenSize";

export const Delete_Modal = ({
  loading,
  isModalOpen,
  setIsModalOpen,
  handleDelete,
  mess,
  retrive = false,
}) => {
  return (
    <Modal
      title={retrive?'Retrieve':"Delete"}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      centered
      footer={
        <div className="flex gap-4 justify-end">
          <CustomButton
            title={"Cancel"}
            className={"h-[28px] min-w-[80px]"}
            onClick={() => setIsModalOpen(false)}
          />
          {retrive ? (
            <CustomButton
              loading={loading}
              onClick={handleDelete}
              title={"Retrieve"}
              className={"h-[28px] bg-green-600 min-w-[80px]"}
            />
          ) : (
            <CustomButton
              loading={loading}
              onClick={handleDelete}
              title={"Delete"}
              className={"h-[28px] min-w-[80px] bg-[#F00]"}
            />
          )}
        </div>
      }
    >
      <p className="font-calibri font-normal text-[16px]">
        {mess || "Are you sure you want to delete detail"}
      </p>
    </Modal>
  );
};

export const CustomModal = ({
  isModalOpen,
  setIsModalOpen,
  children,
  title,
  A4,
  large = false,
  small
}) => {
  console.log(isModalOpen)
  const screen=useScreenSize();
  console.log(screen)
  return (
    <Modal
      title=""
      open={isModalOpen} // Ensure you use `visible` instead of `open`
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      centered
      className="no_scroll top-half"
      width={A4&&screen?.isDesktop?'1000px':small?'40%':"95%"}
      footer={null} // Use `null` instead of `false` for footer prop
      //   bodyStyle={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      styles={{content:{paddingLeft:'30px',paddingRight:'30px'}, body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto",padding:'' } }}
    >
      <div className="flex items-center  justify-between">
        <HeaderText>{title}</HeaderText>
      </div>
      {children}
    </Modal>
  );
};
