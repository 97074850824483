import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import { openNotification } from "../../../App";
import { useSelector } from "react-redux";
import { InputInline } from "../../../components/CustomInput";
import { CustomModal } from "../../../components/modals";
import RelievingFormat from "./RelievingFormat";

const RelievingLetter = ({ id, employeeId }) => {
  const [quotation_detail, setQuotation_detail] = useState({});
  const [errors, setErrors] = useState({});
  const [show_preview, setShow_preview] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotation_detail((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handlePreview = () => {
    if (
      !quotation_detail?.RECIPIENT_NAME ||
      !quotation_detail?.RECIPIENT_ADDRESS ||
      !quotation_detail?.CITY ||
      !quotation_detail?.STATE ||
      !quotation_detail?.ZIPCODE ||
      !quotation_detail?.POSITION ||
      !quotation_detail?.START_DATE ||
      !quotation_detail?.END_DATE ||
      !quotation_detail?.POSSESSIVE_DETERMINER ||
      !quotation_detail?.SALARY
    ) {
      openNotification("info", "Info", "Please fill all the fields");
      return;
    }

    setShow_preview(true);
  };

  return (
    <div className="bg-white p-4">
      <h1 className="text-[20px] font-semibold mb-4">Relieving Letter</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputInline
          label={"Recipient Name"}
          placeholder={"Enter Recipient Name"}
          value={quotation_detail?.RECIPIENT_NAME || ""}
          error={errors?.RECIPIENT_NAME}
          name={"RECIPIENT_NAME"}
          onChange={handleChange}
        />
        <InputInline
          label={"Recipient Address"}
          placeholder={"Enter Recipient Address"}
          value={quotation_detail?.RECIPIENT_ADDRESS || ""}
          error={errors?.RECIPIENT_ADDRESS}
          name={"RECIPIENT_ADDRESS"}
          onChange={handleChange}
        />
        <InputInline
          label={"City"}
          placeholder={"Enter City"}
          value={quotation_detail?.CITY || ""}
          error={errors?.CITY}
          name={"CITY"}
          onChange={handleChange}
        />
        <InputInline
          label={"State"}
          placeholder={"Enter State"}
          value={quotation_detail?.STATE || ""}
          error={errors?.STATE}
          name={"STATE"}
          onChange={handleChange}
        />
        <InputInline
          label={"ZIP Code"}
          placeholder={"Enter ZIP Code"}
          value={quotation_detail?.ZIPCODE || ""}
          error={errors?.ZIPCODE}
          name={"ZIPCODE"}
          onChange={handleChange}
        />
        <InputInline
          label={"Position"}
          placeholder={"Enter Position"}
          value={quotation_detail?.POSITION || ""}
          error={errors?.POSITION}
          name={"POSITION"}
          onChange={handleChange}
        />
        <InputInline
        type={'date'}
          label={"Start Date"}
          placeholder={"Enter Start Date"}
          value={quotation_detail?.START_DATE || ""}
          error={errors?.START_DATE}
          name={"START_DATE"}
          onChange={handleChange}
        />
        <InputInline
        type={'date'}
          label={"End Date"}
          placeholder={"Enter End Date"}
          value={quotation_detail?.END_DATE || ""}
          error={errors?.END_DATE}
          name={"END_DATE"}
          onChange={handleChange}
        />
        <InputInline
          label={"Possessive Determiner"}
          placeholder={"Enter Possessive Determiner (e.g., his/her)"}
          value={quotation_detail?.POSSESSIVE_DETERMINER || ""}
          error={errors?.POSSESSIVE_DETERMINER}
          name={"POSSESSIVE_DETERMINER"}
          onChange={handleChange}
        />
        <InputInline
          label={"Salary"}
          placeholder={"Enter Salary"}
          value={quotation_detail?.SALARY || ""}
          error={errors?.SALARY}
          name={"SALARY"}
          onChange={handleChange}
        />
      </div>
      <div className="flex justify-center mt-4">
        <CustomButton title="Preview" onClick={handlePreview} />
      </div>

      <CustomModal
        A4
        title=""
        isModalOpen={show_preview}
        setIsModalOpen={setShow_preview}
      >
        <div>
          <RelievingFormat
            item={quotation_detail}
            employeeId={employeeId}
            USER_ID={id}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default RelievingLetter;
