import React from "react";
import { PulseLoader } from "react-spinners";

const CustomButton = ({ title, onClick, loading = false, icon, className }) => {
  return (
    <button
      disabled={loading}
      className={`bg-[#0363B0] border border-[#0363B0] text-[#fff] h-[35px] min-w-[120px] rounded-lg font-calibri! px-5 flex gap-2  items-center justify-center font-normal w-full${
        loading ? "text-[28px]" : "text-[16px]"
      } ${className} !min-w-fit text-nowrap`}
      onClick={onClick}
    >
      {icon}
      {loading ? <PulseLoader color="#fff" size={8} /> : title}
    </button>
  );
};

export default CustomButton;
