import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const user = JSON.parse(localStorage.getItem("lavin"));
const token = localStorage.getItem("TOKEN");

// --------------------------------------------------- Lead info -----------------------------------------------------------

export const fetchLead_list = createAsyncThunk("lead/list", async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_KEY + "leads/list?PAGE=1&LIMIT=200",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    };

    // const response = await axios.request(config);

    // const res = await response.data;

    const response = await fetch(
      process.env.REACT_APP_API_KEY + "leads/list?PAGE=1&LIMIT=200",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "GET",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {}
});
// --------------------------------------------------- Lead trash info -----------------------------------------------------------

export const fetchLead_trash_list = createAsyncThunk(
  "lead_trash/list",
  async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_KEY +
          "leads/list-from-trash?PAGE=1&LIMIT=200",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          method: "GET",
        }
      );
      const res = await response.json();
      return res;
    } catch (error) {}
  }
);

// --------------------------------------------------- Admin info -----------------------------------------------------------

export const fetchAdmin_list = createAsyncThunk("admin/list", async () => {
  try {
    const token1 = user?.TOKEN; // Assuming user is accessible here
    console.log("Token:", token1);
    console.log("Token:", token);
    const response = await fetch(
      process.env.REACT_APP_API_KEY +
        "team-structure/user-list-based-on-role?ROLE=ADMIN&page=1&listPerPage=100",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "GET",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {}
});

// --------------------------------------------------- Manager info -----------------------------------------------------------

export const fetchManager_list = createAsyncThunk("manager/list", async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_API_KEY +
        "team-structure/user-list-based-on-role?ROLE=MANAGER&page=1&listPerPage=100",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    };

    // const response = await axios.request(config);
    // const res = await response.data;
    const response = await fetch(
      process.env.REACT_APP_API_KEY +
        "team-structure/user-list-based-on-role?ROLE=MANAGER&page=1&listPerPage=100",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "GET",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {}
});

// --------------------------------------------------- Employee info -----------------------------------------------------------

export const fetchEmployee_list = createAsyncThunk(
  "employee/list",
  async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_KEY +
          "team-structure/user-list-based-on-role?ROLE=EXECUTIVE&page=1&listPerPage=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          method: "GET",
        }
      );
      const res = await response.json();
      return res;
    } catch (error) {}
  }
);

// --------------------------------------------------- Service info -----------------------------------------------------------

export const fetchService_list = createAsyncThunk("Service/list", async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_KEY + "services/list?PAGE=1&LIMIT=100",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "GET",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {}
});

// --------------------------------------------------- Contact info -----------------------------------------------------------

export const fetchContact_info = createAsyncThunk("contact/list", async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_KEY + "contact/list?PAGE=1&LIMIT=100",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
        },
        method: "GET",
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {}
});

// --------------------------------------------------- Contact info -----------------------------------------------------------

export const fetchContact_tarsh_info = createAsyncThunk(
  "contact_trash/list",
  async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_KEY +
          "contact/list-from-trash?PAGE=1&LIMIT=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          method: "GET",
        }
      );
      const res = await response.json();
      return res;
    } catch (error) {}
  }
);

// --------------------------------------------------- CUstomer info -----------------------------------------------------------

export const fetchCustomer_list = createAsyncThunk(
  "customer/list",
  async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_KEY + "customer-po/list?PAGE=1&LIMIT=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          method: "GET",
        }
      );
      const res = await response.json();
      return res;
    } catch (error) {}
  }
);

// --------------------------------------------------- CUstomer trash info -----------------------------------------------------------

export const fetchCustomer_trash_list = createAsyncThunk(
  "customer_trash/list",
  async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_KEY +
          "customer-po/list-from-trash?PAGE=1&LIMIT=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
          method: "GET",
        }
      );
      const res = await response.json();
      return res;
    } catch (error) {}
  }
);
const initialState = {
  lead_list: [],
  lead_total_count: 0,
  lead_loading: false,
  lead_error: "",

  lead_trash_list: [],
  lead_trash_total_count: 0,
  lead_trash_loading: false,
  lead_trash_error: "",

  admin_list: [],
  admin_total_count: 0,
  admin_loading: false,
  admin_error: "",

  manager_list: [],
  manager_total_count: 0,
  manager_loading: false,
  manager_error: "",

  employee_list: [],
  employee_total_count: 0,
  employee_loading: false,
  employee_error: "",

  services_list: [],
  services_total_count: 0,
  services_loading: false,
  services_error: "",

  contact_list: [],
  contact_total_count: 0,
  contact_loading: false,
  contact_error: "",

  contact_trash_list: [],
  contact_trash_total_count: 0,
  contact_trash_loading: false,
  contact_trash_error: "",

  customer_list: [],
  customer_total_count: 0,
  customer_loading: false,
  customer_error: "",
  serial_data: {},

  customer_trash_list: [],
  customer_trash_total_count: 0,
  customer_trash_loading: false,
  customer_trash_error: "",
};
const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // --------------------------------------------------- Lead info -----------------------------------------------------------

    builder.addCase(fetchLead_list.pending, (state) => {
      state.lead_loading = true;
      state.lead_error = "";
    });
    builder.addCase(fetchLead_list.fulfilled, (state, action) => {
      const data = action.payload;
      console.log();
      state.lead_loading = false;
      if (data?.success) {
        state.lead_list = data.data;
        state.lead_total_count = data.count;
      } else {
        state.lead_list = [];
        state.lead_total_count = 0;
      }
    });
    builder.addCase(fetchLead_list.rejected, (state, action) => {
      state.lead_loading = false;
      state.lead_error = action.error.message;
    });

    // --------------------------------------------------- Lead Trash info -----------------------------------------------------------

    builder.addCase(fetchLead_trash_list.pending, (state) => {
      state.lead_trash_loading = true;
      state.lead_trash_error = "";
    });
    builder.addCase(fetchLead_trash_list.fulfilled, (state, action) => {
      const data = action.payload;
      console.log();
      state.lead_trash_loading = false;
      if (data?.success) {
        state.lead_trash_list = data.data;
        state.lead_trash_total_count = data.count;
      } else {
        state.lead_trash_list = [];
        state.lead_trash_total_count = 0;
      }
    });
    builder.addCase(fetchLead_trash_list.rejected, (state, action) => {
      state.lead_trash_loading = false;
      state.lead_trash_error = action.error.message;
    });

    // --------------------------------------------------- Admin info -----------------------------------------------------------

    builder.addCase(fetchAdmin_list.pending, (state) => {
      state.admin_loading = true;
      state.admin_error = "";
    });
    builder.addCase(fetchAdmin_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.admin_loading = false;
      if (data?.success) {
        state.admin_list = data.data;
        state.admin_total_count = data.total;
      } else {
        state.admin_list = [];
        state.admin_total_count = 0;
      }
    });
    builder.addCase(fetchAdmin_list.rejected, (state, action) => {
      state.admin_loading = false;
      state.admin_error = action.error.message;
    });

    // --------------------------------------------------- Manager info -----------------------------------------------------------

    builder.addCase(fetchManager_list.pending, (state) => {
      state.manager_loading = true;
      state.manager_error = "";
    });
    builder.addCase(fetchManager_list.fulfilled, (state, action) => {
      const data = action.payload;
      console.log();
      state.manager_loading = false;
      if (data?.success) {
        state.manager_list = data.data;
        state.manager_total_count = data.total;
      } else {
        state.manager_list = [];
        state.manager_total_count = 0;
      }
    });
    builder.addCase(fetchManager_list.rejected, (state, action) => {
      state.manager_loading = false;
      state.manager_error = action.error.message;
    });

    // --------------------------------------------------- Employee info -----------------------------------------------------------

    builder.addCase(fetchEmployee_list.pending, (state) => {
      state.employee_loading = true;
      state.employee_error = "";
    });
    builder.addCase(fetchEmployee_list.fulfilled, (state, action) => {
      const data = action.payload;
      console.log();
      state.employee_loading = false;
      if (data?.success) {
        state.employee_list = data.data;
        state.employee_total_count = data.total;
      } else {
        state.employee_list = [];
        state.employee_total_count = 0;
      }
    });
    builder.addCase(fetchEmployee_list.rejected, (state, action) => {
      state.employee_loading = false;
      state.employee_error = action.error.message;
    });

    // --------------------------------------------------- Service info -----------------------------------------------------------

    builder.addCase(fetchService_list.pending, (state) => {
      state.services_loading = true;
      state.services_error = "";
    });
    builder.addCase(fetchService_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.services_loading = false;
      if (data?.success) {
        state.services_list = data.data;
        state.services_total_count = data.count;
      } else {
        state.services_list = [];
        state.services_total_count = 0;
      }
    });
    builder.addCase(fetchService_list.rejected, (state, action) => {
      state.services_loading = false;
      state.services_error = action.error.message;
    });

    // --------------------------------------------------- Contact info -----------------------------------------------------------

    builder.addCase(fetchContact_info.pending, (state) => {
      state.contact_loading = true;
      state.contact_error = "";
    });
    builder.addCase(fetchContact_info.fulfilled, (state, action) => {
      const data = action.payload;
      state.contact_loading = false;
      if (data?.success) {
        state.contact_list = data.data;
        state.contact_total_count = data.count;
      } else {
        state.contact_list = [];
        state.contact_total_count = 0;
      }
    });
    builder.addCase(fetchContact_info.rejected, (state, action) => {
      state.contact_loading = false;
      state.contact_error = action.error.message;
    });

    // --------------------------------------------------- Contact trash info -----------------------------------------------------------

    builder.addCase(fetchContact_tarsh_info.pending, (state) => {
      state.contact_trash_loading = true;
      state.contact_trash_error = "";
    });
    builder.addCase(fetchContact_tarsh_info.fulfilled, (state, action) => {
      const data = action.payload;
      state.contact_trash_loading = false;
      if (data?.success) {
        state.contact_trash_list = data.data;
        state.contact_trash_total_count = data.count;
      } else {
        state.contact_trash_list = [];
        state.contact_trash_total_count = 0;
      }
    });
    builder.addCase(fetchContact_tarsh_info.rejected, (state, action) => {
      state.contact_trash_loading = false;
      state.contact_trash_error = action.error.message;
    });

    // --------------------------------------------------- Customer  info -----------------------------------------------------------

    builder.addCase(fetchCustomer_list.pending, (state) => {
      state.customer_loading = true;
      state.customer_error = "";
    });
    builder.addCase(fetchCustomer_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.customer_loading = false;
      if (data?.success) {
        state.customer_list = data.data;
        state.customer_total_count = data.count;
        state.serial_data = data.SERAIAL_NUMBER;
      } else {
        state.customer_list = [];
        state.customer_total_count = 0;
        state.serial_data = data.SERAIAL_NUMBER;
      }
    });
    builder.addCase(fetchCustomer_list.rejected, (state, action) => {
      state.customer_loading = false;
      state.customer_error = action.error.message;
    });

    // --------------------------------------------------- Customer trash info -----------------------------------------------------------

    builder.addCase(fetchCustomer_trash_list.pending, (state) => {
      state.customer_trash_loading = true;
      state.customer_trash_error = "";
    });
    builder.addCase(fetchCustomer_trash_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.customer_trash_loading = false;
      if (data?.success) {
        state.customer_trash_list = data.data;
        state.customer_trash_total_count = data.count;
      } else {
        state.customer_trash_list = [];
        state.customer_trash_total_count = 0;
      }
    });
    builder.addCase(fetchCustomer_trash_list.rejected, (state, action) => {
      state.customer_trash_loading = false;
      state.customer_trash_error = action.error.message;
    });
  },
});

export const {} = dataSlice.actions;
export default dataSlice.reducer;
