import React, { createContext, useState, useContext } from 'react';
import BlurryLoading from '../components/BlurryLoading';

const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const value = {
    loading,
    setLoading,
  };

  return (
    <LoadingContext.Provider value={value}>
      {loading && <BlurryLoading />}
      {children}
    </LoadingContext.Provider>
  );
};
