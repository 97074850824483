import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const CustomInput = ({
  label,
  preview,
  placeholder,
  value,
  onChange,
  multiple,
  area,
  className,
  name,
  error = "",
  rightIcon,
  disabled,
  light,
  maxLength,
  type,
  min,
  max,
  right,
  drop = false,
  options = [],
  inline = false,
  onlyView = false,
  mode,
  nonee,
  special,
  dis,
  mobileBreak
}) => {
  return (
    <div
      className={`flex ${nonee ? "opacity-0" : ""} flex-col ${
        inline ? "min-w-full" : "min-w-[300px] w-full"
      }`}
    >
      <div
        className={`flex ${
          inline ? "flex-row min-w-full items-center" : "flex-row min-w-[300px]"
        }  ${className} flex-1 ${mobileBreak?'flex-col':''}`}
      >
        {label && !dis && (
          <label
            className={`${
              light ? "font-light" : "font-light"
            } text-[16px] text-[#333333] font-calibri ${
              onlyView ? "flex-[0.1]" : "mb-1"
            } ${
              special ? "!min-w-[170px]" : ""
            } min-w-[110px] !max-w-[110px] font-semibold`}
          >
            {label+' :'}
          </label>
        )}
        <div className="flex flex-col flex-1">
          {onlyView ? (
            <p className={`${preview?'bg-transparent':'bg-[#e1e5e7]'} py-1 px-2 font-calibri font-normal min-h-[30px] flex-1 w-full`}>
              {value}
            </p>
          ) : drop ? (
            <div className={`${preview?'bg-transparent':'bg-[#e1e5e7]'}  flex w-[100%]   border-[0.8px]  overflow-hidden`}>
              <Select
                mode={mode}
                showSearch
                variant="borderless"
                style={{ width: "100%" }}
                placeholder={placeholder}
                className="w-full text-[#333333] font-calibri "
                options={options}
                onChange={onChange}
                value={value}
                size="middle"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </div>
          ) : area ? (
            <div className="bg-[#fff]  flex flex-1   border-[0.8px] border-[#0363B0] overflow-hidden">
              <TextArea
                disabled={disabled}
                variant="borderless"
                value={value}
                onChange={onChange}
                name={name}
                placeholder={placeholder}
                className="text-[#333333] font-calibri "
                // className={`bg-[#fff] font-calibri placeholder:text-[#33333377]  flex w-full mt-2 px-4 py-3 rounded-lg border-[0.5px] border-[#0363B0] text-[16px] text-[#333333] focus:outline-none  placeholder-[#454545]  resize-y min-h-[100px]   `}
              />
            </div>
          ) : (
            // <textarea
            //   value={value}
            //   onChange={onChange}
            //   name={name}
            //   placeholder={placeholder}
            //   className={`bg-[#fff] font-calibri placeholder:text-[#33333377]  flex w-full mt-2 px-4 py-3 rounded-lg border-[0.5px] border-[#0363B0] text-[16px] text-[#333333] focus:outline-none  placeholder-[#454545]  resize-y min-h-[100px]   `}
            // />
            <div className="flex gap-1 items-center flex-1">
              <div className="bg-[#fff] flex w-full   border-[0.8px] border-[#0363B0] overflow-hidden">
                {/* <input
              onWheel={(e) => e.target.blur()}
              type={type}
              max={max}
              min={min}
              placeholder={placeholder}
              value={value}
              name={name}
              onChange={onChange}
              multiple={multiple}
              maxLength={maxLength}
              className={`px-4 py-2 w-full text-[14px] text-[#333333] focus:outline-none ${inputClassName}`}
            /> */}
                <Input
                  disabled={disabled}
                  className=" disabled:text-[#333333] text-[#333333] font-calibri w-full"
                  variant="borderless"
                  size="large"
                  onWheel={(e) => e.target.blur()}
                  type={type}
                  max={max}
                  min={min}
                  placeholder={placeholder}
                  value={value}
                  name={name}
                  onChange={onChange}
                  multiple={multiple}
                  maxLength={maxLength}
                />
                {rightIcon}
              </div>
              {right}
            </div>
          )}

          {error && (
            <label className="font-light font-calibri text-[14px] text-[#f00] mt-1">
              {error}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomInput;

export const InputInline = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  name,
  type,
  dis,
  
}) => {
  return (
    <div className="flex flex-col flex-1 font-calibri">
      <div className="flex gap-2 items-center flex-1 min-w-[300px]">
        {!dis && (
          <>
            <label className="min-w-[90px] max-w-[90px] flex">{label}</label>:
          </>
        )}
        <Input
          variant="borderless"
          size="middle"
          className="flex-[1] font-calibri bg-[#e1e5e7] h-[35px] border-[#f1f1f1] bottom-0 outline-none rounded-none focus:outline-none hover:outline-none"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          inputMode=""
          type={type}
        />
      </div>
      {error && (
        <label className="font-normal font-calibri text-[14px] text-[#f00] mt-1">
          {error}
        </label>
      )}
    </div>
  );
};
