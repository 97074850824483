import axios from "axios";
const user = JSON.parse(localStorage.getItem("lavin"));

export const postApi = async (endpoint, body) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_KEY + endpoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };

    const response = await axios.request(config);
    const res = await response.data;
    return res;
  } catch (error) {
    return { success: false, message: "Network error" };
  }
};

export const postApiToken = async (endpoint, body) => {
  try {
    console.log(body,endpoint)
    let config = {
      method: "post",
      // maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_KEY + endpoint,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${user?.TOKEN}`,
      },
      data: body,
    };

    const response = await fetch(process.env.REACT_APP_API_KEY + endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${user?.TOKEN}`,
      },
      body: body,
    });
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network error" };
  }
};

export const putApiToken = async (endpoint, body) => {
  console.log("here");
  try {
    // let config = {
    //   method: "put",
    //   maxBodyLength: Infinity,
    //   url: process.env.REACT_APP_API_KEY + endpoint,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `bearer ${user?.TOKEN}`,
    //   },
    //   data: body,
    // };

    // const response = await axios.request(config);
    // const res = await response.data;
    const response = await fetch(process.env.REACT_APP_API_KEY + endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${user?.TOKEN}`,
      },
      body: body,
    });
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {
    return { success: false, message: "Network error" };
  }
};
