import React, { useEffect, useState } from "react";
import MyContext from "./myContext";
// import { toast } from "react-toastify";

function MyState(props) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <MyContext.Provider
      value={{
        collapsed,
        setCollapsed,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
