import { useEffect } from "react";
import "./App.css";
import "./fonts.css";
import MyState from "./context/myState";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import MainRouter from "./router";
import { LoadingProvider } from "./context/LoadingContext";

let openNotification;
function App() {
  const [api, contextHolder] = notification.useNotification();
  openNotification = (type, title, message) => {
    api[type]({
      message: title,
      description: message,
      placement: "top",
    });
  };
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("lavin"));
  console.log(user, "abhishek");
  useEffect(() => {
    if (user == null) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="font-calibri">
      <Provider store={store}>
        <LoadingProvider>
          {contextHolder}
          <MyState>
            <MainRouter />
            {/* <Quotation />
        {user == null ? <Login /> : <EmployeeRouter />} */}
          </MyState>
        </LoadingProvider>
      </Provider>
    </div>
  );
}
export { openNotification };
export default App;
