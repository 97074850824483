import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import CustomButton from "../../components/CustomButton";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import { CiEdit, CiTrash } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import TableHeader from "../../components/TableHeader";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchContact_info } from "../../redux/slice/dataSlice";
import Loader from "../../components/Loader";
import { CustomModal, Delete_Modal } from "../../components/modals";
import CustomInput from "../../components/CustomInput";
import axios from "axios";
import { BiHeart } from "react-icons/bi";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";

const ContactList = () => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const navigate = useNavigate();
  const { contact_list, contact_loading, contact_total_count } = useSelector(
    (state) => state.allData
  );
  const [selected, setSelected] = useState([]);
  const [add_modal, setAdd_modal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [detail, setDetail] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  useEffect(() => {
    store.dispatch(fetchContact_info());
  }, []);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  const handleSubmit = async (is_fav, item) => {
    if (!is_fav) {
      if (!data.NAME || !data.EMAIL || !data.PHONE_NUMBER) {
        setErrors((prev) => ({
          ...prev,
          ...(!data.NAME && { NAME: "Please enter name" }),
          ...(!data.PHONE_NUMBER && {
            PHONE_NUMBER: "Please enter phone number",
          }),
          ...(!data.EMAIL && { EMAIL: "Please enter email address" }),
        }));
        return; // Exit early to prevent further processing
      }
    }
    setLoading(true);
    const rawData = is_fav
      ? {
          ID: item.ID,
          USER_ID: user?.USER_ID,
          NAME: item.NAME,
          EMAIL: item.EMAIL,
          PHONE_NUMBER: item.PHONE_NUMBER,
          IS_FAVORITE: !item?.IS_FAVORITE,
        }
      : {
          ...(edit && { ID: data.ID }),
          USER_ID: user?.USER_ID,
          NAME: data.NAME,
          EMAIL: data.EMAIL,
          PHONE_NUMBER: data.PHONE_NUMBER,
          IS_FAVORITE: false,
        };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: edit
        ? `${process.env.REACT_APP_API_KEY}contact/update`
        : is_fav
        ? `${process.env.REACT_APP_API_KEY}contact/update`
        : `${process.env.REACT_APP_API_KEY}contact/create`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.TOKEN}`,
      },
      data: rawData,
    };

    try {
      const response = await axios.request(config);
      const res = response.data;

      if (res.success) {
        openNotification("success", "Success", res.message);
        store.dispatch(fetchContact_info());
        setAdd_modal(false);
        setEdit(false);
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      console.error("API call error:", error);
      openNotification(
        "error",
        "Error",
        error.response?.data?.message ||
          "Something went wrong. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({
      ID: [detail.ID],
    });
    const res = await postApiToken(`contact/delete-contact`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchContact_info());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>Contact Info</HeaderText>
        <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Contact"}
          onClick={() => {
            setData({});
            setErrors({});
            setEdit(false);
            setAdd_modal(true);
          }}
        />
      </div>
      {contact_loading && contact_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 mt-4">
          <TableHeader />
          <CustomTable
            list={contact_list}
            selected={selected}
            setSelected={setSelected}
            headerData={[
              "Name",
              "Phone Number",
              "Email",
              "Favorite",
              "Actions",
            ]}
            bodyData={contact_list.map((item, index) => (
              <tr key={item.ID}>
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.ID)}
                    onChange={() => handleSelect(item.ID)}
                  />
                </td>

                <td className="py-2 px-4 border-b ">{item.NAME}</td>
                <td className="py-2 px-4 border-b">{item.PHONE_NUMBER}</td>
                <td className="py-2 px-4 border-b">{item.EMAIL}</td>
                <td className="py-2 px-4 border-b">
                  <div className="flex items-center justify-center">
                    {item?.IS_FAVORITE ? (
                      <IoMdHeart
                        size={25}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => handleSubmit(true, item)}
                      />
                    ) : (
                      <IoMdHeartEmpty
                        size={25}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => handleSubmit(true, item)}
                      />
                    )}
                  </div>
                </td>

                <td className="py-2 px-4 border-b ">
                  <div className="flex gap-4">
                    <CiEdit
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setData(item);
                        setEdit(true);
                        setAdd_modal(true);
                      }}
                    />
                    <CiTrash
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setDetail(item);
                        setDelete_modal(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          />
        </div>
      )}

      <CustomModal
        isModalOpen={add_modal}
        setIsModalOpen={setAdd_modal}
        title={edit ? "Update Contact" : "Add Contact"}
      >
        <div className="flex gap-4 flex-wrap">
          <CustomInput
            value={data?.NAME || ""}
            error={errors?.NAME}
            name={"NAME"}
            onChange={handleChange}
            label={"Name"}
            placeholder={"Enter Name"}
          />
          <CustomInput
            value={data?.PHONE_NUMBER || ""}
            error={errors?.PHONE_NUMBER}
            name={"PHONE_NUMBER"}
            onChange={handleChange}
            type={"number"}
            maxLength={10}
            label={"Phone Number"}
            placeholder={"Enter Phone Number"}
          />
          <CustomInput
            value={data?.EMAIL || ""}
            error={errors?.EMAIL}
            name={"EMAIL"}
            onChange={handleChange}
            label={"Email"}
            placeholder={"Enter Email"}
          />
        </div>
        <div className="flex justify-center mt-8">
          <CustomButton
            title={edit ? "Update" : "Submit"}
            onClick={() => handleSubmit(false, {})}
            loading={loading}
          />
        </div>
      </CustomModal>
      <Delete_Modal
        handleDelete={handleDelete}
        isModalOpen={delete_modal}
        setIsModalOpen={setDelete_modal}
        loading={loading}
      ></Delete_Modal>
    </>
  );
};

export default ContactList;
