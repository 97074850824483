import { Select } from "antd";
import React from "react";
import { GoChevronDown } from "react-icons/go";

const CustomDropdown = ({
  label,
  value,
  onChange,
  children,
  className,
  name,
  error,
}) => {
  return (
    <div
      className={`flex relative ${className} flex-1  min-w-[300px]`}
    >
      {label && (
        <label className=" min-w-[110px] mt-2 text-[14px] text-[#333333] font-semibold">{label}</label>
      )}
      <div className="relative w-full mt-2">
        <select
          value={value}
          name={name}
          onChange={onChange}
          className="bg-[#e1e5e7] flex w-full text-[16px] text-[#333333] appearance-none font-calibri font-light px-4 py-1 pr-10  focus:outline-none"
        >
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
          <GoChevronDown color="#333333" size={20} />
        </div>
      </div>
      {error && (
        <label className="font-medium text-[14px] text-[#f00] mt-1">
          {error}
        </label>
      )}
    </div>
  );
};

export default CustomDropdown;

export const MultiSelect = ({
  label,
  value,
  onChange,
  options,
  className,
  placeholder,
  error,
}) => {
  return (
    <div
      className={`flex flex-col relative ${className} flex-1  min-w-[300px]`}
    >
      {label && (
        <label className="font-light text-[16px] text-[#333333]">{label}</label>
      )}
      <Select
        mode="multiple"
        value={value}
        size="large"
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full mt-2"

        // className="bg-white mt-2 flex w-full rounded-lg border-[0.5px] border-[#0363B0] text-[16px] text-[#333333] appearance-none font-calibri font-light px-4 py-3 pr-10  focus:outline-none"
      ></Select>

      {error && (
        <label className="font-medium text-[14px] text-[#f00] mt-1">
          {error}
        </label>
      )}
    </div>
  );
};
