import React from "react";

const IconButton = ({ title, onClick, loading = false, icon, className }) => {
  return (
    <button
      disabled={loading}
      className={`bg-[#0363B0] border border-[#0363B0] text-[#fff] h-[35px] rounded-lg font-calibri! px-2 flex gap-2 items-center justify-center font-normal w-fit ${
        loading ? "text-[32px]" : "text-[32px]"
      } ${className}`}
      onClick={onClick}
      title={title}
    >
      {icon}
    </button>
  );
};

export default IconButton;
