import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../../App";
import { useSelector } from "react-redux";
import CustomInput, { InputInline } from "../../../components/CustomInput";
import { CustomModal } from "../../../components/modals";
import OfferFormat from "./OfferFormat";
import moment from "moment";

const OfferLetter = ({id,employeeId}) => {
  const navigate = useNavigate();
  const { lead_list, lead_loading, services_list } = useSelector(
    (state) => state.allData
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quotation_detail, setQuotation_detail] = useState({});
  const [errors, setErrors] = useState({});
  const [show_preview, setShow_preview] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotation_detail((prev) => {
      return { ...prev, [name]: value.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handlePreview = () => {
    let newErrors = {};

    // Required field validation
    const requiredFields = [
      "RECIPIENT_NAME",
      "RECIPIENT_ADDRESS",
      "CITY",
      "STATE",
      "ZIPCODE",
      "POSITION",
      "START_DATE",
      "SALARY",
    ];

    requiredFields.forEach((field) => {
      if (!quotation_detail[field]) {
        newErrors[field] = "This field is required";
      }
    });

    // ZIP Code validation

    // Date validation (optional)
    if (quotation_detail.START_DATE && !moment(quotation_detail.START_DATE, "YYYY-MM-DD", true).isValid()) {
      newErrors.START_DATE = "Invalid date format (YYYY-MM-DD)";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      openNotification("info", "Info", "Please fill all the fields correctly");
      return;
    }

    setShow_preview(true);
  };

  return (
    <div className="bg-white p-4">
      <h1 className="text-[20px] font-semibold mb-4">Offer Letter</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <InputInline
          label={"Recipient Name"}
          placeholder={"Enter Recipient Name"}
          value={quotation_detail.RECIPIENT_NAME || ""}
          error={errors.RECIPIENT_NAME}
          name={"RECIPIENT_NAME"}
          onChange={handleChange}
        />
        <InputInline
          label={"Recipient Address"}
          placeholder={"Enter Recipient Address"}
          value={quotation_detail.RECIPIENT_ADDRESS || ""}
          error={errors.RECIPIENT_ADDRESS}
          name={"RECIPIENT_ADDRESS"}
          onChange={handleChange}
        />
        <InputInline
          label={"City"}
          placeholder={"Enter City"}
          value={quotation_detail.CITY || ""}
          error={errors.CITY}
          name={"CITY"}
          onChange={handleChange}
        />
        <InputInline
          label={"State"}
          placeholder={"Enter State"}
          value={quotation_detail.STATE || ""}
          error={errors.STATE}
          name={"STATE"}
          onChange={handleChange}
        />
        <InputInline
          label={"ZIP Code"}
          placeholder={"Enter ZIP Code"}
          value={quotation_detail.ZIPCODE || ""}
          error={errors.ZIPCODE}
          name={"ZIPCODE"}
          onChange={handleChange}
        />
        <InputInline
          label={"Position"}
          placeholder={"Enter Position"}
          value={quotation_detail.POSITION || ""}
          error={errors.POSITION}
          name={"POSITION"}
          onChange={handleChange}
        />
        <InputInline
        type={'date'}
          label={"Start Date"}
          placeholder={"Enter Start Date (YYYY-MM-DD)"}
          value={quotation_detail.START_DATE || ""}
          error={errors.START_DATE}
          name={"START_DATE"}
          onChange={handleChange}
        />
        <InputInline
          label={"Salary"}
          placeholder={"Enter Salary"}
          value={quotation_detail.SALARY || ""}
          error={errors.SALARY}
          name={"SALARY"}
          onChange={handleChange}
        />
      </div>
      <div className="flex justify-center mt-4">
        <CustomButton title="Preview" onClick={handlePreview} />
      </div>

      <CustomModal
        A4
        title=""
        isModalOpen={show_preview}
        setIsModalOpen={setShow_preview}
      >
        <div>
          <OfferFormat item={quotation_detail} USER_ID={id} employeeId={employeeId}  {...quotation_detail} />
        </div>
      </CustomModal>
    </div>
  );
};

export default OfferLetter;
