import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown";
import axios from "axios";
import { openNotification } from "../../App";
import { store } from "../../redux/store";
import { fetchLead_list } from "../../redux/slice/dataSlice";
import CustomStepper from "../../components/CustomStepper";
import { useSelector } from "react-redux";
import CustomInput, { InputInline } from "../../components/CustomInput";
import { testCategories, testCategorieswithId } from "../../constants/data";
import { CustomModal } from "../../components/modals";
import SmallDrop from "./smallDrop";
import { useNavigate } from "react-router-dom";

const AddLead = ({ setIsModalOpen }) => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const [smallModal, setSmallModal] = useState(false);
  const [smallData, setSmallData] = useState([]);
  const { services_list } = useSelector((state) => state.allData);
  const navigate=useNavigate()
  services_list?.map((item) => {
    console.log(item?.SERVICE_NAME, item?.ID);
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [select_ids, setSelect_ids] = useState([]);
  const [prevService,setPrevService]=useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handleNext = () => {
    // if (
    //   !data.c_name ||
    //   !data.c_type ||
    //   !data.p_email ||
    //   !data.p_name ||
    //   !data.p_number ||
    //   !data.department ||
    //   !data.designation ||
    //   !data.address ||
    //   !data.branch ||
    //   !data.country ||
    //   !data.city ||
    //   !data.state ||
    //   !data.location
    // ) {
    //   setErrors({
    //     ...errors,
    //     c_name: !data.c_name ? "Please enter company name" : "",
    //     c_type: !data.c_type ? "Please enter company type" : "",
    //     p_email: !data.p_email ? "Please enter email address" : "",
    //     p_name: !data.p_name ? "Please enter person name" : "",
    //     p_number: !data.p_number ? "Please enter person contact number" : "",
    //     department: !data.department ? "Please enter department" : "",
    //     designation: !data.designation ? "Please enter designation" : "",
    //     address: !data.address ? "Please enter address" : "",
    //     branch: !data.branch ? "Please enter branch" : "",
    //     country: !data.country ? "Please enter country" : "",
    //     city: !data.city ? "Please enter city" : "",
    //     state: !data.state ? "Please enter state " : "",
    //     location: !data.location ? "Please enter location" : "",
    //   });
    //   return;
    // }
    setCurrentStep(1);
  };
  const handleSubmit = async () => {
    // if (
    //   !data.water_testing ||
    //   !data.air_testing ||
    //   !data.food_testing ||
    //   !data.comment ||
    //   !data.notes
    // ) {
    //   setErrors({
    //     ...errors,
    //     water_testing: !data.water_testing ? "" : "",
    //     air_testing: !data.air_testing ? "" : "",
    //     food_testing: !data.food_testing ? "" : "",
    //     comment: !data.comment ? "" : "",
    //     notes: !data.notes ? "" : "",
    //   });
    //   return;
    // }

    if (selected?.length == 0) {
      alert("Please select services");
      return;
    }
    try {
      setLoading(true);
      let rawdata = JSON.stringify({
        USER_ID: user?.USER_ID,
        IS_OTHER: data.c_type == "Others" ? true : false,
        COMPANY_TYPE:
          data.c_type == "Others"
            ? !data.other_type
              ? "Demo 1"
              : data.other_type
            : !data.c_type
            ? "Demo 1"
            : data.c_type,
        COMPANY_NAME: !data.c_name ? "Demo 1" : data.c_name,
        ADDRESS: !data.address ? "Demo 1" : data.address,
        BRANCH: !data.branch ? "Demo 1" : data.branch,
        CITY: !data.city ? "Demo 1" : data.city,
        STATE: !data.state ? "Demo 1" : data.state,
        COUNTRY: !data.country ? "India" : data.country,
        CONTACT_INFO: {
          CONTACT_PERSON: !data.p_name ? "Demo 1" : data.p_name,
          MOBILE_NUMBER: !data.p_number ? "9876543211" : data.p_number,
          DESIGNATION: !data.designation ? "Demo 1" : data.designation,
          EMAIL_ID: !data.p_email ? "demo@gmail.com" : data.p_email,
          DEPARTMENT: !data.department ? "Demo 1" : data.department,
          COMMENT: !data.comment ? "Demo 1" : data.comment,
          NOTES: !data.notes ? "Demo 1" : data.notes,
        },
        SERVICE_REQUIRD: selected?.map((item, index) => ({
          NAME: item.label,
          VALUE: item.value,
        })),

        LOCATION: !data.location ? "Demo 1" : data.location,
      });

      let config = {
        method: "post",
        // maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_KEY + "leads/create",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${user?.TOKEN}`,
        },
        data: rawdata,
      };
      const response = await axios.request(config);
      const res = await response.data;

      setLoading(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        setIsModalOpen(false);
        store.dispatch(fetchLead_list());
        setCurrentStep(0);
        navigate('/lead-list')
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const allData = () => {
    // const orderedServiceIds = new Set(selected?.map((item) => item?.value));
    // return (
    //   services_list
    //     // ?.filter((service) => !orderedServiceIds.has(service?.ID))
    //     .map((service) => ({
    //       value: service?.ID,
    //       label: service?.SERVICE_NAME,
    //       desc: service?.DESCRIPTION,
    //       price: service?.PRICE,
    //     }))
    // );
    const newarray = [];
    testCategories.forEach((test, categories) => {
      newarray.push({
        value: categories,
        label: categories,
        desc: "",
        price: "",
      });
    });
    return newarray;
  };
  useEffect(() => {
    setCurrentStep(0);
    setSelected([]);
    setData({});
  }, []);

  return (
    <>
      <div className="md:max-w-[60%] flex mx-auto">
        <CustomStepper
          active_tab={currentStep}
          list={["Add Profile", "Required Services"]}
        />
      </div>
      <div className="">
        {currentStep == 0 && (
          <div className="py-2 gap-4 flex flex-col ">
            <p className="text-[#0363B0] font-semibold text-[20px]">
              Company Detail
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <CustomDropdown
                label={"Company Type"}
                className={"flex-1"}
                value={data?.c_type}
                error={errors?.c_type}
                name={"c_type"}
                onChange={handleChange}
              >
                <option value={""}>Select Company Type</option>
                <option value={"HOTEL"}>HOTEL</option>
                <option value={"INDUSTRY"}>INDUSTRY</option>
                <option value={"Factory"}>Factory</option>
                <option value={"hospitals"}>hospitals</option>
                <option value={"individuals"}>individuals</option>
                <option value={"Others"}>Others</option>
              </CustomDropdown>
              {data?.c_type == "Others" ? (
                <InputInline
                  special
                  value={data?.other_type}
                  error={errors?.other_type}
                  name={"other_type"}
                  onChange={handleChange}
                  label={"Company Type"}
                  placeholder={"Enter Company Type"}
                  className={"flex-1"}
                />
              ) : (
                <div className="flex-1" />
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <InputInline
                special
                value={data?.c_name}
                error={errors?.c_name}
                name={"c_name"}
                onChange={handleChange}
                label={"Company Name"}
                placeholder={"Enter Company Name"}
                className={"flex-1"}
              />
              <InputInline
                value={data?.address}
                error={errors?.address}
                name={"address"}
                onChange={handleChange}
                label={"Address"}
                placeholder={"Enter Address"}
                className={"flex-1"}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <InputInline
                special
                value={data?.branch}
                error={errors?.branch}
                name={"branch"}
                onChange={handleChange}
                label={"Branch"}
                placeholder={"Enter Branch"}
                className={"flex-1"}
              />
              <InputInline
                value={data?.country}
                error={errors?.country}
                name={"country"}
                onChange={handleChange}
                label={"Country"}
                placeholder={"Enter Country Name"}
                className={"flex-1"}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <InputInline
                special
                value={data?.state}
                error={errors?.state}
                name={"state"}
                onChange={handleChange}
                label={"State"}
                placeholder={"Enter State Name"}
                className={"flex-1"}
              />
              <InputInline
                value={data?.city}
                error={errors?.city}
                name={"city"}
                onChange={handleChange}
                label={"City"}
                placeholder={"Enter City Name"}
                className={"flex-1"}
              />
            </div>
            <p className="text-[#0363B0] font-semibold text-[20px]">
              Contact Person Detail
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              <InputInline
                special
                value={data?.p_name}
                error={errors?.p_name}
                name={"p_name"}
                onChange={handleChange}
                label={"Contact Person Name"}
                placeholder={"Enter Contact Person Name"}
                className={"flex-1"}
              />
              <InputInline
                value={data?.designation}
                error={errors?.designation}
                name={"designation"}
                onChange={handleChange}
                label={"Designation"}
                placeholder={"Enter Designation Name"}
                className={"flex-1"}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputInline
                special
                value={data?.department}
                error={errors?.department}
                name={"department"}
                onChange={handleChange}
                label={"Department"}
                placeholder={"Enter Department Name"}
                className={"flex-1"}
              />
              <InputInline
                value={data?.p_email}
                error={errors?.p_email}
                name={"p_email"}
                onChange={handleChange}
                label={"Email ID"}
                placeholder={"Enter Email ID"}
                className={"flex-1"}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputInline
                special
                value={data?.p_number}
                error={errors?.p_number}
                name={"p_number"}
                maxLength={10}
                onChange={handleChange}
                label={"Mobile Number"}
                placeholder={"Enter Mobile Number"}
                className={"flex-1"}
              />
              <InputInline
                value={data?.location}
                error={errors?.location}
                name={"location"}
                onChange={handleChange}
                label={"Location"}
                placeholder={"Enter Location"}
                className={"flex-1"}
              />
            </div>

            <div className="justify-center items-center flex mt-3">
              <CustomButton title={"Next"} onClick={() => handleNext(1)} />
            </div>
          </div>
        )}
        {currentStep == 1 && (
          <div className="py-2 gap-4 flex flex-col">
            <div className=" flex flex-wrap gap-4">
              <CustomInput
                drop
                mode={"multiple"}
                options={allData()}
                // value={select_ids}
                // error={errors?.air_testing}
                name={"air_testing"}
                testCategorieswithId={testCategorieswithId}
                onChange={(e, item) => {
                  if (e.length > 0&&prevService.length<=e.length) {
                    setSmallData(e[e.length - 1]);
                    setSmallModal(true);
                  }
                  setPrevService(e);
                  let idArray = [];
                  e?.map((itemm) => {
                    idArray = [
                      ...idArray,
                      ...testCategorieswithId.get(itemm).map((itt) => itt.id),
                    ];
                  });
                  // console.log(idArray);
                  // setSelect_ids(idArray);
                  setSelected(item);
                }}
                label={"Select Service"}
                placeholder={"Select Service"}
              ></CustomInput>

              <InputInline
                special
                value={data?.comment}
                error={errors?.comment}
                name={"comment"}
                onChange={handleChange}
                area={true}
                label={"Comment"}
                placeholder={"Enter Comment"}
                className={"flex-1"}
              />
              <InputInline
                special
                value={data?.notes}
                error={errors?.notes}
                name={"notes"}
                onChange={handleChange}
                area={true}
                label={"Notes"}
                placeholder={"Enter Notes"}
                className={"flex-1"}
              />
            </div>
            <div className="justify-center items-center flex mt-3 gap-4">
              <CustomButton title={"Back"} onClick={() => setCurrentStep(0)} />
              <CustomButton
                title={"Submit"}
                onClick={handleSubmit}
                loading={loading}
              />
            </div>
          </div>
        )}
      </div>
      <CustomModal small={true} isModalOpen={smallModal} setIsModalOpen={setSmallModal}>
        <SmallDrop
          setSelect_ids={setSelect_ids}
          data={smallData}
          testCategorieswithId={testCategorieswithId}
          onClose={() => setSmallModal(false)}
        />
      </CustomModal>
    </>
  );
};

export default AddLead;
