import React, { useEffect, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, DatePicker, message, Select, Upload } from "antd";
import CustomButton from "../../components/CustomButton";
import { IoArrowBack } from "react-icons/io5";
import axios from "axios";
import { useSelector } from "react-redux";
import { openNotification } from "../../App";
import { store } from "../../redux/store";
import {
  fetchCustomer_list,
  fetchService_list,
} from "../../redux/slice/dataSlice";
import { CustomModal } from "../../components/modals";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Dragger from "antd/es/upload/Dragger";
import CustomInput, { InputInline } from "../../components/CustomInput";
import { FaPlus } from "react-icons/fa";
import { generateCustomerNumber, generatePONumber } from "../../utils/utils";
import { BiTrash } from "react-icons/bi";
import CustomTable from "../../components/table/CustomTablee";

const CustomerAdd = ({ detail,edit, setDetail, isModalOpen, setIsModalOpen }) => {
  const pdfRef = useRef();
  const user = JSON.parse(localStorage.getItem("lavin"));
  const [fileList, setFileList] = useState([]);
  const { services_list, customer_list, serial_data } = useSelector(
    (state) => state.allData
  );
  console.log(serial_data?.PO_NUMBER);
  const [order_data, setOrder_data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show_contact, setShow_contact] = useState(true);
  const [errors, setErrors] = useState({});
  const [order_summary, setOrder_summary] = useState([]);
  const [detail2, setDetail2] = useState({
    service: {},
    service_id: "",
    price: "",
    total_price: "",
    quantity: "",
    left: "",
    completed: "",
    remark: "",
  });
  console.log(serial_data);

  useEffect(() => {
    store.dispatch(fetchService_list());
  }, []);

  const handleChange = (info) => {
    const { status } = info.file;
    console.log(detail);
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(info.fileList);
  };

  const removeService = (index) => {
    setOrder_data((prev) => prev.filter((item, i) => i !== index));
  };

  const contact_info =
    detail?.CONTACT_INFO?.map((contact, index) => ({
      CONTACT_PERSON: contact.CONTACT_PERSON,
      DEPARTMENT: contact.DEPARTMENT,
      CONTACT_NUMBER: [contact.CONTACT_NO],
      CONTACT_EMAIL: contact.CONTACT_EMAIL,
      DESIGNATION: contact?.DESIGNATION,
    })) || [];

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    // Append files
    fileList.forEach((file) => {
      formData.append("FILES", file.originFileObj);
    });

    // Append detail data
    formData.append(
      "CUSTOMER_ID",
      generateCustomerNumber({
        name: detail?.CUSTOMER_NAME ? detail?.CUSTOMER_NAME : "",
        TOTALCN: serial_data?.CUSTOMER_NUMBER + 1,
      })
    );
    formData.append("CUSTOMER_NAME", detail?.CUSTOMER_NAME);
    formData.append("FULL_ADDRESS", detail?.FULL_ADDRESS);
    formData.append("STATE", detail?.STATE);
    formData.append("POST_CODE", detail?.POST_CODE);
    formData.append("CITY", detail?.CITY);

    // Append contact info
    formData.append("CONTACT_INFO", JSON.stringify(contact_info));

    formData.append("NOTE", detail?.NOTE);
    formData.append("REMARK", detail?.REMARK);
    formData.append("GSTIN", detail?.GSTIN);

    // Append PO info
    const po_info =
      detail?.PO_INFO?.map((po) => ({
        PO_NUMBER: po.PO_NUMBER,
        BILLING_ADDRESS: po.BILLING_ADDRESS,
        REPORTING_ADDRESS: po.REPORTING_ADDRESS,
        PO_EXPIRY_DATE: po.PO_EXPIRY_DATE,
        DESIGNATION: po.DESIGNATION,
        ORDER_SUMMARY: order_summary,
      })) || [];

    formData.append("PO_INFO", JSON.stringify(po_info));

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:edit?"https://api.psklavinlab.com/api/customer-po/update": "https://api.psklavinlab.com/api/customer-po/create",
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
        data: formData,
      };

      const response = await axios.request(config);
      const res = await response.data;
      console.log(res);
      setLoading(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        store.dispatch(fetchCustomer_list());
        setDetail({});
        setOrder_summary([]);
        setIsModalOpen(false);
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) { 
      setLoading(false);
      console.error("Error uploading files:", error);
      message.error("File upload failed.");
      openNotification("error", "Error", "File upload failed.");
    }
  };

  const onAdd = () => {
    if (
      !detail2.completed ||
      !detail2.left ||
      !detail2.quantity ||
      !detail2.service_id ||
      !detail2.total_price
    ) {
      setErrors((prev) => ({
        ...prev,
        ...(!detail2.completed && {
          completed: "Please enter no of completed samples",
        }),
        ...(!detail2.left && { left: "Please enter no of left samples" }),
        ...(!detail2.quantity && { quantity: "Please enter no of samples" }),
        ...(!detail2.service_id && { service_id: "Please select service" }),
        ...(!detail2.total_price && {
          total_price: "Please enter total price",
        }),
        ...(!detail2.price && { price: "Please enter total price" }),
      }));
      return;
    }
    setOrder_data([...order_data, detail2]);
    setShow_contact(false);
    setOrder_summary([
      ...order_summary,
      {
        PRODUCT_NAME: detail2?.service?.label,
        PRODUCT_ID: detail2?.service_id,
        NO_OF_SAMPLES: detail2?.quantity,
        PRICE: detail2?.price,
        TOTAL_AMOUNT: detail2?.total_price,
        COMPLETED: detail2?.completed,
        LEFT: detail2?.left,
        REMARK: detail2?.remark,
      },
    ]);
    console.log(detail2, order_summary);
    setDetail2({});
  };

  const allData = () => {
    const orderedServiceIds = new Set(
      order_data?.map((item) => item?.service_id)
    );
    return services_list
      ?.filter((service) => !orderedServiceIds.has(service?.ID))
      .map((service) => ({
        value: service?.ID,
        label: service?.SERVICE_NAME,
        desc: service?.DESCRIPTION,
        price: service?.PRICE,
      }));
  };

  return (
    <CustomModal
      title={edit?"Update Customer":"Add Customer"}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      A4={true}
    >
      <div className="p-0 md:p-6 md:pt-0" ref={pdfRef}>
        <div>
          <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
            1. Customer Information
          </p>
        </div>
        <div className="flex flex-wrap pt-3 w-full gap-4">
          <div className="w-[100%] md:w-[50%]">
            <InputInline
              inline
              label={"Customer ID"}
              value={generateCustomerNumber({
                name: detail?.CUSTOMER_NAME ? detail?.CUSTOMER_NAME : "",
                TOTALCN: serial_data?.CUSTOMER_NUMBER + 1,
              })}
            />
          </div>
          <div className="w-full">
            <InputInline
              onChange={(e) =>
                setDetail({ ...detail, CUSTOMER_NAME: e.target.value })
              }
              inline
              label={"Company Name"}
              value={detail?.CUSTOMER_NAME || ""}
            />
          </div>
          <div className="w-full">
            <InputInline
              onChange={(e) =>
                setDetail({ ...detail, FULL_ADDRESS: e.target.value })
              }
              inline
              label={"Full Address"}
              value={detail?.FULL_ADDRESS || ""}
            />
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) => setDetail({ ...detail, STATE: e.target.value })}
              label={"State"}
              value={detail?.STATE || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({ ...detail, POST_CODE: e.target.value })
              }
              label={"Postcode"}
              value={detail?.POST_CODE || ""}
            />
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) => setDetail({ ...detail, CITY: e.target.value })}
              label={"City / Country"}
              value={detail?.CITY || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({ ...detail, REFERENCE: e.target.value })
              }
              label={"Reference"}
              value={detail?.REFERENCE || ""}
            />
          </div>
        </div>

        <div className="flex gap-4 mt-4 items-center">
          <p className="text-[#0363B0] flex items-center gap-4 text-[20px] font-semibold">
            2. Contact Detail
          </p>
        </div>
        <div className="flex flex-wrap pt-3 w-full gap-4">
          <div className="flex flex-wrap w-[100%] items-center gap-4">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  CONTACT_INFO: [
                    {
                      ...detail.CONTACT_INFO?.[0],
                      CONTACT_PERSON: e.target.value,
                    },
                  ],
                })
              }
              label={"Contact Person Name"}
              value={detail?.CONTACT_INFO?.[0]?.CONTACT_PERSON || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  CONTACT_INFO: [
                    {
                      ...detail.CONTACT_INFO?.[0],
                      CONTACT_EMAIL: e.target.value,
                    },
                  ],
                })
              }
              label={"Contact Email"}
              value={detail?.CONTACT_INFO?.[0]?.CONTACT_EMAIL || ""}
            />
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  CONTACT_INFO: [
                    {
                      ...detail.CONTACT_INFO?.[0],
                      DEPARTMENT: e.target.value,
                    },
                  ],
                })
              }
              label={"Department"}
              value={detail?.CONTACT_INFO?.[0]?.DEPARTMENT || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  CONTACT_INFO: [
                    {
                      ...detail.CONTACT_INFO?.[0],
                      DESIGNATION: e.target.value,
                    },
                  ],
                })
              }
              label={"Designation"}
              value={detail?.CONTACT_INFO?.[0]?.DESIGNATION || ""}
            />
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  CONTACT_INFO: [
                    {
                      ...detail.CONTACT_INFO?.[0],
                      CONTACT_NO: e.target.value,
                    },
                  ],
                })
              }
              label={"Contact Number"}
              value={detail?.CONTACT_INFO?.[0]?.CONTACT_NO || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  CONTACT_INFO: [
                    {
                      ...detail.CONTACT_INFO?.[0],
                      REFERENCE: e.target.value,
                    },
                  ],
                })
              }
              label={"Reference"}
              value={detail?.CONTACT_INFO?.[0]?.REFERENCE || ""}
            />
          </div>
        </div>

        <div>
          <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
            3. PO Detail
          </p>
        </div>
        <div className="flex flex-wrap pt-3 w-full gap-4">
          <div className="w-[calc(50%-8px)]">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [
                    { ...detail.PO_INFO?.[0], PO_NUMBER: e.target.value },
                  ],
                })
              }
              inline
              label={"PO Number"}
              value={generatePONumber({
                name: detail?.CUSTOMER_NAME ? detail?.CUSTOMER_NAME : "",
                TOTALPO: serial_data?.PO_NUMBER + 1,
              })}
            />
            <div className="w-[100%] md:w-[50%]"></div>
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [
                    { ...detail.PO_INFO?.[0], PO_DATE: e.target.value },
                  ],
                })
              }
              label={"PO Date"}
              value={detail?.PO_INFO?.[0]?.PO_DATE || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [
                    { ...detail.PO_INFO?.[0], POST_CODE: e.target.value },
                  ],
                })
              }
              label={"Postcode"}
              value={detail?.PO_INFO?.[0]?.POST_CODE || ""}
            />
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [
                    { ...detail.PO_INFO?.[0], CONTACT_NO: e.target.value },
                  ],
                })
              }
              label={"Contact Number"}
              value={detail?.PO_INFO?.[0]?.CONTACT_NO || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [{ ...detail.PO_INFO?.[0], CITY: e.target.value }],
                })
              }
              label={"City / Country"}
              value={detail?.PO_INFO?.[0]?.CITY || ""}
            />
          </div>
          <div className="flex w-[100%] flex-wrap items-center gap-4">
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [
                    { ...detail.PO_INFO?.[0], PO_EXPIRY_D2ATE: e.target.value },
                  ],
                })
              }
              label={"PO Expiry Date"}
              value={detail?.PO_INFO?.[0]?.PO_EXPIRY_DATE || ""}
            />
            <InputInline
              onChange={(e) =>
                setDetail({
                  ...detail,
                  PO_INFO: [
                    { ...detail.PO_INFO?.[0], REFERENCE: e.target.value },
                  ],
                })
              }
              label={"Reference"}
              value={detail?.PO_INFO?.[0]?.REFERENCE || ""}
            />
          </div>
        </div>
        {/* <div className="flex justify-between items-center mt-4">
          <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
           4. Order Summmary
          </div>
          {
            <CustomButton
              className="px-4"
              size="small"
              title={"Add Order Summary"}
              onClick={() => setShow_contact(true)}
            ></CustomButton>
          }
        </div>
        {show_contact && (
          // className="border px-2 pb-2 pt-1 my-2 shadow-lg rounded-lg"
          <div>
            <div className="flex gap-2 flex-wrap">
              <div className="w-[100%] md:w-[50%]">
                <CustomInput
                  inline
                  light={true}
                  drop
                  options={allData()}
                  value={detail2.service_id}
                  // error={errors?.service_id}
                  onChange={(v, item) => {
                    console.log(item);
                    setDetail2({
                      ...detail2,
                      service: item,
                      service_id: v,
                      price: item.price,
                      quantity: "",
                      total_price: "",
                      completed: "",
                      left: "",
                    });
                    setErrors((prev) => ({
                      ...prev,
                      price: "",
                      service_id: "",
                    }));
                  }}
                  label={"Service"}
                  placeholder={"Select Service"}
                />
              </div>
              <InputInline
                inline
                value={detail2?.quantity || ""}
                // error={errors?.quantity}
                name={"quantity"}
                onChange={(v) => {
                  console.log(detail2);
                  setDetail2({
                    ...detail2,
                    quantity: v.target.value,
                    total_price: detail2.price * v.target.value,
                  });
                  setErrors((prev) => ({
                    ...prev,
                    quantity: "",
                    total_price: "",
                  }));
                }}
                label={"No of samples"}
                placeholder={"Enter No of samples"}
                type={"number"}
              />
            </div>
            <div className="flex mt-4 gap-2 flex-wrap">
              <InputInline
                inline
                value={detail2?.price || ""}
                // error={errors?.price}
                name={"price"}
                onChange={(v) => {
                  setDetail2({
                    ...detail2,
                    price: v.target.value,
                    total_price: v.target.value * detail2?.quantity,
                  });
                  setErrors((prev) => ({
                    ...prev,
                    price: "",
                    total_price: "",
                  }));
                }}
                label={"Price"}
                placeholder={"Enter Price"}
              />
              <InputInline
                inline
                value={detail2?.total_price || ""}
                // error={errors?.total_price}
                name={"total_price"}
                onChange={(v) => {
                  setDetail2({
                    ...detail2,
                    total_price: v.target.value,
                  });
                  setErrors((prev) => ({ ...prev, total_price: "" }));
                }}
                label={"Total Price"}
                placeholder={"Enter Total Price"}
              />
            </div>
            <div className="flex gap-2 flex-wrap">
              <InputInline
                inline
                value={detail2?.completed || ""}
                // error={errors?.completed}
                name={"completed"}
                onChange={(v) => {
                  console.log(
                    "v.target.value > detail.quantity",
                    Number(v.target.value) > Number(detail2.quantity)
                  );
                  if (Number(v.target.value) > Number(detail2.quantity)) {
                    openNotification(
                      "info",
                      "Info",
                      "Completed number cannot be greater than quantity"
                    );
                    return;
                  }
                  setDetail2({
                    ...detail2,
                    completed: v.target.value,
                    left: detail2.quantity - v.target.value,
                  });
                  setErrors((prev) => ({ ...prev, completed: "", left: "" }));
                }}
                label={"No of completed samples"}
                placeholder={"Enter No of completed samples"}
              />
              <InputInline
                inline
                value={detail2?.left || ""}
                // error={errors?.left}
                name={"left"}
                onChange={(v) => {
                  if (
                    Number(v.target.value) + Number(detail2.completed) >
                    Number(detail2.quantity)
                  ) {
                    openNotification(
                      "info",
                      "Info",
                      "Sum of Left samples and completed samples cannot be greater than quantity"
                    );
                    return;
                  }
                  setDetail2({
                    ...detail2,
                    left: v.target.value,
                    // completed: Number(detail.quantity) - v.target.value,
                  });
                  setErrors((prev) => ({ ...prev, left: "" }));
                }}
                label={"No of leftsamples"}
                placeholder={"Enter No of leftsamples"}
              />
            </div>
            <div className="flex gap-2 flex-wrap">
              <InputInline
                inline
                value={detail2?.remark || ""}
                // error={errors?.remark}
                name={"remark"}
                onChange={(v) => {
                  setDetail2({
                    ...detail2,
                    remark: v.target.value,
                  });
                  setErrors((prev) => ({ ...prev, remark: "" }));
                }}
                label={"Remark"}
                placeholder={"Enter Remark"}
              />
            </div>
            <div className="flex justify-end items-center mt-2">
              <Button
                className="px-4 bg-[#0363B0]"
                size="small"
                type="primary"
                onClick={() => {
                  onAdd();
                }}
              >
                Add Order
              </Button>
            </div>
          </div>
        )} */}
        <div>
          <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
            4. ORDER SUMMARY
          </p>
          <CustomTable
            edit={true}
            order_summary={order_summary}
            setOrder_summary={setOrder_summary}
            detail2={detail2}
            options={allData()}
            setDetail2={setDetail2}
          />
        </div>
        {/* {order_data?.length > 0 && (
          <div className="overflow-x-auto mt-2">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b border-gray-200 border-r">
                    S.No.
                  </th>
                  <th className="px-4 py-2 border-b border-gray-200 border-r">
                    Product & Description
                  </th>
                  <th className="px-4 py-2 border-b border-gray-200 border-r">
                    No. of Samples
                  </th>
                  <th className="px-4 py-2 border-b border-gray-200 border-r">
                    Price
                  </th>
                  <th className="px-4 py-2 border-b border-gray-200 border-r">
                    Total Amount
                  </th>
                  <th className=" border-b border-gray-200 border-r ">
                    <p className="py-3">Status</p>
                    <table className="min-w-full ">
                      <thead>
                        <tr className=" flex">
                          <th className="px-4 py-2  border-t border-gray-200 border-r w-[110px]">
                            No. of Samples
                          </th>
                          <th className="px-4 py-2 border-t border-gray-200 border-r w-[110px]">
                            Completed
                          </th>
                          <th className="px-4 py-2 border-t border-gray-200 w-[110px] ">
                            Left
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                  <th className="px-4 py-2 border-b border-gray-200 border-r">
                    Remark
                  </th>
                  <th className="px-4 py-2 border-b border-gray-200">Action</th>
                </tr>
              </thead>
              <tbody>
                {order_data.map((order, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b border-gray-200 border-r">
                      {index + 1}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 border-r">
                      <strong>{order.service.label}</strong>
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 border-r">
                      {order.quantity}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 border-r">
                      {order.price}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 border-r">
                      {order.total_price}
                    </td>
                    <td className="border-b border-gray-200 border-r h-full p-0 m-0">
                      <table className="min-w-full">
                        <tbody>
                          <tr className="flex">
                            <td className="flex-1 px-4 py-2 border-r ">
                              {order.quantity}
                            </td>
                            <td className="flex-1 px-4 py-2 border-r">
                              {order.completed}
                            </td>
                            <td className="flex-1 px-4 py-2">{order.left}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 border-r">
                      {order.remark}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200">
                      <BiTrash
                        size={20}
                        color="red"
                        onClick={() => removeService(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )} */}
        <div className="flex flex-wrap pt-3 w-full gap-4">
          <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
            5. Attachments
          </div>
          <div className="w-full">
            <Dragger
              name="file"
              multiple
              beforeUpload={(file) => {
                setFileList((prevList) => [...prevList, file]);
                return false; // Prevent automatic upload
              }}
              onChange={handleChange}
              fileList={fileList}
              onDrop={(e) => {
                console.log("Dropped files", e.dataTransfer.files);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
          </div>
        </div>
        <div className="flex flex-wrap items-center pt-3 w-full gap-4">
          <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
            6. Order Status
          </div>
          <Select
            placeholder="Select a status"
            className="md:ml-auto w-[100%] md:w-[50%] mt-4"
            style={{ width: 200 }}
            options={[
              { label: "Pending", value: "pending" },
              { label: "Fullfilled", value: "fullfilled" },
              { label: "Failed", value: "failed" },
            ]}
          />
        </div>
        <div className="flex flex-col flex-wrap pt-3 w-full gap-4">
          <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
            7. Audit Trial
          </div>
          <div className="mt-auto flex flex-wrap items-center justify-between">
            <p className="font-semibold"><span className="text-gray-500 font-normal">Created By:</span> Pabba sk guptha</p>
            <p className="font-semibold"><span className="text-gray-500 font-normal">Created Date:</span> 29/07/2024</p>
            <p className="font-semibold"><span className="text-gray-500 font-normal">Last Modified By:</span> Admin</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-8">
        <CustomButton
          onClick={() => setIsModalOpen(false)}
          icon={<IoArrowBack />}
          title={"Cancel"}
          color={"secondary"}
        />
        <div className="flex gap-4">
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={handleUpload}
            title={"Upload"}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default CustomerAdd;
