import React from "react";
import CustomButton from "../CustomButton";
import CustomInput from "../CustomInput";
import { Input } from "antd";
import { FaPlus } from "react-icons/fa";
import { BiTrash } from "react-icons/bi";

const CustomTable = ({
  order_summary = [],
  setOrder_summary,
  options,
  detail2,
  setd,
  edit
}) => {
  const [data, setData] = React.useState({
    service: {},
    service_id: "",
    price: "",
    total_price: "",
    quantity: "",
    left: "",
    completed: "",
    remark: "",
  });
  console.log(data);
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white ">
        <thead>
          <tr>
            <th className="px-2 py-2">S.No</th>
            <th className="px-2 py-2 max-w-[250px] min-w-[250px]">
              Service/Product description
            </th>
            <th className="px-2 py-2 w-[80px]">No of samples</th>
            <th className="px-2 py-2 w-[150px]">Price per sample</th>
            <th className="px-2 py-2 w-[150px]">Total amount</th>
            <th className="px-2 py-2">
              <div className="flex flex-col gap-3  items-center justify-between">
                <span className="">Status</span>
                <div className="flex justify-between gap-4 w-full">
                  <span className="flex-1  text-wrap">No of samples</span>
                  <span className="flex-1 ">Completed</span>
                  <span className="flex-1 ">Left</span>
                </div>
              </div>
            </th>
            <th className="px-2 py-2 w-[80px]">Remark</th>
            {edit&&<th className="px-2 py-2 w-[80px]">Action</th>}
          </tr>
        </thead>
        <tbody>
          {order_summary?.map((item, index) => (
            <tr key={index}>
              <td className="px-2 py-2 text-center border-b">
                <div className="bg-gray-300 w-full px-3 py-2">{index + 1}</div>
              </td>
              <td className="px-2 py-2 border-b max-w-[300px]">
                <div className="w-full bg-[#e1e5e7] p-2">
                  {item?.PRODUCT_NAME}
                </div>
              </td>
              <td className="px-2 py-2 text-center border-b w-[80px]">
                <div className="w-full">
                  <Input
                    variant="borderless"
                    placeholder="Enter"
                    size="middle"
                    className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                    value={item.NO_OF_SAMPLES}
                    inputMode="numeric"
                    onChange={(v) => {
                      setData((pre) => ({
                        ...pre,

                        total_price: data?.service?.price * v.target.value,
                        quantity: v.target.value,

                        completed: "",
                        left: "",
                      }));
                    }}
                  />
                </div>
              </td>
              <td className="px-2 py-2 text-center border-b w-[150px]">
                <div className="w-full">
                  <Input
                    variant="borderless"
                    placeholder="Enter"
                    size="middle"
                    className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                    value={item.PRICE}
                    inputMode="numeric"
                    onChange={(v) => {
                      setData((pre) => ({
                        ...pre,
                        total_price: data.quantity * v.target.value,
                        price: v.target.value,
                      }));
                    }}
                  />
                </div>
              </td>
              <td className="px-2 py-2 text-center border-b w-[150px]">
                <div className="w-full p-2 bg-[#e1e5e7]">
                  {item.TOTAL_AMOUNT}
                </div>
              </td>
              <td className="px-2 py-2 text-center border-b ">
                <div className="flex justify-between gap-4">
                  <span className="w-[80px] bg-[#e1e5e7] p-2">
                    {item.NO_OF_SAMPLES}
                  </span>
                  <span className="w-[80px]">
                    <Input
                      variant="borderless"
                      placeholder="Enter"
                      size="middle"
                      className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                      value={item.COMPLETED}
                      inputMode="numeric"
                      onChange={(v) => {
                        setData((pre) => ({
                          ...pre,
                          left: Number(data.quantity) - Number(v.target.value),
                          completed: v.target.value,
                        }));
                      }}
                    />
                  </span>
                  <span className="w-[80px]">
                    <Input
                      variant="borderless"
                      placeholder="Enter"
                      size="middle"
                      className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                      value={item.LEFT}
                      inputMode="numeric"
                      onChange={(v) => {
                        setData((pre) => ({
                          ...pre,
                          completed:
                            Number(data.quantity) - Number(v.target.value),
                          left: v.target.value,
                        }));
                      }}
                    />
                  </span>
                </div>
              </td>
              <td className="px-2 py-2 text-center border-b w-[80px]">
                <div className="w-full">
                  <Input
                    variant="borderless"
                    placeholder="Enter"
                    size="middle"
                    className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                    value={item.REMARK}
                    onChange={(v) => {
                      setData((pre) => ({
                        ...pre,
                        REMARK: v.target.value,
                      }));
                    }}
                  />
                </div>
              </td>
              {edit&&<td className="px-2 py-2 text-center w-[80px]">
                <BiTrash
                  className="cursor-pointer"
                  size={20}
                  onClick={() => {
                    setOrder_summary([
                      ...order_summary.filter((e, i) => i !== index),
                    ]);
                  }}
                />
              </td>}
            </tr>
          ))}
          {edit&&<tr>
            <td className="px-2 py-2 text-center border-b">
              <div className="bg-gray-300 w-full px-3 py-2">
                {order_summary?.length + 1}
              </div>
            </td>
            <td className="px-2 py-2 border-b max-w-[250px] min-w-[250px]">
              <div className="w-full">
                <CustomInput
                  inline
                  light={true}
                  drop
                  disabled={true}
                  options={options}
                  value={data.service_id}
                  // error={errors?.service_id}
                  onChange={(v, item) => {
                    console.log(item);
                    setData((pre) => ({
                      ...pre,
                      service: item,
                      service_id: v,
                      price: item.price,
                      quantity: "",
                      total_price: "",
                      completed: "",
                      left: "",
                      remark: "",
                    }));
                  }}
                  placeholder={"Select Service"}
                />
              </div>
            </td>
            <td className="px-2 py-2 text-center border-b w-[80px]">
              <div className="w-full">
                <Input
                  variant="borderless"
                  placeholder="Enter"
                  size="middle"
                  className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                  value={data.quantity}
                  inputMode="numeric"
                  onChange={(v) => {
                    setData((pre) => ({
                      ...pre,

                      total_price: data?.service?.price * v.target.value,
                      quantity: v.target.value,

                      completed: "",
                      left: "",
                    }));
                  }}
                />
              </div>
            </td>
            <td className="px-2 py-2 text-center border-b w-[150px]">
              <div className="w-full">
                <Input
                  variant="borderless"
                  placeholder="Enter"
                  size="middle"
                  className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                  value={data.price}
                  inputMode="numeric"
                  onChange={(v) => {
                    setData((pre) => ({
                      ...pre,
                      total_price: data.quantity * v.target.value,
                      price: v.target.value,
                    }));
                  }}
                />
              </div>
            </td>
            <td className="px-2 py-2 text-center border-b w-[150px]">
              <div className="w-full">
                <Input
                  variant="borderless"
                  placeholder="Enter"
                  size="middle"
                  contentEditable={false}
                  className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                  value={data.total_price}
                />
              </div>
            </td>
            <td className="px-2 py-2 text-center border-b ">
              <div className="flex justify-between gap-4">
                <span className="w-[80px]">
                  <Input
                    variant="borderless"
                    placeholder="Enter"
                    size="middle"
                    className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                    contentEditable={false}
                    value={data.quantity}
                  />
                </span>
                <span className="w-[80px]">
                  <Input
                    variant="borderless"
                    placeholder="Enter"
                    size="middle"
                    className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                    value={data.completed}
                    inputMode="numeric"
                    onChange={(v) => {
                      setData((pre) => ({
                        ...pre,
                        left: Number(data.quantity) - Number(v.target.value),
                        completed: v.target.value,
                      }));
                    }}
                  />
                </span>
                <span className="w-[80px]">
                  <Input
                    variant="borderless"
                    placeholder="Enter"
                    size="middle"
                    className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                    value={data.left}
                    inputMode="numeric"
                    onChange={(v) => {
                      setData((pre) => ({
                        ...pre,
                        completed:
                          Number(data.quantity) - Number(v.target.value),
                        left: v.target.value,
                      }));
                    }}
                  />
                </span>
              </div>
            </td>
            <td className="px-2 py-2 text-center border-b w-[80px]">
              <div className="w-full">
                <Input
                  variant="borderless"
                  placeholder="Enter"
                  size="middle"
                  className="rounded-none h-[38px] bg-[#e1e5e7] focus:outline-none hover:outline-none"
                  value={data.remark}
                  onChange={(v) => {
                    setData((pre) => ({
                      ...pre,
                      remark: v.target.value,
                    }));
                  }}
                />
              </div>
            </td>
            <td className="px-2 py-2 text-center w-[80px]">
              <FaPlus
                className="cursor-pointer"
                size={20}
                onClick={() => {
                    setOrder_summary([
                        ...order_summary,
                        {
                          PRODUCT_NAME: data?.service?.label,
                          PRODUCT_ID: data?.service_id,
                          NO_OF_SAMPLES: data?.quantity,
                          PRICE: data?.price,
                          TOTAL_AMOUNT: data?.total_price,
                          COMPLETED: data?.completed,
                          LEFT: data?.left,
                          REMARK: data?.remark,
                        },
                      ]);
                  setData({});
                }}
              />
            </td>
          </tr>}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
