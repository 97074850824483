import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import CustomButton from "../../components/CustomButton";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import { CiEdit, CiTrash } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import TableHeader from "../../components/TableHeader";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchService_list } from "../../redux/slice/dataSlice";
import Loader from "../../components/Loader";
import { CustomModal, Delete_Modal } from "../../components/modals";
import CustomInput, { InputInline } from "../../components/CustomInput";
import axios from "axios";

const user = JSON.parse(localStorage.getItem("lavin"));

const ServicesList = ({ type, selectedServices, serviceModal,setServiceModal,setSelectedServices }) => {
  const navigate = useNavigate();
  const { services_list, services_loading } = useSelector(
    (state) => state.allData
  );
  const [selected, setSelected] = useState([]);
  const [add_modal, setAdd_modal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [detail, setDetail] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(type === 'quotation' ? 100 : 10);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  useEffect(() => {
    store.dispatch(fetchService_list());
  }, []);

  const handleSelect = (id) => {
    if (type === 'quotation') {
      const service = services_list.find((item) => item.ID === id);
      if (selectedServices.some((item) => item.ID === id)) {
        setSelectedServices(selectedServices.filter((item) => item.ID !== id));
      } else {
        setSelectedServices([...selectedServices, service]);
      }
    } else {
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id));
      } else {
        setSelected([...selected, id]);
      }
    }
  };

  const handleSubmit = async () => {
    if (!data.SERVICE_NAME || !data.DESCRIPTION) {
      setErrors((prev) => ({
        ...prev,
        ...(!data.SERVICE_NAME && {
          SERVICE_NAME: "Please enter service name",
        }),
        ...(!data.DESCRIPTION && { DESCRIPTION: "Please enter description" }),
      }));
      return; // Exit early to prevent further processing
    }
    setLoading(true);
    const rawData = {
      ...(edit && { ID: data.ID }),
      USER_ID: user?.USER_ID,
      SERVICE_NAME: data.SERVICE_NAME,
      DESCRIPTION: data.DESCRIPTION,
      PRICE: data.PRICE,
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: edit
        ? `${process.env.REACT_APP_API_KEY}services/update`
        : `${process.env.REACT_APP_API_KEY}services/create`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.TOKEN}`,
      },
      data: rawData,
    };

    try {
      const response = await axios.request(config);
      const res = response.data;

      if (res.success) {
        openNotification("success", "Success", res.message);
        store.dispatch(fetchService_list());
        setAdd_modal(false);
        setEdit(false);
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      console.error("API call error:", error);
      openNotification(
        "error",
        "Error",
        "Something went wrong. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({
      ID: [detail.ID],
    });
    const res = await postApiToken(`services/delete-service`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchService_list());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const filteredData = services_list?.filter((item) => {
    return item?.SERVICE_NAME.toLowerCase().includes(search.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>{type === 'quotation' && 'Select '}Services</HeaderText>
        {type !== 'quotation' && <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Service"}
          onClick={() => {
            setData({});
            setErrors({});
            setEdit(false);
            setAdd_modal(true);
          }}
        />}
      </div>
      {services_loading && services_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 mt-4">
          {type !== 'quotation' && <TableHeader
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            tableHeaders={["Company Name", "Company Type", "City", "Location"]}
            tableData={filteredData.map((e) => [
              e.COMPANY_NAME,
              e.COMPANY_TYPE,
              e.CITY,
              e.LOCATION,
            ])}
            onDeleteAll={() => handleDelete("all")}
            tableName={"Abhishek"}
            fileName={"pddd"}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredData.length}
            onPageChange={(page) => setCurrentPage(page)}
            onLimitChange={(limit) => setItemsPerPage(limit)}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
          />}

          <CustomTable
            list={services_list}
            selected={selected}
            setSelected={setSelected}
            headerData={type === 'quotation' ? ["Service Name", "Description", "Price"] : ["Service Name", "Description", "Price", "Actions"]}
            bodyData={currentItems?.map((item, index) => (
              <tr key={item.ID}>
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={type === 'quotation' ? selectedServices.some((s) => s.ID === item.ID) : selected.includes(item.ID)}
                    onChange={() => handleSelect(item.ID)}
                  />
                </td>

                <td className="py-2 px-4 border-b ">{item.SERVICE_NAME}</td>
                <td className="py-2 px-4 border-b " style={{lineHeight:'normal'}}><p className="line-clamp-3 overflow-hidden">{item.DESCRIPTION}</p></td>
                <td className="py-2 px-4 border-b">{item.PRICE}</td>
                {type !== 'quotation' && <td className="py-2 px-4 border-b ">
                  <div className="flex gap-4">
                    <CiEdit
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setData(item);
                        setEdit(true);
                        setAdd_modal(true);
                      }}
                    />
                    <CiTrash
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setDetail(item);
                        setDelete_modal(true);
                      }}
                    />
                  </div>
                </td>}
              </tr>
            ))}
          />
        </div>
      )}
      <div className="flex justify-end">
        {type === 'quotation' && <CustomButton title={'Submit'} onClick={() => {
          setServiceModal(false);
          console.log(selectedServices);
          }}/>}
      </div>

      <CustomModal
        isModalOpen={add_modal}
        setIsModalOpen={setAdd_modal}
        A4={true}
        title={edit ? "Update Service" : "Add Service"}
      >
        <div className="flex gap-4 flex-wrap">
          <InputInline
            value={data?.SERVICE_NAME || ""}
            error={errors?.SERVICE_NAME}
            name={"SERVICE_NAME"}
            onChange={handleChange}
            label={"Service Name"}
            placeholder={"Enter Service Name"}
          />
          <InputInline
            value={data?.PRICE || ""}
            error={errors?.PRICE}
            name={"PRICE"}
            type={"number"}
            onChange={handleChange}
            label={"Price"}
            placeholder={"Enter Price"}
          />
          <InputInline
            value={data?.DESCRIPTION || ""}
            error={errors?.DESCRIPTION}
            name={"DESCRIPTION"}
            onChange={handleChange}
            label={"Description"}
            placeholder={"Enter Description"}
            area={true}
          />
        </div>
        <div className="flex justify-center mt-8">
          <CustomButton
            title={edit ? "Update" : "Submit"}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </CustomModal>
      <Delete_Modal
        handleDelete={handleDelete}
        isModalOpen={delete_modal}
        setIsModalOpen={setDelete_modal}
        loading={loading}
      ></Delete_Modal>
    </>
  );
};

export default ServicesList;
