import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

// API base URL configuration
export const base_url = "https://api.smartdocs365.com/api/";

// Retrieve logged-in user information
const loggedInUser = localStorage.getItem("userId");
const user = loggedInUser ? JSON.parse(loggedInUser) : null;
export const ID = user?.userid ?? "";

// Email validation regex
export const isValidEmail = /\S+@\S+\.\S+/;

/**
 * Export data to PDF
 * @param {Object} params - Parameters for PDF export
 * @param {Array} params.table_headers - Table headers
 * @param {Array} params.table_data - Table data
 * @param {string} params.tableName - Table name
 * @param {string} params.fileName - File name
 */
export const exportPDF = ({
  table_headers,
  table_data,
  tableName,
  fileName,
}) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);
  const headers = [table_headers];
  const data = table_data;
  console.log(data);
  const content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(tableName, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${fileName}.pdf`);
};

/**
 * Export data to Excel
 * @param {Array} data - Data to be exported
 * @param {string} fileName - File name
 */
export const handleExcel = (data, fileName) => {
  if (data?.length > 0) {
    const ws = XLSX.utils.json_to_sheet(data.map((v) => v));

    // Check if ws["!ref"] is defined before using it
    const firstRowRange = ws["!ref"]
      ? XLSX.utils.decode_range(ws["!ref"])
      : null;

    if (firstRowRange) {
      for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: firstRowRange.s.r,
          c: col,
        });
        ws[cellAddress].s = { fill: { fgColor: { rgb: "DDDDDD" } } }; // Gray color
      }

      for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
        let maxCellLength = 30;

        for (let row = firstRowRange.s.r + 1; row <= firstRowRange.e.r; row++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cellValue = ws[cellAddress] ? ws[cellAddress].w || "" : "";
          maxCellLength = Math.max(maxCellLength, cellValue.length);
        }

        const colWidth = maxCellLength + 1; // Adjust as needed
        ws["!cols"] = ws["!cols"] || [];
        ws["!cols"][col] = { width: colWidth };
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
        mimeType: "application/octet-stream",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(blob, `${fileName}.xlsx`);
    } else {
      toast.error("Please Add Some Item");
    }
  } else {
    toast.error("No data to export");
  }
};
