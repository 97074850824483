import React, { useEffect, useState } from "react";
import HeaderText from "../../components/HeaderText";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import axios from "axios";
import { Modal } from "antd";
import { openNotification } from "../../App";
import { store } from "../../redux/store";
import { fetchLead_list } from "../../redux/slice/dataSlice";
import { putApiToken } from "../../utils/apis";

const UpadteContactInfo = ({
  isModalOpen,
  setIsModalOpen,
  detail = {},
  main_id = "",
  onUpdate,
}) => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };
  const handleSubmit = async () => {
    // if (
    //   !data.EMAIL_ID ||
    //   !data.CONTACT_PERSON ||
    //   !data.MOBILE_NUMBER ||
    //   !data.DEPARTMENT ||
    //   !data.DESIGNATION ||
    //   !data.COMMENT ||
    //   !data.NOTES
    // ) {
    //   setErrors({
    //     ...errors,
    //     EMAIL_ID: !data.EMAIL_ID ? "Please enter email address" : "",
    //     CONTACT_PERSON: !data.CONTACT_PERSON ? "Please enter person name" : "",
    //     MOBILE_NUMBER: !data.MOBILE_NUMBER ? "Please enter person contact number" : "",
    //     DEPARTMENT: !data.DEPARTMENT ? "Please enter department" : "",
    //     DESIGNATION: !data.DESIGNATION ? "Please enter DESIGNATION" : "",
    //     COMMENT: !data.COMMENT ? "Please enter comment " : "",
    //     NOTES: !data.NOTES ? "Please enter notes" : "",
    //   });
    //   return;
    // }

    try {
      setLoading(true);
      let rawdata = JSON.stringify({
        USER_ID: user?.USER_ID,
        ID: main_id,
        CONTACT_INFO: {
          CONTACT_PERSON: !data.CONTACT_PERSON ? "demo1" : data.CONTACT_PERSON,
          MOBILE_NUMBER: !data.MOBILE_NUMBER
            ? "9876543211"
            : data.MOBILE_NUMBER,
          DESIGNATION: !data.DESIGNATION ? "demo1" : data.DESIGNATION,
          EMAIL_ID: !data.EMAIL_ID ? "demo1@gami.com" : data.EMAIL_ID,
          DEPARTMENT: !data.DEPARTMENT ? "demo1" : data.DEPARTMENT,
          COMMENT: !data.COMMENT ? "Comment" : data.COMMENT,
          NOTES: !data.NOTES ? "Notes" : data.NOTES,
          ID: detail.ID,
        },
      });

      const res = await putApiToken("leads/update-contact-info", rawdata);

      setLoading(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        setIsModalOpen(false);
        store.dispatch(fetchLead_list());
        onUpdate();
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading(false);
      openNotification("error", "Error", "res.message");
    }
  };

  useEffect(() => {
    setData(detail);
  }, [detail]);

  return (
    <Modal
      title=""
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      centered
      width={"60%"}
      footer={null} // Use `null` instead of `false` for footer prop
      bodyStyle={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
    >
      <div className="flex items-center justify-between">
        <HeaderText>Update Contact Person Detail</HeaderText>
        {/* <CustomButton
          icon={<IoArrowBack size={20} />}
          title={"Back"}
          onClick={() => navigate(-1)}
        /> */}
      </div>

      <div className="gap-4 flex flex-col mt-8">
        <div className="flex gap-4 ">
          <CustomInput
            value={data?.CONTACT_PERSON}
            error={errors?.CONTACT_PERSON}
            name={"CONTACT_PERSON"}
            onChange={handleChange}
            label={"Contact Person"}
            placeholder={"Enter Contact Person Name"}
            className={"flex-1"}
          />
          <CustomInput
            value={data?.EMAIL_ID}
            error={errors?.EMAIL_ID}
            name={"EMAIL_ID"}
            onChange={handleChange}
            label={"Email ID"}
            placeholder={"Enter Email ID"}
            className={"flex-1"}
          />
        </div>
        <div className="flex gap-4">
          <CustomInput
            value={data?.MOBILE_NUMBER}
            error={errors?.MOBILE_NUMBER}
            name={"MOBILE_NUMBER"}
            onChange={handleChange}
            maxLength={10}
            label={"Mobile Number"}
            placeholder={"Enter Mobile Number"}
            className={"flex-1"}
          />
          <CustomInput
            value={data?.DESIGNATION}
            error={errors?.DESIGNATION}
            name={"DESIGNATION"}
            onChange={handleChange}
            label={"Designation"}
            placeholder={"Enter Designation Name"}
            className={"flex-1"}
          />
        </div>
        <div className="flex gap-4">
          <CustomInput
            value={data?.DEPARTMENT}
            error={errors?.DEPARTMENT}
            name={"DEPARTMENT"}
            onChange={handleChange}
            label={"Department"}
            placeholder={"Enter Designation Name"}
            className={"flex-1"}
          />
          <div className="flex-1" />
        </div>
        <div className="flex gap-4">
          <CustomInput
            value={data?.COMMENT}
            error={errors?.COMMENT}
            name={"COMMENT"}
            onChange={handleChange}
            area={true}
            label={"Comment"}
            placeholder={"Enter Comment"}
            className={"flex-1"}
          />
          <CustomInput
            value={data?.NOTES}
            error={errors?.NOTES}
            name={"NOTES"}
            onChange={handleChange}
            area={true}
            label={"Notes"}
            placeholder={"Enter Notes"}
            className={"flex-1"}
          />
        </div>

        <div className="justify-center items-center flex mt-3 gap-4">
          <CustomButton
            title={"Update"}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpadteContactInfo;
