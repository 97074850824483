import React, { useContext, useState, useMemo, useCallback } from "react";
import { Layout, Modal, Popover, Drawer, Menu } from "antd";
import { BiLogOut, BiSolidUserCircle, BiUser } from "react-icons/bi";
import {
  RiMenuFold3Fill,
  RiMenuFold4Fill,
  RiDashboardFill,
  RiStockLine,
  RiFileList2Line,
} from "react-icons/ri";
import { AiOutlineTeam, AiOutlineProduct } from "react-icons/ai";
import { FaRegCalendarCheck, FaBars, FaUsers } from "react-icons/fa";
import { ImLab } from "react-icons/im";
import { FiUsers } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/svg/LOGO2.svg";
import myContext from "../context/myContext";
import CustomButton from "./CustomButton";
import { ReactComponent as Alarm } from "../assets/svg/Alarm.svg";

const { Header } = Layout;

const HeaderComponent = () => {
  const { collapsed, setCollapsed } = useContext(myContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (items.some((item) => item.key === latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const items = useMemo(
    () => [
      {
        key: "1",
        icon: <RiDashboardFill size={20} />,
        label: "Dashboard",
        path: "/dashboard",
      },
      {
        key: "sub1",
        icon: <AiOutlineTeam size={20} />,
        label: "Team Structure",
        children: [
          { key: "2.1", label: "Admin", path: "/teams/admin" },
          { key: "2.2", label: "Manager", path: "/teams/manager" },
          { key: "2.3", label: "Executive", path: "/teams/executive" },
        ],
      },
      {
        key: "3",
        icon: <FaRegCalendarCheck size={20} />,
        label: "Attendance",
        path: "/attendance",
      },
      {
        key: "sub2",
        label: "Lead Generation",
        icon: <RiStockLine size={20} />,
        children: [
          { key: "4.1", label: "Leads", path: "/lead-generation/leads" },
          {
            key: "4.2",
            label: "Lead RecycleBin",
            path: "/lead-generation/recycle-bin",
          },
        ],
      },
      {
        key: "5",
        icon: <RiFileList2Line size={20} />,
        label: "Quick Quotation",
        children: [
          {
            key: "5.1",
            label: "Generate Quotation",
            path: "/quick-quotation/generate-quotation",
          },
          {
            key: "5.2",
            label: "Quotation List",
            path: "/quick-quotation/list",
          },
        ],
      },
      {
        key: "6",
        icon: <AiOutlineProduct size={20} />,
        label: "Services",
        path: "/services",
      },
      {
        key: "8",
        icon: <ImLab size={20} />,
        label: "LAB",
        path: "/lab",
      },
      {
        key: "sub5",
        icon: <FiUsers size={20} />,
        label: "Customer",
        children: [
          { key: "9.1", label: "Customer List", path: "/customer/info" },
          {
            key: "9.2",
            label: "Customer RecycleBin",
            path: "/customer/recycle-bin",
          },
        ],
      },
  {
    key: "8",
    icon: <FaUsers size={20} />,
    label: "HR Portal",
    children: [
      {
        key: "8.1",
        label: "Employee List",
        path: "/hr-portal/home",
      },

      {
        key: "8.2",
        label: "Job Application Form",
        path: "/hr-portal/send-application-form",
      },
    ],
  },
    ],
    []
  );

  const renderMenuItems = useCallback(
    (items) =>
      items.map((item) => {
        if (item.children) {
          return (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {renderMenuItems(item.children)}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            onClick={() => item.path && setDrawerVisible(false)}
          >
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        );
      }),
    []
  );

  return (
    <div className="flex justify-between items-center bg-[#fff] px-6 md:pr-10 h-[70px] ">
      {collapsed ? (
        <RiMenuFold4Fill
          size={35}
          className="cursor-pointer hidden md:block"
          onClick={() => setCollapsed(!collapsed)}
          aria-label="Expand menu"
        />
      ) : (
        <RiMenuFold3Fill
          size={35}
          className="cursor-pointer hidden md:block"
          onClick={() => setCollapsed(!collapsed)}
          aria-label="Collapse menu"
        />
      )}
      <div className="md:hidden">
        <img
          src={Logo}
          alt=""
          className="object-contain min-w-fit h-[70px] w-full p-2"
        />
      </div>
      <div className="md:ml-auto hidden md:flex gap-6 ">
        <Alarm
          className="h-[35px] w-[35px] ml-auto object-contain cursor-pointer"
          aria-label="Alarm"
        />
        <Popover
          placement="bottomRight"
          title={""}
          content={
            <ul className="min-w-[180px] gap-1 flex flex-col">
              <li className="group">
                <Link
                  to="/"
                  className="flex gap-2 px-4 font-calibri font-medium py-2 rounded-md group-hover:bg-[#0363B0] bg-gray-200 text-[#333333]"
                >
                  <BiUser className="group-hover:text-white" size={20} />
                  <span className="group-hover:text-white">Profile</span>
                </Link>
              </li>
              <li className="group">
                <div
                  onClick={() => setIsModalOpen(true)}
                  className="flex gap-2 px-4 font-calibri font-medium py-2 rounded-md group-hover:bg-[#0363B0] bg-gray-200 text-[#333333] cursor-pointer"
                >
                  <BiLogOut className="group-hover:text-white" size={20} />
                  <span className="group-hover:text-white">Log Out</span>
                </div>
              </li>
            </ul>
          }
        >
          <BiSolidUserCircle
            size={40}
            color="#0363B0"
            aria-label="User profile"
          />
        </Popover>
      </div>
      <div className=" md:!hidden">
        <div className="md:hidden flex gap-4 items-center ml-auto">
          <Alarm
            className="h-[35px] w-[35px] ml-auto object-contain cursor-pointer"
            aria-label="Alarm"
          />
          <FaBars
            className="text-[24px] cursor-pointer"
            onClick={() => setDrawerVisible(true)}
            aria-label="Open drawer"
          />
        </div>

        <Drawer
          title="Menu"
          placement="right"
          width={"70%"}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            <Menu.Item icon={<BiSolidUserCircle size={20} />}>
              <Popover
                placement="bottomRight"
                title={""}
                content={
                  <ul className="min-w-[180px] gap-1 flex flex-col">
                    <li className="group">
                      <Link
                        to="/"
                        className="flex gap-2 px-4 font-calibri font-medium py-2 rounded-md group-hover:bg-[#0363B0] bg-gray-200 text-[#333333]"
                      >
                        <BiUser className="group-hover:text-white" size={20} />
                        <span className="group-hover:text-white">Profile</span>
                      </Link>
                    </li>
                    <li className="group">
                      <div
                        onClick={() => setIsModalOpen(true)}
                        className="flex gap-2 px-4 font-calibri font-medium py-2 rounded-md group-hover:bg-[#0363B0] bg-gray-200 text-[#333333] cursor-pointer"
                      >
                        <BiLogOut
                          className="group-hover:text-white"
                          size={20}
                        />
                        <span className="group-hover:text-white">Log Out</span>
                      </div>
                    </li>
                  </ul>
                }
              >
                User
              </Popover>
            </Menu.Item>
            {renderMenuItems(items)}
          </Menu>
        </Drawer>
      </div>
      <Modal
        title="Log out"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        centered
        footer={
          <div className="flex gap-4 justify-end">
            <CustomButton
              title="Cancel"
              className="h-[28px] min-w-[80px]"
              onClick={() => setIsModalOpen(false)}
            />
            <CustomButton
              onClick={() => {
                localStorage.removeItem("lavin");
                setIsModalOpen(false);
                navigate("/login");
              }}
              title="Log Out"
              className="h-[28px] min-w-[80px]"
            />
          </div>
        }
      >
        <p className="font-calibri font-normal text-[16px]">
          Are you sure you want to Log Out?
        </p>
      </Modal>
    </div>
  );
};

export default HeaderComponent;
