import React, { useEffect, useState } from "react";
import { ReactComponent as Login_img } from "../../assets/svg/login.svg";
import { ReactComponent as Logo } from "../../assets/svg/Logo1.svg";
import CustomInput from "../../components/CustomInput";
import {
  AiOutlineMail,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { MdOutlineLock } from "react-icons/md";
import CustomButton from "../../components/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { postApi } from "../../utils/apis";
import { openNotification } from "../../App";

const Login = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handleSubmit = async () => {
    if (!data.id || !data.password) {
      setError({
        ...error,
        id: !data.id ? "Please enter your email address" : "",
        password: !data.password ? "Please enter your password" : "",
      });
      return;
    }

    setLoading(true);
    let rawData = JSON.stringify({
      EMAIL_ID: data.id, // Ensure you are using the entered data
      PASSWORD: data.password,
    });

    try {
      const res = await postApi("login", rawData);
      setLoading(false);
      console.log(res);

      if (res.success) {
        localStorage.setItem("lavin", JSON.stringify(res));
        localStorage.setItem("TOKEN", res.TOKEN); // Store the token separately
        openNotification("success", "Success", res.message);
        window.location.reload();
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Login API Error:", error);
      openNotification("error", "Error", "An error occurred during login.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const user = JSON.parse(localStorage.getItem("lavin"));

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  return (
    <div className="flex h-screen w-screen bg-[#F1F3F6]">
      <div className="bg-[#FFF] flex h-full w-full flex-[0.6] justify-center items-center flex-col p-5">
        <Logo className="h-[60px] w-full object-contain mb-3" />
        <p className="text-[17px] font-calibri font-semibold text-[#555555] my-5">
          Login into your account
        </p>
        <div className="flex w-full px-6 flex-col gap-4">
          <CustomInput
            light={true}
            inputClassName={"flex-1"}
            className={"flex-1"}
            label={"Email address"}
            placeholder={"Enter Email address"}
            rightIcon={
              <div className="bg-[#0363B0] p-3">
                <AiOutlineMail size={25} color="#FFF" />
              </div>
            }
            mobileBreak={true}
            value={data.id}
            name={"id"}
            onChange={handleChange}
            error={error.id}
          />
          <CustomInput
            light={true}
            inputClassName={"flex-1"}
            className={"flex-1"}
            label={"Password"}
            placeholder={"Enter Password"}
            mobileBreak={true}
            rightIcon={
              <div
                className="bg-[#0363B0] p-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible size={25} color="#FFF" />
                ) : (
                  <AiOutlineEye size={25} color="#FFF" />
                )}
              </div>
            }
            type={showPassword ? "text" : "password"}
            value={data.password}
            name={"password"}
            onChange={handleChange}
            error={error.password}
          />
          <div className="flex items-center justify-end my-3">
            <Link className="text-[17px] font-calibri font-medium underline text-[#0363B0]">
              Forgot password?
            </Link>
          </div>
          <CustomButton
            onClick={handleSubmit}
            className={"max-w-full"}
            title={"Signin"}
            loading={loading}
          />
        </div>
      </div>
      <div className="flex flex-1 justify-center items-center">
        <Login_img className="h-[70%] w-[70%] object-contain" />
      </div>
    </div>
  );
};

export default Login;
