import React from "react";
import AddLead from "./AddLead";
import HeaderText from "../../components/HeaderText";

const LeadAdd = () => {
  return (
    <div className="mx">
      <HeaderText>All Lead Data</HeaderText>
      <div className="bg-[#fff] p-4 mt-4">
        <AddLead />
      </div>
    </div>
  );
};

export default LeadAdd;
