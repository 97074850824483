import React from "react";

const HeaderText = ({ children }) => {
  return (
    <h1 className="text-[#0363B0] text-center ml-auto mr-auto text-[32px] font-bold font-calibri">
      {children}
    </h1>
  );
};

export default HeaderText;
