import React, { useEffect, useState } from "react";
import HeaderText from "../../components/HeaderText";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchCustomer_trash_list } from "../../redux/slice/dataSlice";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { Delete_Modal } from "../../components/modals";
import CustomTable from "../../components/CustomTable";
import TableHeader from "../../components/TableHeader";
import Loader from "../../components/Loader";
import { CiTrash } from "react-icons/ci";
import moment from "moment";
import CustomerView from "./CustomerView";
import { AiOutlineRetweet } from "react-icons/ai";

const CustomerTrash = () => {
  const { customer_trash_list, customer_trash_loading } = useSelector(
    (state) => state.allData
  );
  const [selected, setSelected] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [view_modal, setView_modal] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [retrive, setRetrive] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    store.dispatch(fetchCustomer_trash_list());
  }, []);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleDelete = async (type) => {
    setLoading(true);
    const body = JSON.stringify({
      ID: type === "single" ? [detail.ID] : selected,
    });
    const res = await postApiToken(
      retrive
        ? "customer-po/retrieve-customer"
        : `customer-po/delete-customer-from-trash`,
      body
    );
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchCustomer_trash_list());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };
  const handleRetrieve = async (type) => {
    setLoading(true);
    const body = JSON.stringify({
      ID: type === "single" ? [detail.ID] : selected,
    });
    const res = await postApiToken("customer-po/retrieve-customer", body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchCustomer_trash_list());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const filteredData = customer_trash_list?.filter((item) => {
    return (
      item.CUSTOMER_NAME?.toLowerCase().includes(search.toLowerCase()) ||
      item.CUSTOMER_ID.toLowerCase().includes(search.toLowerCase())
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <HeaderText>Customers Trash List</HeaderText>
      {customer_trash_loading && customer_trash_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] max-w-[1100px] p-4 mt-4">
          <TableHeader
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            tableHeaders={[
              "CUSTOMER_NAME",
              "Customer ID",
              "Created BY",
              "Created Date",
            ]}
            tableData={filteredData.map((e) => [
              e.CUSTOMER_NAME,
              e.CUSTOMER_ID,
              e.CREATED_BY_NAME,
              moment(e.CREATED_AT).format("DD-MM-YYYY"),
            ])}
            tableName={"Abhishek"}
            fileName={"pddd"}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredData.length}
            onPageChange={(page) => setCurrentPage(page)}
            onLimitChange={(limit) => setItemsPerPage(limit)}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
            onDeleteAll={() => handleDelete("all")}
            onRetrieveAll={() => {
              handleRetrieve("all");
            }}
            recycle={true}
          />
          <div className="max-w-[1100px]">
            <CustomTable
              list={customer_trash_list}
              selected={selected}
              setSelected={setSelected}
              headerData={["CUSTOMER_NAME", "Customer ID", "Actions"]}
              bodyData={currentItems?.map((item, index) => (
                <tr key={item.ID}>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selected.includes(item.ID)}
                      onChange={() => handleSelect(item.ID)}
                    />
                  </td>
                  <td className="py-2 px-4 cursor-pointer border-b">
                    {item.CUSTOMER_NAME}
                  </td>
                  <td className="py-2 px-4 border-b ">
                    <div
                      className="text-[#0363B0] cursor-pointer"
                      onClick={() => {
                        setDetail(item);
                        setView_modal(true);
                      }}
                    >
                      {item.CUSTOMER_ID}
                    </div>
                  </td>

                  <td className="py-2 px-4 border-b ">
                    <div className="flex gap-4">
                      <AiOutlineRetweet
                        size={20}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => {
                          setRetrive(true);
                          setDetail(item);
                          setDelete_modal(true);
                        }}
                      />
                      <CiTrash
                        size={20}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => {
                          setRetrive(false);
                          setDetail(item);
                          setDelete_modal(true);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            />
          </div>
        </div>
      )}

      <Delete_Modal
        handleDelete={() => handleDelete("single")}
        isModalOpen={delete_modal}
        setIsModalOpen={setDelete_modal}
        loading={loading}
        mess={`Are you sure you want to ${
          retrive ? "retrieve" : "delete permanently"
        }?`}
        retrive={retrive}
      ></Delete_Modal> 
      <CustomerView
        detail={detail}
        setIsModalOpen={setView_modal}
        isModalOpen={view_modal}
      />
    </>
  );
};

export default CustomerTrash;
