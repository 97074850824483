import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import { CiTrash } from "react-icons/ci";
import TableHeader from "../../components/TableHeader";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchContact_tarsh_info } from "../../redux/slice/dataSlice";
import Loader from "../../components/Loader";
import { Delete_Modal } from "../../components/modals";
import { AiOutlineRetweet } from "react-icons/ai";

const user = JSON.parse(localStorage.getItem("lavin"));
const ContactTrash = () => {
  const navigate = useNavigate();
  const { contact_trash_list, contact_trash_loading, contact_total_count } =
    useSelector((state) => state.allData);
  const [selected, setSelected] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [retrive, setRetrive] = useState(false);

  useEffect(() => {
    store.dispatch(fetchContact_tarsh_info());
  }, []);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({
      ID: [detail.ID],
    });
    const res = await postApiToken(
      retrive
        ? "contact/retrieve-contact"
        : `contact/delete-contact-from-trash`,
      body
    );
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchContact_tarsh_info());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  return (
    <>
      <HeaderText>Contact RecycleBin</HeaderText>
      {contact_trash_loading && contact_trash_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 mt-4">
          <TableHeader />
          <CustomTable
            list={contact_trash_list}
            selected={selected}
            setSelected={setSelected}
            headerData={["Name", "Phone Number", "Email", "Actions"]}
            bodyData={contact_trash_list.map((item, index) => (
              <tr key={item.ID}>
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.ID)}
                    onChange={() => handleSelect(item.ID)}
                  />
                </td>

                <td className="py-2 px-4 border-b ">{item.NAME}</td>
                <td className="py-2 px-4 border-b">{item.PHONE_NUMBER}</td>
                <td className="py-2 px-4 border-b">{item.EMAIL}</td>

                <td className="py-2 px-4 border-b ">
                  <div className="flex gap-4">
                    <AiOutlineRetweet
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setRetrive(true);
                        setDetail(item);
                        setDelete_modal(true);
                      }}
                    />
                    <CiTrash
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setRetrive(false);
                        setDetail(item);
                        setDelete_modal(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          />
        </div>
      )}
      <Delete_Modal
        handleDelete={handleDelete}
        isModalOpen={delete_modal}
        setIsModalOpen={setDelete_modal}
        loading={loading}
        retrive={retrive}
        mess={"Are you sure you want to retrieve data?"}
      ></Delete_Modal>
    </>
  );
};

export default ContactTrash;
