import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import CustomButton from "../../components/CustomButton";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import { CiEdit, CiTrash } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import TableHeader from "../../components/TableHeader";
import { Switch, Tooltip } from "antd";
import { postApiToken, putApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchAdmin_list } from "../../redux/slice/dataSlice";
import { CustomModal, Delete_Modal } from "../../components/modals";
import CustomInput, { InputInline } from "../../components/CustomInput";
import CustomDropdown from "../../components/CustomDropdown";
import Loader from "../../components/Loader";

const TeamAdmin = () => {
  const navigate = useNavigate();
  const { admin_list, admin_loading } = useSelector((state) => state.allData);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    store.dispatch(fetchAdmin_list());
  }, []);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value?.trimStart() };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    const body = JSON.stringify({
      USER_ID: detail.USER_ID,
    });
    const res = await postApiToken(`team-structure/delete-user`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchAdmin_list());
      setIsModalOpen(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const handleBolckUnblock = async (id, status) => {
    setLoading(true);
    const body = JSON.stringify({
      USER_ID: id,
      STATUS: status,
    });
    const res = await putApiToken(`team-structure/block`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchAdmin_list());
      setIsModalOpen(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const handleSubmit = async () => {
    if (
      !data?.FIRST_NAME ||
      !data?.LAST_NAME ||
      !data?.EMAIL_ADDRESS ||
      !data?.PHONE_NUMBER ||
      !data?.GENDER ||
      !data?.ADDRESS ||
      !data?.DATE_OF_BIRTH ||
      !data?.FATHER_NAME
    ) {
      setErrors({
        ...errors,
        FIRST_NAME: !data?.FIRST_NAME ? "Enter First Name" : "",
        LAST_NAME: !data?.LAST_NAME ? "Enter Last Name" : "",
        EMAIL_ADDRESS: !data?.EMAIL_ADDRESS ? "Enter Email Address" : "",
        PHONE_NUMBER: !data?.PHONE_NUMBER ? "Enter Phone Number" : "",
        GENDER: !data?.GENDER ? "Enter Gender" : "",
        ADDRESS: !data?.ADDRESS ? "Enter Address" : "",
        DATE_OF_BIRTH: !data?.DATE_OF_BIRTH ? "Enter Date Of Birth" : "",
        FATHER_NAME: !data?.FATHER_NAME ? "Enter Father's Name" : "",
      });
      return;
    }
    setLoading(true);
    let body = JSON.stringify({
      FIRST_NAME: data.FIRST_NAME,
      LAST_NAME: data.LAST_NAME,
      FATHER_NAME: data.FATHER_NAME,
      DATE_OF_BIRTH: data.DATE_OF_BIRTH,
      ADDRESS: data.ADDRESS,
      PHONE_NUMBER: data.PHONE_NUMBER,
      EMAIL_ADDRESS: data.EMAIL_ADDRESS,
      GENDER: data.GENDER,
      ROLE: "ADMIN",
      COUNTRY:'India',
      STATE:'Madhya Pradesh'
    });
    let body1 = JSON.stringify({
      USER_ID: data.USER_ID,
      FIRST_NAME: data.FIRST_NAME,
      LAST_NAME: data.LAST_NAME,
      FATHER_NAME: data.FATHER_NAME,
      DATE_OF_BIRTH: data.DATE_OF_BIRTH,
      ADDRESS: data.ADDRESS,
      PHONE_NUMBER: data.PHONE_NUMBER,
      EMAIL_ADDRESS: data.EMAIL_ADDRESS,
      GENDER: data.GENDER,
      ROLE: "ADMIN",
    });
    const res = edit
      ? await putApiToken(`team-structure/profile-update`, body1)
      : await postApiToken(`team-structure/create`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchAdmin_list());
      setData({});
      setIsModalOpen1(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  // Filtered and paginated data
  const filteredData = admin_list.filter((item) =>
    (
      item.userInfo[0]?.FIRST_NAME +
      " " +
      item.userInfo[0]?.LAST_NAME
    )
      .toLowerCase()
      .includes(search.toLowerCase()) ||
    item.userInfo[0]?.EMAIL_ADDRESS.toLowerCase().includes(
      search.toLowerCase()
    ) ||
    item.userInfo[0]?.PHONE_NUMBER.includes(search)||
    item.userInfo[0]?.GENDER.includes(search)||
    item.userInfo[0]?.FATHER_NAME.includes(search)||
    item.userInfo[0]?.ADDRESS.includes(search)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>Admin</HeaderText>
        <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Admin"}
          onClick={() => {
            setData({});
            setErrors({});
            setEdit(false);
            setIsModalOpen1(true);
          }}
        />
      </div>

      {admin_loading && admin_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 mt-4">
          <TableHeader
            TOTAL_NUMBER={admin_list.length}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            tableHeaders={[
              "Full Name",
              "Email Address",
              "Phone Number",
              "Gender",
              "Father's Name",
              "DOB",
              "Address",
              "Block",
            ]}
            tableData={filteredData.map((e) => [
              e.userInfo[0]?.FIRST_NAME + e.userInfo[0]?.LAST_NAME,
              e.userInfo[0]?.EMAIL_ADDRESS,
              e.userInfo[0]?.PHONE_NUMBER,
              e.userInfo[0]?.GENDER,
              e.userInfo[0]?.FATHER_NAME,
              e.userInfo[0]?.DATE_OF_BIRTH,
              e.userInfo[0]?.ADDRESS,
              e.userInfo[0]?.BLOCKED,
            ])}
            tableName={"Abhishek"}
            fileName={"pddd"}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredData.length}
            onPageChange={(page) => setCurrentPage(page)}
            onLimitChange={(limit) => setItemsPerPage(limit)}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
          />
          <CustomTable
            user_id
            list={admin_list}
            selected={selected}
            setSelected={setSelected}
            headerData={[
              "Full Name",
              "Email Address",
              "Phone Number",
              "Gender",
              "Father's Name",
              "DOB",
              "Address",
              "Actions",
              "Block",
            ]}
            bodyData={currentItems.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.USER_ID)}
                    onChange={() => handleSelect(item.USER_ID)}
                  />
                </td>

                <td
                  className="py-2 px-4 border-b cursor-pointer hover:text-[#0363B0]"
                  onClick={() => ""}
                >
                  {item.userInfo[0]?.FIRST_NAME +
                    " " +
                    item.userInfo[0]?.LAST_NAME}
                </td>
                <td className="py-2 px-4 border-b">
                  {item.userInfo[0]?.EMAIL_ADDRESS}
                </td>
                <td className="py-2 px-4 border-b">
                  {item.userInfo[0]?.PHONE_NUMBER}
                </td>
                <td className="py-2 px-4 border-b">
                  {item.userInfo[0]?.GENDER}
                </td>
                <td className="py-2 px-4 border-b">
                  {item.userInfo[0]?.FATHER_NAME}
                </td>
                <td className="py-2 px-4 border-b">
                  {item.userInfo[0]?.DATE_OF_BIRTH}
                </td>
                <td className="py-2 px-4 border-b">
                  <Tooltip title={item.userInfo[0]?.ADDRESS}>
                    <span className="line-clamp-3 max-w-[200px] break-words">
                      {item.userInfo[0]?.ADDRESS}
                    </span>
                  </Tooltip>
                </td>
                <td className="py-2 px-4 border-b ">
                  <div className="flex gap-4">
                    <CiEdit
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setData(item?.userInfo[0]);
                        setEdit(true);
                        setIsModalOpen1(true);
                      }}
                    />
                    <CiTrash
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setDetail(item);
                        setIsModalOpen(true);
                      }}
                    />
                  </div>
                </td>
                <td className="py-2 px-4 border-b">
                  <Switch
                    checked={item?.userInfo[0]?.BLOCKED}
                    onChange={(e) => {
                      console.log(e);
                      handleBolckUnblock(item?.USER_ID, e ? 1 : 0);
                    }}
                  />
                </td>
              </tr>
            ))}
          />
        </div>
      )}
      <Delete_Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleDelete={handleDelete}
        loading={loading}
      />
      <CustomModal
        title={`${edit?'Update':'Add'} Admin`}
        isModalOpen={isModalOpen1}
        setIsModalOpen={setIsModalOpen1}
        A4={true}
      >
        <div className="py-6 mt-4 gap-4 flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <InputInline
              value={data?.FIRST_NAME || ""}
              error={errors?.FIRST_NAME}
              name={"FIRST_NAME"}
              onChange={handleChange}
              label={"First Name"}
              placeholder={"Enter First Name"}
              className={"flex-1"}
            />
            <InputInline
              value={data?.LAST_NAME || ""}
              error={errors?.LAST_NAME}
              name={"LAST_NAME"}
              onChange={handleChange}
              label={"Last Name"}
              placeholder={"Enter Last Name"}
              className={"flex-1"}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <InputInline
              value={data?.EMAIL_ADDRESS || ""}
              error={errors?.EMAIL_ADDRESS}
              name={"EMAIL_ADDRESS"}
              onChange={handleChange}
              label={"Email Address"}
              placeholder={"Enter Email Address"}
              className={"flex-1"}
            />
            <InputInline
              value={data?.PHONE_NUMBER || ""}
              error={errors?.PHONE_NUMBER}
              name={"PHONE_NUMBER"}
              maxLength={10}
              onChange={handleChange}
              label={"Phone Number"}
              placeholder={"Enter Phone Number"}
              className={"flex-1"}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <InputInline
              value={data?.FATHER_NAME || ""}
              error={errors?.FATHER_NAME}
              name={"FATHER_NAME"}
              onChange={handleChange}
              label={"Father's Name"}
              placeholder={"Enter Father's  Name"}
              className={"flex-1"}
            />
            <InputInline
              value={data?.DATE_OF_BIRTH || ""}
              error={errors?.DATE_OF_BIRTH}
              name={"DATE_OF_BIRTH"}
              onChange={handleChange}
              label={"Date of Birth"}
              type={"date"}
              className={"flex-1"}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <InputInline
              value={data?.ADDRESS || ""}
              error={errors?.ADDRESS}
              name={"ADDRESS"}
              onChange={handleChange}
              label={"Address"}
              placeholder={"Enter Address"}
              className={"flex-1"}
              area={true}
            />
            <CustomDropdown
              label={"Gender"}
              className={"flex-1"}
              value={data?.GENDER || ""}
              error={errors?.GENDER}
              name={"GENDER"}
              onChange={handleChange}
            >
              <option value={""}>Select Gender</option>
              <option value={"MALE"}>MALE</option>
              <option value={"FEMALE"}>FEMALE</option>
            </CustomDropdown>
          </div>

          <div className="justify-center items-center flex mt-3">
            <CustomButton
              title={edit ? "Update" : "Submit"}
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default TeamAdmin;
