import React, { useEffect, useRef } from "react";
import { CustomModal } from "../../components/modals";
import CustomInput from "../../components/CustomInput";
import { CiEdit, CiTrash } from "react-icons/ci";
import { Button, Image, Select } from "antd";
import { FaPlus } from "react-icons/fa";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CustomTable from "../../components/table/CustomTablee";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import useScreenSize from "../../utils/useScreenSize";
import { useLoading } from "../../context/LoadingContext";

const CustomerView = ({ detail, setDetail, isModalOpen, setIsModalOpen }) => {
  const pdfRef = useRef();
  const { setLoading } = useLoading();
  console.log(detail?.FILES_DETAILS, "akdj");
  const screen = useScreenSize();

  let filePath = "";
  let fileUrl = `https://api.psklavinlab.com/`;

  useEffect(() => {
    const handlePrintShortcut = (event) => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        printDiv("printableDiv");
      }
    };

    document.addEventListener("keydown", handlePrintShortcut);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handlePrintShortcut);
    };
  }, []);

  // Utility function to get the file extension
  const getFileExtension = (url) => {
    console.log(url);
    return url.split(".").pop().toLowerCase();
  };

  // Determine if the file is a PDF
  const isPDF = getFileExtension(fileUrl) === "pdf";

  const generatePDF = async () => {
    try {
      setLoading(true);
      const input = pdfRef.current;

      // Capture the content as an image using html2canvas
      const canvas = await html2canvas(input, {
        scale: 2, // Increase scale to improve quality
      });
      const imgData = canvas.toDataURL("image/png");

      // Create a new PDF document using jsPDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the scaling factor to fit the content to the PDF size
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const scaleX = pdfWidth / imgWidth;
      const scaleY = pdfHeight / imgHeight;
      const scale = Math.min(scaleX, scaleY);

      // Calculate dimensions of the content
      const contentWidth = imgWidth * scale;
      const contentHeight = imgHeight * scale;

      // Add the captured image to the PDF document with reduced quality
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        contentWidth,
        contentHeight,
        "",
        "FAST"
      );

      // Save the PDF document as a blob
      const pdfBlob = pdf.output("blob");

      // Optionally, save the PDF locally or open in a new tab for download
      pdf.save("customer_detail.pdf");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  function printDiv(divId) {
    const printContents = document.getElementById(divId).innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    document.body.style.padding = "20px 40px";

    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  return (
    <CustomModal
      title={"Customer Detail"}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      A4={screen.isDesktop}
    >
      <div>
        <div className="flex your-print-container-class  justify-end  px-6">
          <button
            className=" px-4  py-2 right-0 bg-blue-500 text-white rounded self-center"
            onClick={generatePDF}
          >
            Print
          </button>
        </div>
        <div
          className="p-6 section-to-print pt-0 "
          id="printableDiv"
          ref={pdfRef}
        >
          <div>
            <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              1.Customer Information
            </p>
          </div>
          <div className="flex flex-wrap pt-3 w-full gap-4">
            <CustomInput
              preview
              inline
              onlyView={true}
              label={"Customer ID"}
              value={detail?.CUSTOMER_ID}
            />
            <CustomInput
              preview
              onChange={(e) =>
                setDetail({ ...detail, CUSTOMER_NAME: e.target.value })
              }
              inline
              onlyView
              label={"Company Name"}
              value={
                detail?.CUSTOMER_NAME !== "undefined"
                  ? detail?.CUSTOMER_NAME
                  : "DEMO"
              }
            />
            <CustomInput
              preview
              onChange={(e) =>
                setDetail({ ...detail, FULL_ADDRESS: e.target.value })
              }
              inline
              onlyView
              label={"Full Address"}
              value={
                detail?.FULL_ADDRESS !== "undefined"
                  ? detail?.FULL_ADDRESS
                  : "DEMO"
              }
            />
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, STATE: e.target.value })
                }
                onlyView
                label={"State"}
                value={detail?.STATE !== "undefined" ? detail?.STATE : "DEMO"}
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, POST_CODE: e.target.value })
                }
                onlyView
                label={"Postcode"}
                value={
                  detail?.POST_CODE !== "undefined" ? detail?.POST_CODE : "DEMO"
                }
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) => setDetail({ ...detail, CITY: e.target.value })}
                onlyView
                label={"City / Country"}
                value={detail?.CITY !== "undefined" ? detail?.CITY : "DEMO"}
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, REFERENCE: e.target.value })
                }
                onlyView
                label={"Reference"}
                value={detail?.REFERENCE}
              />
            </div>
          </div>

          <div className="flex gap-4 mt-4 items-center">
            <p className="text-[#0363B0]  text-[20px] font-semibold">
              2.Contact Detail
            </p>
            {/* <FaPlus className="text-[24px] cursor-pointer text-[#0363B0]" /> */}
          </div>
          <div className="flex flex-wrap pt-3 w-full gap-4">
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, CONTACT_PERSON: e.target.value })
                }
                onlyView
                label={"Contact person Name"}
                value={
                  detail?.CONTACT_INFO?.length > 0
                    ? detail?.CONTACT_INFO[0]?.CONTACT_PERSON
                    : detail?.CONTACT_PERSON
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, EMAIL: e.target.value })
                }
                onlyView
                label={"E-Mail"}
                value={
                  detail?.CONTACT_INFO?.length > 0
                    ? detail?.CONTACT_INFO[0]?.CONTACT_EMAIL
                    : detail?.EMAIL
                }
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, DEPARTMENT: e.target.value })
                }
                onlyView
                label={"Department"}
                value={
                  detail?.CONTACT_INFO?.length > 0
                    ? detail?.CONTACT_INFO[0]?.DEPARTMENT
                    : detail?.DEPARTMENT
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, DESIGNATION: e.target.value })
                }
                onlyView
                label={"Designation"}
                value={detail?.DESIGNATION}
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, SECONDARY_EMAIL: e.target.value })
                }
                onlyView
                label={"E-Mail"}
                value={
                  detail?.CONTACT_INFO?.length > 0
                    ? detail?.CONTACT_INFO[0]?.CONTACT_EMAIL
                    : detail?.EMAIL
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, SECONDARY_REFERENCE: e.target.value })
                }
                onlyView
                label={"Reference"}
                value={detail?.SECONDARY_REFERENCE}
              />
            </div>
          </div>

          <div>
            <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              3.Purchase Order Details
            </p>
          </div>
          <div className="flex flex-wrap pt-3 w-full gap-4">
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_NUMBER: e.target.value })
                }
                onlyView
                label={"PO Number"}
                value={
                  detail?.PO_INFO?.length > 0
                    ? detail?.PO_INFO[0]?.PO_NUMBER
                    : detail?.PO_NUMBER
                }
              />
              <CustomInput
                onChange={(e) =>
                  setDetail({ ...detail, PO_EXPIRY_DATE: e.target.value })
                }
                onlyView
                disabled={true}
                nonee
                label={"PO Expiry Date"}
                value={
                  detail?.PO_INFO?.length > 0
                    ? detail?.PO_INFO[0]?.PO_NUMBER
                    : ""
                }
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_DATE: e.target.value })
                }
                onlyView
                label={"PO Date"}
                value={
                  detail?.PO_INFO?.length > 0 ? detail?.PO_INFO[0]?.PO_DATE : ""
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_POSTCODE: e.target.value })
                }
                onlyView
                label={"Postcode"}
                value={detail?.PO_INFO?.length > 0 ? detail?.POST_CODE : ""}
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, CONTACT_NO: e.target.value })
                }
                onlyView
                label={"Contact No"}
                value={
                  detail?.CONTACT_INFO?.length > 0
                    ? detail?.CONTACT_INFO[0]?.CONTACT_NUMBER
                    : ""
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_CITY_COUNTRY: e.target.value })
                }
                onlyView
                label={"City / Country"}
                value={detail?.CITY}
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_EXPIRY_DATE: e.target.value })
                }
                onlyView
                label={"PO expiry date"}
                value={
                  detail?.PO_INFO?.length > 0
                    ? detail?.PO_INFO[0]?.PO_EXPIRY_DATE
                    : ""
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_REFERENCE: e.target.value })
                }
                onlyView
                label={"Reference"}
                value={
                  detail?.P0_INFO?.length > 0
                    ? detail?.P0_INFO[0]?.PO_NUMBER
                    : ""
                }
              />
            </div>
          </div>
          <div>
            <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              4. ORDER SUMMARY
            </p>
            <CustomTable
              order_summary={
                detail?.PO_INFO?.length > 0
                  ? detail?.PO_INFO[0]?.ORDER_SUMMARY
                  : []
              }
              edit={false}
              // setOrder_summary
              options
              detail2
              setd
            />
          </div>
          <div className="!min-w-full">
            <p className="text-[#0363B0] my-4 text-[20px] font-semibold">
              5. FILE PREVIEW
            </p>
            {getFileExtension(
              detail?.FILES_DETAILS?.length
                ? detail?.FILES_DETAILS[0]?.path
                : ""
            ) === "pdf" ? (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={
                    detail?.FILES_DETAILS?.length
                      ? fileUrl + detail?.FILES_DETAILS[0]?.path
                      : ""
                  }
                />
              </Worker>
            ) : (
              <Image
                src={
                  detail?.FILES_DETAILS?.length
                    ? fileUrl + detail?.FILES_DETAILS[0]?.path
                    : ""
                }
                className="min-h-[350px] max-h-[350px]"
                alt="File Preview"
              />
            )}
          </div>
          <div className="flex mt-4 flex-wrap items-center pt-3 w-full gap-4">
            <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              6. Order Status
            </div>
            <p className="text-[20px] ml-auto font-semibold mt-4 bg-[#e1e5e7] px-3 py-[0.5]">
              Pending
            </p>
          </div>
          <div className="flex flex-col flex-wrap pt-3 w-full gap-4">
            <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              7. Audit Trial
            </div>
            <div className="ml-6 mt-auto flex items-center justify-between">
              <p className="font-semibold">
                <span className="text-gray-500 font-normal">Created By:</span>{" "}
                Pabba sk guptha
              </p>
              <p className="font-semibold">
                <span className="text-gray-500 font-normal">Created Date:</span>{" "}
                29/07/2024
              </p>
              <p className="font-semibold">
                <span className="text-gray-500 font-normal">
                  Last Modified By:
                </span>{" "}
                Admin
              </p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default CustomerView;
