
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiDashboardFill, RiFileList2Line, RiStockLine } from "react-icons/ri";
import { FaRegCalendarCheck, FaChevronDown, FaUsers } from "react-icons/fa";
import { ImLab } from "react-icons/im";
import { FiUsers } from "react-icons/fi";
import { ReactComponent as Logo } from "../../assets/svg/Logo1.svg";
import { ReactComponent as Logo1 } from "../../assets/svg/LOGO2.svg";
import { Popover, Tooltip } from "antd";
import myContext from "../../context/myContext";
import { AiOutlineProduct, AiOutlineTeam } from "react-icons/ai";
import { IoIosContacts } from "react-icons/io";

const items = [
  {
    key: "1",
    icon: <RiDashboardFill size={20} />,
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    key: "sub1",
    icon: <AiOutlineTeam size={20} />,
    label: "Team Structure",
    children: [
      {
        key: "2.1",
        label: "Admin",
        path: "/teams/admin",
      },
      {
        key: "2.2",
        label: "Manager",
        path: "/teams/manager",
      },
      {
        key: "2.3",
        label: "Executive",
        path: "/teams/executive",
      },
    ],
  },
  {
    key: "sub2",
    label: "Lead Generation",
    icon: <RiStockLine size={20} />,
    children: [
      // {
      //   key: "4.1",
      //   label: "Leads",
      //   path: "/lead-generation/leads",
      // },
      // {
      //   key: "4.2",
      //   label: "Lead RecycleBin",
      //   path: "/lead-generation/recycle-bin",
      // },
      {
        key: "4.3",
        label: "List Of Leads",
        path: "/lead-generation/list-of-leads",
      },
      {
        key: "4.4",
        label: "Add Lead",
        path: "/lead-generation/add-lead",
      },
      {
        key: "4.5",
        label: "Converted Leads",
        path: "/lead-generation/converted-leads",
      },
    ],
  },
  {
    key: "5",
    icon: <RiFileList2Line size={20} />,
    label: "Quick Quotation",
    children: [
      {
        key: "5.1",
        label: "Generate Quotation",
        path: "/quick-quotation/generate-quotation",
      },
      {
        key: "5.2",
        label: "Quotation List",
        path: "/quick-quotation/list",
      },
    ],
  },
  {
    key: "6",
    icon: <AiOutlineProduct size={20} />,
    label: "Services",
    path: "/services",
  },
  {
    key: "8",
    icon: <ImLab size={20} />,
    label: "LAB",
    path: "/lab",
  },
  {
    key: "sub5",
    icon: <FiUsers size={20} />,
    label: "Customer",
    children: [
      {
        key: "9.1",
        label: "Customer List",
        path: "/customer/info",
      },

      {
        key: "9.2",
        label: "Customer RecycleBin",
        path: "/customer/recycle-bin",
      },
    ],
  },
  {
    key: "10",
    icon: <FaUsers size={20} />,
    label: "HR Portal",
    children: [
      {
        key: "10.1",
        label: "Employee List",
        path: "/hr-portal/home",
      },

      {
        key: "10.2",
        label: "Job Application Form",
        path: "/hr-portal/send-application-form",
      },
    ],
  },
];

const MainSidebar = () => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { collapsed } = useContext(myContext);
  const [openKeys, setOpenKeys] = useState([]);
  const navigate=useNavigate();

  const pathname = location.pathname;
  let arr = pathname.split("/");
  let newArr = arr.slice(0, arr.length - 1);
  let joinedString = newArr.join("/");

  useEffect(() => {
    const currentKey = findKeyForPath(pathname);
    setSelectedKeys(currentKey ? [currentKey] : []);
    setOpenKeys(currentKey ? [currentKey.split(".")[0]] : []);
  }, [pathname]);

  const findKeyForPath = (pathname) => {
    for (let item of items) {
      if (item.path === pathname) {
        return item.key.toString();
      }
      if (item.children) {
        for (let child of item.children) {
          if (child.path === pathname || child.path === joinedString) {
            return item.key.toString(); // Return parent key for submenu item
          }
        }
      }
    }
    return null;
  };

  const handleSubMenuClick = (key) => {
    setOpenKeys((prevOpenKeys) => (prevOpenKeys.includes(key) ? [] : [key]));
  };

  return (
    <div className="flex flex-col h-screen w-full bg-white border-r">
      {collapsed ? (
        <Logo1 onClick={()=>navigate('/')} className="object-contain cursor-pointer h-[70px] w-full p-2" />
      ) : (
        <Logo onClick={()=>navigate('/')} className="object-contain cursor-pointer min-w-fit h-[70px] w-full p-2" />
      )}
      <div className="overflow-y-auto pb-6">
        <ul className="mt-0 px-4">
          {items.map((item) =>
            item.children ? (
              <li key={item.key} className="mt-2">
                {collapsed ? (
                  <Popover
                    placement="rightTop"
                    title={""}
                    content={
                      <ul className={""}>
                        {item.children.map((child) => (
                          <li key={child.key} className="mt-1 group">
                            <Link
                              to={child.path}
                              className={`block px-4 py-2 group-hover:bg-[#0363B0] rounded-lg ${
                                child.path === pathname ||
                                child.path === joinedString
                                  ? "bg-[#0363B0] text-white"
                                  : "bg-gray-200 text-gray-900"
                              }`}
                            >
                              <span className="group-hover:text-white">
                                {child.label}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    }
                  >
                    <li key={item.key} className="mt-2">
                      <div
                        className={`flex items-center px-4 py-3 cursor-pointer group hover:bg-[#0363B0] hover:text-white rounded-lg ${
                          openKeys.includes(item.key)
                            ? "bg-[#0363B044] text-gray-900"
                            : "bg-gray-200 text-gray-900"
                        }`}
                        onClick={() => handleSubMenuClick(item.key)}
                      >
                        <span className="mr-3">{item.icon}</span>
                        <FaChevronDown
                          className={`w-4 h-4 ml-auto transform ${
                            openKeys.includes(item.key) ? "rotate-180" : ""
                          }`}
                        />
                      </div>
                    </li>
                  </Popover>
                ) : (
                  <>
                    <div
                      className={`flex items-center px-4 py-3 cursor-pointer group hover:bg-[#0363B0] hover:text-white rounded-lg ${
                        openKeys.includes(item.key)
                          ? "bg-[#0363B044] text-gray-900"
                          : "bg-gray-200 text-gray-900"
                      }`}
                      onClick={() => handleSubMenuClick(item.key)}
                    >
                      <span className="mr-3">{item.icon}</span>
                      {collapsed || (
                        <span className="flex-1">{item.label}</span>
                      )}
                      <FaChevronDown
                        className={`w-4 h-4 ml-auto transform ${
                          openKeys.includes(item.key) ? "rotate-180" : ""
                        }`}
                      />
                    </div>

                    <ul
                      className={`${
                        openKeys.includes(item.key) ? "" : "hidden"
                      }`}
                    >
                      {item.children.map((child) => (
                        <li key={child.key} className="mt-1 group">
                          <Link
                            to={child.path}
                            className={`block ml-10 px-4 py-2 group-hover:bg-[#0363B0] rounded-lg ${
                              child.path === pathname ||
                              child.path === joinedString
                                ? "bg-[#0363B0] text-white"
                                : "bg-gray-200 text-gray-900"
                            }`}
                          >
                            <span className="group-hover:text-white">
                              {child.label}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </li>
            ) : (
              <li key={item.key} className="mt-2 group">
                {collapsed ? (
                  <Tooltip placement="right" title={item.label} arrow>
                    <Link
                      id="shubh"
                      to={item.path}
                      className={`flex items-center px-4 py-3 cursor-pointer rounded-lg group-hover:bg-[#0363B0] ${
                        item.path === pathname
                          ? "bg-[#0363B0] text-white"
                          : "bg-gray-200 text-gray-900"
                      }`}
                    >
                      <span className="mr-3 group-hover:text-white">
                        {item.icon}
                      </span>
                    </Link>
                  </Tooltip>
                ) : (
                  <Link
                    id="shubh"
                    to={item.path}
                    className={`flex items-center px-4 py-3 cursor-pointer rounded-lg group-hover:bg-[#0363B0] ${
                      item.path === pathname ||
                      (item.label == "Dashboard" && pathname == "/")
                        ? "bg-[#0363B0] text-white"
                        : "bg-gray-200 text-gray-900"
                    }`}
                  >
                    <span className="mr-3 group-hover:text-white">
                      {item.icon}
                    </span>
                    {collapsed || (
                      <span className="group-hover:text-white">
                        {item.label}
                      </span>
                    )}
                  </Link>
                )}
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default MainSidebar;
