import React, { useRef } from "react";
import quotation from "../assets/images/quotation.jpg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import axios from "axios";
import { openNotification } from "../App";
import { store } from "../redux/store";
import { fetchLead_list } from "../redux/slice/dataSlice";
import { useLoading } from "../context/LoadingContext";

const QuotationFormat = ({
  mail = "",
  list = [],
  quotation_number = "",
  person_name = "",
  company_name = "",
  // quotationRef='',
  reference = "",
  address = "",
  quotation_detail = {},
  setClose,
  setAddClose,
  setDetail,
  leadOrNot,
  leadid,
  selectedContacts,
}) => {
  console.log(list);
  console.log(selectedContacts);
  const user = JSON.parse(localStorage.getItem("lavin"));
  const { setLoading } = useLoading();
  const quotationRef = useRef(null);
  const sendPDFToBackend = async (pdfBlob) => {
    let data = new FormData();
    console.log(leadid);
    data.append("FILE", pdfBlob, "quotation.pdf");
    data.append("COMPANY_NAME", quotation_detail?.COMPANY_NAME);
    data.append("FULL_ADDRESS", quotation_detail?.FULL_ADDRESS);
    data.append("CONTACT_PERSON", quotation_detail?.CONTACT_PERSON);
    data.append("STATE", quotation_detail?.STATE);
    data.append("POSTCODE", quotation_detail?.POSTCODE);
    data.append("CONTACT_NUMBER", quotation_detail?.CONTACT_NUMBER);
    data.append("CITY", quotation_detail?.CITY);
    data.append("EMAIL", quotation_detail?.EMAIL);
    data.append("REFERENCE", quotation_detail?.REFERENCE);
    data.append("QUOTAION_NUM", quotation_number);
    data.append("LEAD_ID", leadid);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: !leadOrNot
        ? "https://api.psklavinlab.com/api/quick-quotation/create"
        : "https://api.psklavinlab.com/api/leads/generate-quotation",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.TOKEN}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const res = response.data;
        setClose(false);
        setAddClose(false);
        if (res.success) {
          openNotification("success", "Success", res.message);
          store.dispatch(fetchLead_list());
        } else {
          openNotification("error", "Error", res.message);
        }
      })
      .catch((error) => {
        openNotification(
          "error",
          "Error",
          error.response?.data?.message ||
            "Something went wrong. Please try again."
        );

        console.log(error);
      });
  };
  const generatePDF = async () => {
    try {
      setLoading(true);
      const input = quotationRef.current;

      // Capture the content as an image using html2canvas
      const canvas = await html2canvas(input, {
        scale: 2, // Increase scale to improve quality
      });
      const imgData = canvas.toDataURL("image/png");

      // Create a new PDF document using jsPDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the scaling factor to fit the content to the PDF size
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const scaleX = pdfWidth / imgWidth;
      const scaleY = pdfHeight / imgHeight;
      const scale = Math.min(scaleX, scaleY);

      // Calculate dimensions of the content
      const contentWidth = imgWidth * scale;
      const contentHeight = imgHeight * scale;

      // Add the captured image to the PDF document with reduced quality
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        contentWidth,
        contentHeight,
        "",
        "FAST"
      );

      // Save the PDF document as a blob
      const pdfBlob = pdf.output("blob");

      // Send the PDF blob to the backend
      await sendPDFToBackend(pdfBlob);

      // Optionally, save the PDF locally or open in a new tab for download
      // pdf.save("quotation.pdf");
      // setDetail({});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  console.log(list, "abhishek");
  return (
    <div className="font-calibri text-sm">
      
      <div className="m-4 border ">
        <div
          className="p-8 max-w-4xl mx-auto bg-white text-[12px] my-4 "
          ref={quotationRef}
        >
          <img
            src={quotation}
            className="w-full object-contain mt-4 mb-6"
            alt="Quotation"
          />
          <h1 className="font-bold mb-1 text-center">Quotation</h1>

          <div className="border-collapse border border-gray-400 flex mb-3 bg-slate-200">
            <div className="flex-[0.8] p-2 border-r border-r-gray-400">
              <p className="font-bold">Company Address</p>
              <p className="font-bold">M/s.{company_name}</p>
              <p className="font-normal">
                {quotation_detail?.FULL_ADDRESS},
                <br />
                {quotation_detail?.CITY},
                <br />
                {quotation_detail?.STATE}-{quotation_detail?.POSTCODE}
              </p>
            </div>
            <div className="flex-1">
              <div className="flex border-b border-b-gray-400">
                <p className="p-1 flex-[0.8] border-r border-r-gray-400">
                  Date:
                </p>
                <p className="flex-1 p-1">{moment().format("DD.MM.YYYY")}</p>
              </div>
              <div className="flex border-b border-b-gray-400">
                <p className="p-1 flex-[0.8] border-r border-r-gray-400">
                  Quotation:
                </p>
                <p className="flex-1  p-1 ">{quotation_number}</p>
              </div>
              <div className="flex border-b border-b-gray-400">
                <p className="p-1 flex-[0.8] border-r border-r-gray-400">
                  Contact Person:
                </p>
                <p className="flex-1  p-1 ">{person_name}</p>
              </div>
              <div className="flex border-b border-b-gray-400">
                <p className="p-1 flex-[0.8] border-r border-r-gray-400">
                  Email:
                </p>
                <p className="flex-1  p-1">{mail}</p>
              </div>
              <div className="flex">
                <p className="p-1 flex-[0.8] border-r border-r-gray-400">
                  Reference:
                </p>
                <p className="flex-1  p-1 ">{reference}</p>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <p>Dear Sir/Madam,</p>
            <p>
              We are thankful for your enquiry. We are pleased to quote the
              following:
            </p>
          </div>

          <table className="min-w-full bg-white mb-6 border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="py-2 px-4 border border-gray-300">S.no</th>
                <th className="py-2 px-4 border border-gray-300">
                  Description
                </th>
                <th className="py-2 px-4 border border-gray-300">Qty</th>
                <th className="py-2 px-4 border border-gray-300">Amount</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border border-gray-300">
                    {index + 1}.
                  </td>
                  <td className="py-2 px-4 border border-gray-300 text-sm font-normal">
                    <span className="font-bold text-sm">
                      {item.service?.label
                        ? item.service?.label
                        : item?.SERVICE_NAME}{" "}
                      :{" "}
                    </span>
                    {item.service?.desc
                      ? item.service?.desc
                      : item?.DESCRIPTION}
                  </td>
                  <td className="py-2 px-4 border border-gray-300">
                    {item.qty ? item.qty : item?.QUANTITY ? item?.QUANTITY : 1}
                  </td>
                  <td className="py-2 px-4 border border-gray-300">
                    {item.price ? item.price : item?.PRICE}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mb-6">
            <p>• GST (18%) Extra applicable as per Govt. Norms</p>
            <p>• Payment: 15 days against the reports.</p>
            <p>
              • TAT: Reports will be submitted within 6 to 7 working days after
              completion of analysis.
            </p>
          </div>

          <div className="mb-6">
            <p>
              If you have any questions or need further information, please feel
              free to contact us. We are here to assist you.
            </p>
            <p>We look forward to your valuable order.</p>
          </div>

          <div>
            <p>With Best Regards,</p>
            <p>For Lavin Laboratories</p>
            <p>Contact: 9849265173</p>
          </div>
        </div>
      </div>
      <button
        className="float-right mt-2 px-4 mr-4 py-2 bg-blue-500 text-white rounded self-center"
        onClick={generatePDF}
      >
        Send
      </button>
    </div>
  );
};

export default QuotationFormat;
