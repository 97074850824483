import React, { useEffect, useState } from "react";
import CustomerInfo from "./CustomerInfo";
import POInfo from "./POInfo";
import Attechment from "./Attechment";
import HeaderText from "../../components/HeaderText";
import CustomButton from "../../components/CustomButton";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchCustomer_list } from "../../redux/slice/dataSlice";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { CustomModal, Delete_Modal } from "../../components/modals";
import CustomTable from "../../components/CustomTable";
import TableHeader from "../../components/TableHeader";
import Loader from "../../components/Loader";
import { CiEdit, CiTrash } from "react-icons/ci";
import moment from "moment";
import CustomerView from "./CustomerView";
import { Button, Popover } from "antd";
import CustomStepper from "../../components/CustomStepper";
import { Link } from "react-router-dom";
import { BiUser } from "react-icons/bi";
import CustomerAdd from "./CustomerAdd";

const Customer = () => {
  const [active_tab, setActive_tab] = useState(0);
  const { customer_list, customer_loading, customer_total_count } = useSelector(
    (state) => state.allData
  );
  const [selected, setSelected] = useState([]);
  const [add_modal, setAdd_modal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [view_modal, setView_modal] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    if (!view_modal) setDetail({});
  }, [view_modal]);

  useEffect(() => {
    store.dispatch(fetchCustomer_list());
  }, []);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleDelete = async (type) => {
    setLoading(true);
    const body = JSON.stringify({
      ID: type === "single" ? [detail.ID] : selected,
    });
    const res = await postApiToken(`customer-po/delete-customer`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchCustomer_list());
      setDelete_modal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const filteredData = customer_list?.filter((item) => {
    return (
      item.CUSTOMER_NAME?.toLowerCase().includes(search.toLowerCase()) ||
      item.CUSTOMER_ID.toLowerCase().includes(search.toLowerCase())
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>Customers List</HeaderText>
        <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Customer"}
          onClick={() => {
            setActive_tab(0);
            setEdit(false);
            setDetail({});
            setAdd_modal(true);
          }}
        />
      </div>
      {customer_loading && customer_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 max-w-[1100px] mt-4">
          <div className="max-w-[1100px]">
            <TableHeader
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              tableHeaders={[
                "Customer ID",
                "COMPANY NAME",
                "Created BY",
                "Created Date",
              ]}
              tableData={filteredData.map((e) => [
                e.CUSTOMER_ID,
                e.CUSTOMER_NAME,
                e.CREATED_BY_NAME,
                moment(e.CREATED_AT).format("DD-MM-YYYY"),
              ])}
              onDeleteAll={() => handleDelete("all")}
              tableName={"Abhishek"}
              fileName={"pddd"}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={(page) => setCurrentPage(page)}
              onLimitChange={(limit) => setItemsPerPage(limit)}
              indexOfFirstItem={indexOfFirstItem}
              indexOfLastItem={indexOfLastItem}
            />
          </div>
          <div className="max-w-[1100px]">
            <CustomTable
              list={customer_list}
              selected={selected}
              setSelected={setSelected}
              headerData={["COMPANY NAME", "Customer ID", "Actions"]}
              bodyData={currentItems?.map((item, index) => (
                <tr key={item.ID}>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selected.includes(item.ID)}
                      onChange={() => handleSelect(item.ID)}
                    />
                  </td>

                  <td
                    className="py-2 px-4 cursor-pointer border-b"
                    onClick={() => {
                      console.log(item);
                      setDetail(item);
                      setActive_tab(item?.STEP);
                      setView_modal(true);
                    }}
                  >
                    {item.CUSTOMER_NAME !== "undefined"
                      ? item.CUSTOMER_NAME
                      : "DEMO"}
                  </td>
                  <td
                    onClick={() => {
                      console.log(item);
                      setDetail(item);
                      setActive_tab(item?.STEP);
                      setView_modal(true);
                    }}
                    className="py-2 px-4 cursor-pointer border-b text-[#0363B0]"
                  >
                    {item.CUSTOMER_ID}
                  </td>
                  {/* <td className="py-2 px-4 border-b">{item.CREATED_BY_NAME}</td>
                  <td className="py-2 px-4 border-b">
                    {moment(item.CREATED_AT).format("DD-MM-YYYY")}
                  </td> */}

                  <td className="py-2 px-4 border-b ">
                    <div className="flex gap-4">
                      <CiEdit
                        size={20}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => {
                          console.log(item)
                          setEdit(true);
                          setDetail(item);
                          setAdd_modal(true);
                        }}
                      />
                      <CiTrash
                        size={20}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => {
                          setDetail(item);
                          setDelete_modal(true);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            />
          </div>
        </div>
      )}
      <Delete_Modal
        handleDelete={() => handleDelete("single")}
        isModalOpen={delete_modal}
        setIsModalOpen={setDelete_modal}
        loading={loading}
      ></Delete_Modal>
      <CustomerView
        detail={detail}
        setDetail={setDetail}
        setIsModalOpen={setView_modal}
        isModalOpen={view_modal}
        type="preview"
      />
      <CustomerAdd
        detail={detail}
        edit={edit}
        setDetail={setDetail}
        isModalOpen={add_modal}
        setIsModalOpen={setAdd_modal}
      />
    </>
  );
};

export default Customer;
