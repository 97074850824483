import React from "react";
import { TextField, Button } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  padding: "20px",
  gap: "20px",
  justifyContent: "center",
  alignItems: "flex-start",
  flexWrap:'wrap'
});

const ProfileContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  maxWidth: "fit-content",
  width: "100%",
});

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#fff",
  flex: 1,
});

const ProfileImage = styled("img")({
  width: "130px",
  height: "130px",
  borderRadius: "50%",
});

const ProfileInput = styled(TextField)({
  color: "black !important",
  marginBottom: "16px",
  "& .Mui-disabled": {
    color: "#000", // Text color when disabled
    WebkitTextFillColor: "#000", // For Safari to apply the color
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#000", // Label color when disabled
  },
});

const SaveButton = styled(Button)({
  alignSelf: "flex-end",
  marginTop: "20px",
});

const UserProfile = ({ item }) => {
  if (!item?.userInfo || item.userInfo.length === 0) {
    return null; // or render a fallback UI
  }

  const userInfo = item.userInfo[0];

  return (
    <Container className="!flex-col md:!flex-row">
      <ProfileContainer>
        <ProfileImage
          src={
            userInfo.IMAGE_URL?.length > 0
              ? userInfo.IMAGE_URL
              : "https://img.freepik.com/free-photo/female-web-designer-office-with-notebook_23-2149749868.jpg"
          }
          alt="Profile"
        />
      </ProfileContainer>

      <FormContainer>
        <div style={{ display: "flex", gap: "20px"}} className="md:flex-row flex-col">
          <div style={{ flex: 1 }}>
            <ProfileInput
              size="small"
              disabled
              label="Name"
              value={`${userInfo.FIRST_NAME} ${userInfo.LAST_NAME}`}
              fullWidth
            />
            <ProfileInput
              size="small"
              disabled
              label="Email address"
              value={userInfo.EMAIL_ADDRESS}
              fullWidth
            />
            <ProfileInput
              size="small"
              disabled
              label="Phone Number"
              value={userInfo.PHONE_NUMBER}
              fullWidth
            />
            <ProfileInput
              size="small"
              disabled
              label="Address"
              value={userInfo.ADDRESS}
              fullWidth
            />
          </div>
          <div style={{ flex: 1 }}>
            <ProfileInput
              size="small"
              disabled
              label="Country"
              value={userInfo.COUNTRY}
              fullWidth
            />
            <ProfileInput
              size="small"
              disabled
              label="State/region"
              value={userInfo.STATE}
              fullWidth
            />
            <ProfileInput
              size="small"
              disabled
              label="City"
              value={userInfo.CITY}
              fullWidth
            />
            <ProfileInput
              size="small"
              disabled
              label="Zip/code"
              value={userInfo.POSTCODE}
              fullWidth
            />
          </div>
        </div>
        <ProfileInput
          size="small"
          disabled
          label="About"
          value="Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus."
          multiline
          rows={4}
          fullWidth
        />
      </FormContainer>
    </Container>
  );
};

export default UserProfile;
