import React, { useEffect, useRef, useState } from "react";
import HeaderText from "../../components/HeaderText";
import CustomButton from "../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import axios from "axios";
import { IoArrowBack, IoMailOutline } from "react-icons/io5";
import { FaAddressCard, FaFileLines } from "react-icons/fa6";
import { FaRegAddressCard } from "react-icons/fa";
import { Image, Modal, Tooltip } from "antd";
import { CiEdit, CiTrash } from "react-icons/ci";
import { postApiToken, putApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import * as pdfjs from "pdfjs-dist";
import html2canvas from "html2canvas";
import UpadteContactInfo from "./UpadteContactInfo";
import UpdateLead from "./UpdateLead";
import { store } from "../../redux/store";
import { fetchLead_list } from "../../redux/slice/dataSlice";
import IconButton from "../../components/IconButton";
import { toast } from "react-toastify";
import QuotationModal from "../../components/QuotationModal";
import CustomInput, { InputInline } from "../../components/CustomInput";
import { useLoading } from "../../context/LoadingContext";
import jsPDF from "jspdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FollowUp = () => {
  const pdfRef = useRef();
  const user = JSON.parse(localStorage.getItem("lavin"));
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const location = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading1] = useState(false);
  const [loading1, setLoading11] = useState(false);
  const [errors, setErrors] = useState({});
  const [detail, setDetail] = useState({});
  const [lead_detail, setLead_detail] = useState({});
  const [quotationUrl, setQuotationUrl] = useState("");
  const [showpdf, setShowpdf] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [follow, setFollow] = useState(false);
  const [del, setdel] = useState(0);
  const [IDs, setIDs] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [quotaionModal, setQuotaionModal] = useState(false);
  const [selectedQuotaionUrl, setSelectedQuotationUrl] = useState("");

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const generatePDF = async () => {
    try {
      setLoading(true);
      const input = pdfRef.current;

      // Capture the content as an image using html2canvas
      const canvas = await html2canvas(input, {
        scale: 2, // Increase scale to improve quality
      });
      const imgData = canvas.toDataURL("image/png");

      // Create a new PDF document using jsPDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the scaling factor to fit the content to the PDF size
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const scaleX = pdfWidth / imgWidth;
      const scaleY = pdfHeight / imgHeight;
      const scale = Math.min(scaleX, scaleY);

      // Calculate dimensions of the content
      const contentWidth = imgWidth * scale;
      const contentHeight = imgHeight * scale;

      // Add the captured image to the PDF document with reduced quality
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        contentWidth,
        contentHeight,
        "",
        "FAST"
      );

      // Save the PDF document as a blob
      const pdfBlob = pdf.output("blob");

      // Optionally, save the PDF locally or open in a new tab for download
      pdf.save("customer_detail.pdf");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  const handleShareQuotaion = async () => {
    if (selectedItems?.length == 0 || selectedQuotaionUrl === "") {
      openNotification(
        "error",
        "Error",
        "please select atlease one contact person and one quotation"
      );
      return;
    }

    console.log(location.state);
    const body = {
      LEAD_ID: location?.state?.ID,
      IDS: selectedItems,
      QUOTAION_URL: "https://api.psklavinlab.com/" + selectedQuotaionUrl,
    };
    try {
      setLoading(true);
      const res = await postApiToken(
        "leads/send-quotation-mail",
        JSON.stringify(body)
      );
      if (res.success) {
        openNotification("success", "Success", res.message);
      } else openNotification("error", "error", res.message);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotification("success", "Success", error.message);
    }
  };

  useEffect(() => {
    // if(!quotaionModal)setLead_detail({})
  }, [quotaionModal]);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
    setErrors((prev) => {
      return { ...prev, [name]: "" };
    });
  };
  const handleSubmit = async () => {
    // if (
    //   !data.p_email ||
    //   !data.p_name ||
    //   !data.p_number ||
    //   !data.department ||
    //   !data.designation ||
    //   !data.comment ||
    //   !data.notes
    // ) {
    //   setErrors({
    //     ...errors,
    //     p_email: !data.p_email ? "Please enter email address" : "",
    //     p_name: !data.p_name ? "Please enter person name" : "",
    //     p_number: !data.p_number ? "Please enter person contact number" : "",
    //     department: !data.department ? "Please enter department" : "",
    //     designation: !data.designation ? "Please enter designation" : "",
    //     comment: !data.comment ? "Please enter comment " : "",
    //     notes: !data.notes ? "Please enter notes" : "",
    //   });
    //   return;
    // }

    try {
      setLoading1(true);
      let rawdata = JSON.stringify({
        USER_ID: user?.USER_ID,
        ID: location.state?.ID,
        CONTACT_INFO: {
          CONTACT_PERSON: !data.p_name ? "demo1" : data.p_name,
          MOBILE_NUMBER: !data.p_number ? "demo1" : data.p_number,
          DESIGNATION: !data.designation ? "demo1" : data.designation,
          EMAIL_ID: !data.p_email ? "demo1@gami.com" : data.p_email,
          DEPARTMENT: !data.department ? "demo1" : data.department,
          COMMENT: !data.comment ? "demo1" : data.comment,
          NOTES: !data.notes ? "demo1" : data.notes,
        },
      });
      console.log(JSON.parse(rawdata));
      let config = {
        method: "post",
        // maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_KEY + "leads/add-new-contact-info",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${user?.TOKEN}`,
        },
        data: rawdata,
      };
      const response = await axios.request(config);
      const res = await response.data;
      console.log(res);
      setLoading1(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        setFollow(false);
        get_data();
        store.dispatch(fetchLead_list());
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading1(false);
    }
  };
  const handleDelete = async () => {
    setLoading1(true);
    const body = JSON.stringify({
      ID: [detail.ID],
    });
    const res = await postApiToken(`leads/delete-lead?ID=${detail.ID}`, body);
    setLoading1(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      setIsModalOpen(false);
      // get_data();
      navigate(-1);
      store.dispatch(fetchLead_list());
    } else {
      openNotification("error", "Error", res.message);
    }
  };
  const handleContactDelete = async () => {
    try {
      setLoading1(true);
      let rawdata = JSON.stringify({
        USER_ID: user?.USER_ID,
        ID: location.state.ID,
        CONTACT_INFO: {
          CONTACT_PERSON: detail.CONTACT_PERSON,
          MOBILE_NUMBER: detail.MOBILE_NUMBER,
          DESIGNATION: detail.DESIGNATION,
          EMAIL_ID: detail.EMAIL_ID,
          DEPARTMENT: detail.DEPARTMENT,
          COMMENT: detail.COMMENT,
          NOTES: detail.NOTES,
          ID: detail.ID,
        },
      });

      const res = await putApiToken("leads/delete-contact-info", rawdata);

      setLoading1(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        setIsModalOpen(false);
        store.dispatch(fetchLead_list());
        get_data();
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading1(false);
      openNotification("error", "Error", "res.message");
    }
  };

  const getFileExtension = (url) => {
    console.log(url);
    return url.split(".").pop().toLowerCase();
  };

  const sendMail = async () => {
    setLoading1(true);
    if (selectedItems?.length === 0) {
      openNotification(
        "error",
        "Error",
        "Please Select atleast one Contact detail"
      );
      setLoading1(false);
      return;
    }
    try {
      console.log(selectedItems, "add");
      setLoading1(true);
      let rawdata = JSON.stringify({
        LEAD_ID: lead_detail?.ID,
        IDS: selectedItems,
      });

      const res = await postApiToken("leads/send-introducing-mail", rawdata);
      if (res.success) {
        setAllChecked(false);
        setLoading1(false);
        setSelectedItems([]);
        openNotification("success", "Success", res.message);
        setIsModalOpen(false);
        // store.dispatch(fetchLead_list());
        get_data();
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading1(false);
      openNotification("error", "Error", "res.message");
    }
  };

  const get_data = async () => {
    try {
      setLoading11(true);
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url:
          process.env.REACT_APP_API_KEY + `leads/info?ID=${location.state.ID}`,
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      };

      const response = await axios.request(config);
      const data = await response.data;
      setLoading11(false);
      if (data.success) {
        console.log(data.data[0], "adkjksd");
        setLead_detail(data.data[0]);
      } else {
        setLead_detail({});
      }
    } catch (error) {
      setLoading11(false);
    }
  };
  useEffect(() => {
    get_data();
  }, []);

  const handleIndividualCheckedChange = (id) => {
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
    setAllChecked(newSelectedItems.length === lead_detail.CONTACT_INFO.length);
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between">
        <div className="mr-auto">
          <HeaderText>Follow Up</HeaderText>
        </div>
        <div className="hidden md:flex flex-wrap gap-2 items-center">
          <CustomButton
            icon={<FaRegAddressCard size={20} />}
            title={"Share Business Card"}

            //   onClick={() => navigate(-1)}
          />
          <CustomButton
            icon={<IoMailOutline size={20} />}
            title={"Share Introduction Mail"}
            onClick={sendMail}
            //   onClick={() => navigate(-1)}
          />
          <CustomButton
            icon={<FaFileLines size={20} />}
            title={"Generate Quotation"}
            onClick={() => setQuotaionModal(true)}
            //   onClick={() => navigate(-1)}
          />
          <CustomButton
            icon={<FaFileLines size={20} />}
            title={"Share Quotation"}
            onClick={() => handleShareQuotaion()}
            //   onClick={() => navigate(-1)}
          />
          <CustomButton
            icon={<IoArrowBack size={20} />}
            title={"Back"}
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="flex md:hidden flex-wrap gap-2 items-center">
          <IconButton
            icon={<FaRegAddressCard size={20} />}
            title={"Share Business Card"}
            //   onClick={() => navigate(-1)}
          />
          <IconButton
            icon={<IoMailOutline size={20} />}
            title={"Share Introduction Mail"}
            //   onClick={() => navigate(-1)}
          />
          <IconButton
            icon={<FaFileLines size={20} />}
            title={"Share Quotation"}
            //   onClick={() => navigate(-1)}
          />
          <IconButton
            icon={<IoArrowBack size={20} />}
            title={"Back"}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <p className="text-[#333333] font-semibold text-center text-4xl mt-3">
        Company Detail
      </p>
      {/* <div className="bg-[#fff] p-4 mt-4">
        <CustomTable
          list={[lead_detail]}
          check={false}
          headerData={[
            "Company Type",
            "Company Name",
            "City",
            "Location",

            "Action",
          ]}
          bodyData={[lead_detail].map((item, index) => (
            <tr key={item.ID}>
              

              <td className="py-2 px-4 border-b ">{item.COMPANY_TYPE}</td>
              <td className="py-2 px-4 border-b">{item.COMPANY_NAME}</td>
              <td className="py-2 px-4 border-b">{item.CITY}</td>
              <td className="py-2 px-4 border-b">{item.LOCATION}</td>
              <td className="py-2 px-4 border-b ">
                <div className="flex gap-4">
                  <CiEdit
                    size={20}
                    color="#0363B0"
                    className="cursor-pointer"
                    onClick={
                      () => {
                        setDetail(item);
                        setIsModalOpen2(true);
                      }
                     
                    }
                  />
                  <CiTrash
                    size={20}
                    color="#0363B0"
                    className="cursor-pointer"
                    onClick={() => {
                      setdel(0);
                      setDetail(item);
                      showModal();
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        />
      </div> */}
      <div className="px-3 bg-white mt-3 py-3">
        {/* <div className="flex your-print-container-class  justify-end  px-6">
          <button
            className=" px-4  py-2 right-0 bg-blue-500 text-white rounded self-center"
            onClick={generatePDF}
          >
            Print
          </button>
        </div> */}
        <div
          className="p-6 section-to-print pt-0 "
          id="printableDiv"
          ref={pdfRef}
        >
          <div>
            <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              1.Customer Information
            </p>
          </div>
          <div className="flex flex-wrap pt-3 w-full gap-4">
            <CustomInput
              preview
              inline
              onlyView={true}
              label={"Customer ID"}
              value={detail?.CUSTOMER_ID}
            />
            <CustomInput
              preview
              onChange={(e) =>
                setDetail({ ...detail, CUSTOMER_NAME: e.target.value })
              }
              inline
              onlyView
              label={"Company Name"}
              value={
                lead_detail?.COMPANY_NAME
              }
            />
            <CustomInput
              preview
              onChange={(e) =>
                setDetail({ ...detail, FULL_ADDRESS: e.target.value })
              }
              inline
              onlyView
              label={"Full Address"}
              value={
               lead_detail?.ADDRESS
              }
            />
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, STATE: e.target.value })
                }
                onlyView
                label={"State"}
                value={lead_detail?.STATE}
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, POST_CODE: e.target.value })
                }
                onlyView
                label={"Postcode"}
                value={
                  lead_detail?.POSTCODE
                }
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) => setDetail({ ...detail, CITY: e.target.value })}
                onlyView
                label={"City / Country"}
                value={lead_detail?.CITY}
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, REFERENCE: e.target.value })
                }
                onlyView
                label={"Reference"}
                value={lead_detail?.REFERENCE}
              />
            </div>
          </div>

          <div className="flex gap-4 mt-4 items-center">
            <p className="text-[#0363B0]  text-[20px] font-semibold">
              2.Contact Detail
            </p>
            {/* <FaPlus className="text-[24px] cursor-pointer text-[#0363B0]" /> */}
          </div>
          {lead_detail?.CONTACT_INFO?.map((item, index) => {
            console.log(lead_detail,index);
            return (
            <div className="flex flex-wrap pt-3 w-full gap-4">
              {/* <td className="py-2 px-4 border-b"> */}
              <input
                type="checkbox"
                checked={selectedItems.includes(item.ID)}
                onChange={() => handleIndividualCheckedChange(item.ID)}
              />
              {/* </td> */}
              <div className="flex w-[100%] items-center gap-4">
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({ ...detail, CONTACT_PERSON: e.target.value })
                  }
                  onlyView
                  label={"Contact person Name"}
                  value={
                    item.CONTACT_PERSON
                  }
                />
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({ ...detail, EMAIL: e.target.value })
                  }
                  onlyView
                  label={"E-Mail"}
                  value={
                    item.EMAIL_ID
                  }
                />
              </div>
              <div className="flex w-[100%] items-center gap-4">
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({ ...detail, DEPARTMENT: e.target.value })
                  }
                  onlyView
                  label={"Department"}
                  value={
                   item?.DEPARTMENT
                  }
                />
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({ ...detail, DESIGNATION: e.target.value })
                  }
                  onlyView
                  label={"Designation"}
                  value={item.DESIGNATION}
                />
              </div>
              <div className="flex w-[100%] items-center gap-4">
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({ ...detail, SECONDARY_EMAIL: e.target.value })
                  }
                  onlyView
                  label={"E-Mail"}
                  value={
                    item.EMAIL_ID
                  }
                />
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      SECONDARY_REFERENCE: e.target.value,
                    })
                  }
                  onlyView
                  label={"Reference"}
                  value={detail?.SECONDARY_REFERENCE}
                />
              </div>
              <div className="flex w-[100%] items-center gap-4">
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({ ...detail, SECONDARY_EMAIL: e.target.value })
                  }
                  onlyView
                  label={"Quotation Mail"}
                  value={
                    item.IS_QUOTATION_MAIL_SENDED?'sent':'not sent'
                  }
                />
                <CustomInput
                  preview
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      SECONDARY_REFERENCE: e.target.value,
                    })
                  }
                  onlyView
                  label={"Intro Mail"}
                  value={ item.IS_INTRO_MAIL_SENDED?'sent':'not sent'}
                />
              </div>
            </div>
          )})}

          {/* <div>
            <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              3.Purchase Order Details
            </p>
          </div>
          <div className="flex flex-wrap pt-3 w-full gap-4">
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_NUMBER: e.target.value })
                }
                onlyView
                label={"PO Number"}
                value={
                  detail?.PO_INFO?.length > 0
                    ? detail?.PO_INFO[0]?.PO_NUMBER
                    : detail?.PO_NUMBER
                }
              />
              <CustomInput
                onChange={(e) =>
                  setDetail({ ...detail, PO_EXPIRY_DATE: e.target.value })
                }
                onlyView
                disabled={true}
                nonee
                label={"PO Expiry Date"}
                value={
                  detail?.PO_INFO?.length > 0
                    ? detail?.PO_INFO[0]?.PO_NUMBER
                    : ""
                }
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_DATE: e.target.value })
                }
                onlyView
                label={"PO Date"}
                value={
                  detail?.PO_INFO?.length > 0 ? detail?.PO_INFO[0]?.PO_DATE : ""
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_POSTCODE: e.target.value })
                }
                onlyView
                label={"Postcode"}
                value={detail?.PO_INFO?.length > 0 ? detail?.POST_CODE : ""}
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, CONTACT_NO: e.target.value })
                }
                onlyView
                label={"Contact No"}
                value={
                  detail?.CONTACT_INFO?.length > 0
                    ? detail?.CONTACT_INFO[0]?.CONTACT_NUMBER
                    : ""
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_CITY_COUNTRY: e.target.value })
                }
                onlyView
                label={"City / Country"}
                value={detail?.CITY}
              />
            </div>
            <div className="flex w-[100%] items-center gap-4">
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_EXPIRY_DATE: e.target.value })
                }
                onlyView
                label={"PO expiry date"}
                value={
                  detail?.PO_INFO?.length > 0
                    ? detail?.PO_INFO[0]?.PO_EXPIRY_DATE
                    : ""
                }
              />
              <CustomInput
                preview
                onChange={(e) =>
                  setDetail({ ...detail, PO_REFERENCE: e.target.value })
                }
                onlyView
                label={"Reference"}
                value={
                  detail?.P0_INFO?.length > 0
                    ? detail?.P0_INFO[0]?.PO_NUMBER
                    : ""
                }
              />
            </div>
          </div>
          <div>
            <p className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              4. ORDER SUMMARY
            </p>
            <CustomTable
              order_summary={
                detail?.PO_INFO?.length > 0
                  ? detail?.PO_INFO[0]?.ORDER_SUMMARY
                  : []
              }
              edit={false}
              // setOrder_summary
              options
              detail2
              setd
            />
          </div>
          <div className="!min-w-full">
            <p className="text-[#0363B0] my-4 text-[20px] font-semibold">
              5. FILE PREVIEW
            </p>
            {getFileExtension(
              detail?.FILES_DETAILS?.length
                ? detail?.FILES_DETAILS[0]?.path
                : ""
            ) === "pdf" ? (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={
                    detail?.FILES_DETAILS?.length
                      ? fileUrl + detail?.FILES_DETAILS[0]?.path
                      : ""
                  }
                />
              </Worker>
            ) : (
              <Image
                src={
                  detail?.FILES_DETAILS?.length
                    ? fileUrl + detail?.FILES_DETAILS[0]?.path
                    : ""
                }
                className="min-h-[350px] max-h-[350px]"
                alt="File Preview"
              />
            )}
          </div>
          <div className="flex mt-4 flex-wrap items-center pt-3 w-full gap-4">
            <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              6. Order Status
            </div>
            <p className="text-[20px] ml-auto font-semibold mt-4 bg-[#e1e5e7] px-3 py-[0.5]">
              Pending
            </p>
          </div>
          <div className="flex flex-col flex-wrap pt-3 w-full gap-4">
            <div className="text-[#0363B0] mt-4 text-[20px] font-semibold">
              7. Audit Trial
            </div>
            <div className="ml-6 mt-auto flex items-center justify-between">
              <p className="font-semibold">
                <span className="text-gray-500 font-normal">Created By:</span>{" "}
                Pabba sk guptha
              </p>
              <p className="font-semibold">
                <span className="text-gray-500 font-normal">Created Date:</span>{" "}
                29/07/2024
              </p>
              <p className="font-semibold">
                <span className="text-gray-500 font-normal">
                  Last Modified By:
                </span>{" "}
                Admin
              </p>
            </div>
          </div> */}
        </div>
      </div>
      {/* <p className="text-[#333333] font-medium text-[20px] mt-3">
        Contact Person Detail
      </p> */}
      {/* <div className="bg-[#fff] p-4 mt-4">
        <CustomTable
          list={[lead_detail]}
          check={false}
          headerData={[
            <input
              type="checkbox"
              checked={allChecked}
              onChange={handleAllCheckedChange}
            />,
            "Person Name",
            "Designation",
            "Department",
            "Eamil Address",
            "Mobile Number",
            "COMMENT",
            "NOTES",
            "Intro Mail Status",
            "Actions",
          ]}
          bodyData={lead_detail?.CONTACT_INFO?.map((item) => (
            <tr key={item.ID}>
              <td className="py-2 px-4 border-b">
                <input
                  type="checkbox"
                  checked={selectedItems.includes(item.ID)}
                  onChange={() => handleIndividualCheckedChange(item.ID)}
                />
              </td>
              <td className="py-2 px-4 border-b">{item.CONTACT_PERSON}</td>
              <td className="py-2 px-4 border-b">{item.DESIGNATION}</td>
              <td className="py-2 px-4 border-b">{item.DEPARTMENT}</td>
              <td className="py-2 px-4 border-b">{item.EMAIL_ID}</td>
              <td className="py-2 px-4 border-b">{item.MOBILE_NUMBER}</td>
              <td className="py-2 px-4 border-b">
                <Tooltip title={item.COMMENT}>
                  <span className="line-clamp-3">{item.COMMENT}</span>
                </Tooltip>
              </td>
              <td className="py-2 px-4 border-b">
                <Tooltip title={item.NOTES}>
                  <span className="line-clamp-3">{item.NOTES}</span>
                </Tooltip>
              </td>
              <td className="py-2 px-4 border-b">
                {item.IS_INTRO_MAIL_SENDED ? (
                  <button className="bg-blue-600 px-4 text-white rounded-[14px] py-1">
                    Sent
                  </button>
                ) : (
                  <button className="bg-blue-600 px-4 text-white rounded-[14px] py-1">
                    Not Sent
                  </button>
                )}
              </td>
              <td className="py-2 px-4 border-b">
                <div className="flex gap-4">
                  <CiEdit
                    size={20}
                    color="#0363B0"
                    className="cursor-pointer"
                    onClick={() => {
                      setDetail(item);
                      setIsModalOpen1(true);
                    }}
                  />
                  <CiTrash
                    size={20}
                    color="#0363B0"
                    className="cursor-pointer"
                    onClick={() => {
                      setdel(1);
                      setDetail(item);
                      showModal();
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        />
      </div> */}
      {/* <div className="justify-end flex mt-3">
        <CustomButton title={"Follow Up"} onClick={() => setFollow(true)} />
      </div> */}
      {lead_detail?.QUOTAION_URLS?.length > 0 && (
        <div>
          <p className="text-[#333333] font-medium text-[20px] mt-3">
            Quotation Url's
          </p>
          <div className="bg-[#fff] p-4 mt-4">
            <CustomTable
              list={[lead_detail]}
              check={false}
              headerData={["Quotation Number", "Quotation Url"]}
              bodyData={lead_detail?.QUOTAION_URLS?.map((item, index) => (
                <tr key={item.ID} className="border-b ">
                  <td className="py-2 px-4  flex itc">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={selectedQuotaionUrl === item?.URL}
                      onClick={() => {
                        setSelectedQuotationUrl(item?.URL);
                      }}
                    />
                    {item.QUOTAION_NUM}
                  </td>
                  <td className="py-2 px-4  ">
                    <CustomButton
                      title="Show Pdf"
                      onClick={() => {
                        console.log(item?.URL);
                        setQuotationUrl(
                          "https://api.psklavinlab.com/" + item?.URL
                        );
                        setShowpdf(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
            />
          </div>
        </div>
      )}
      <Modal
        title=""
        open={follow}
        onOk={() => setFollow(false)}
        onCancel={() => setFollow(false)}
        centered
        width={"60%"}
        footer={null} // Use `null` instead of `false` for footer prop
        bodyStyle={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      >
        <p className="text-[#333333] font-medium text-[20px] mt-3">
          Add Contact Person Detail
        </p>
        <div className="gap-4 flex flex-col mt-8">
          <div className="flex gap-4 ">
            <InputInline
              value={data?.p_name}
              error={errors?.p_name}
              name={"p_name"}
              onChange={handleChange}
              label={"Contact Person"}
              placeholder={"Enter Contact Person Name"}
              className={"flex-1"}
            />
            <InputInline
              value={data?.p_email}
              error={errors?.p_email}
              name={"p_email"}
              onChange={handleChange}
              label={"Email ID"}
              placeholder={"Enter Email ID"}
              className={"flex-1"}
            />
          </div>
          <div className="flex gap-4">
            <InputInline
              value={data?.p_number}
              error={errors?.p_number}
              name={"p_number"}
              onChange={handleChange}
              label={"Mobile Number"}
              placeholder={"Enter Mobile Number"}
              className={"flex-1"}
            />
            <InputInline
              value={data?.designation}
              error={errors?.designation}
              name={"designation"}
              onChange={handleChange}
              label={"Designation"}
              placeholder={"Enter Designation Name"}
              className={"flex-1"}
            />
          </div>
          <div className="flex gap-4">
            <InputInline
              value={data?.department}
              error={errors?.department}
              name={"department"}
              onChange={handleChange}
              label={"Department"}
              placeholder={"Enter Designation Name"}
              className={"flex-1"}
            />
            <div className="flex-1" />
          </div>
          <div className="flex gap-4">
            <InputInline
              value={data?.comment}
              error={errors?.comment}
              name={"comment"}
              onChange={handleChange}
              area={true}
              label={"Comment"}
              placeholder={"Enter Comment"}
              className={"flex-1"}
            />
            <InputInline
              value={data?.notes}
              error={errors?.notes}
              name={"notes"}
              onChange={handleChange}
              area={true}
              label={"Notes"}
              placeholder={"Enter Notes"}
              className={"flex-1"}
            />
          </div>

          <div className="justify-center items-center flex mt-3 gap-4">
            <CustomButton
              title={"Submit"}
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
      </Modal>
      <Modal
        title=""
        open={showpdf}
        onOk={() => setShowpdf(false)}
        onCancel={() => setShowpdf(false)}
        centered
        width={""}
        className="md:w-[60%] w-[100%]"
        footer={null} // Use `null` instead of `false` for footer prop
        bodyStyle={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
      >
        <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
          <Viewer
            fileUrl={quotationUrl}
            plugins={[defaultLayoutPluginInstance]}
            className="!w-[300px] !h-[300px]" // Apply your styles here
          />
        </Worker>
      </Modal>

      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={
          <div className="flex gap-4 justify-end">
            <CustomButton
              title={"Cancel"}
              className={"h-[28px] min-w-[80px]"}
              onClick={handleCancel}
            />
            <CustomButton
              loading={loading}
              onClick={() =>
                del == 0 ? handleDelete() : handleContactDelete()
              }
              title={"Delete"}
              className={"h-[28px] min-w-[80px] bg-[#F00]"}
            />
          </div>
        }
      >
        <p className="font-calibri font-normal text-[16px]">
          Are you sure you want to delete detail
        </p>
      </Modal>
      <UpadteContactInfo
        isModalOpen={isModalOpen1}
        setIsModalOpen={setIsModalOpen1}
        detail={detail}
        main_id={location.state?.ID}
        onUpdate={() => get_data()}
      />
      <UpdateLead
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        detail={detail}
        onUpdate={() => get_data()}
      />
      <QuotationModal
        lead_detail={lead_detail}
        CONTACT_INFO={lead_detail?.CONTACT_INFO}
        COMPANY_DETAIL={lead_detail}
        A4={true}
        setIsMainModalOpen={setQuotaionModal}
        isMainModalOpen={quotaionModal}
      />
    </div>
  );
};

export default FollowUp;
