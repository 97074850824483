import React from "react";
import { HashLoader } from "react-spinners";

const BlurryLoading = () => {
  return (
    <div className="fixed  inset-0 flex items-center justify-center bg-white bg-opacity-80 backdrop-blur z-[2000]">
      <div className="my-10 items-center justify-center flex flex-col">
        <HashLoader color="#0363B0" size={50} />
        {/* <ScaleLoader color="#0363B0" size={50} /> */}
      </div>
    </div>
  );
};

export default BlurryLoading;
