import React, { useEffect, useState } from "react";
import TableHeader from "../TableHeader";
import CustomTable from "../CustomTable";
import { Select, Tag, Modal } from "antd";
import { CiTrash } from "react-icons/ci";
import { FaEye } from "react-icons/fa6";
import { CustomModal, Delete_Modal } from "../modals";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import useScreenSize from "../../utils/useScreenSize";
import Appointment from "../../pages/hr-portal/Appointment";
import OfferLetter from "../../pages/hr-portal/Offer";
import RelievingLetter from "../../pages/hr-portal/Relieving";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";

const DocumentModal = ({ item, handleData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [id, setId] = useState("");
  const [deletedoc, setDeletedoc] = useState(false);
  const [type,setType]=useState('');
  const [employeeId, setEmployeeId] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const screen = useScreenSize();
  console.log(item?.APPOINTMENT_LATTER);

  // Pagination state
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [edit,setEdit]=useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const currentItems = [].slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSendClick = (document) => {
    if (document.STATUS === "send") {
      setSelectedDocument(document);
      setIsConfirmationModalOpen(true);
    }
  };

  const confirmResend = () => {
    console.log("Document resent:", selectedDocument);
    setIsConfirmationModalOpen(false);
  };

  const handleViewClick = (type, item) => {
    setModalType(type);
    console.log(type);
    console.log(item, item?.OFFER_LATTER[0]?.URL);
    setPdfUrl(
      "https://api.psklavinlab.com/" +
        (type === "appointment"
          ? item?.APPOINTMENT_LATTER[0]?.URL
          : type === "offer"
          ? item?.OFFER_LATTER[0]?.URL
          : item?.RELIEVING_LATTER[0]?.URL)
    );
    setIsModalOpen(true);
  };

  const handleDelete = async (type) => {
    console.log(type)
    const url=type==='appointment'?"hr-management-appointlatter/delete-latter":type==='offer'?"hr-management-offerlatter/delete-latter":"hr-management-appointlatter/delete-latter"
    const res = await postApiToken(url,
      JSON.stringify({ ID: [item?.APPOINTMENT_LATTER[0]?.ID] })
    );
    if (res.success) {
      setDeletedoc(false);
      await handleData();
      openNotification("success", "Success", res.message);
    } else {
      openNotification("error", "Success", res.message);
    }
  };

  const handleAddClick = (type, item) => {
    console.log(item);
    setModalType(type);
    setEmployeeId(item?.userInfo[0]?.USER_ID);
    setId(item?.USER_ID);
    setOpenModal(true);
  };

  useEffect(()=>{
    if(!openModal)setEdit(false);
  },[openModal])

  const renderDocumentActions = (item, documentType, type) => {
    const hasDocument = item[documentType]?.length > 0;

    return (
      <div className="flex gap-4">
        {hasDocument && (
          <>
            <FaEye
              size={20}
              color="#0363B0"
              className="cursor-pointer"
              onClick={() => handleViewClick(type, item)}
            />
            <CiTrash
              size={20}
              color="#0363B0"
              className="cursor-pointer"
              onClick={() => {
                setDeletedoc(true);
                setType(type)
              }}
            />
            <button
              className="bg-blue-500 px-3 rounded-sm py-[0.5] text-white"
              onClick={() => handleSendClick(item)}
            >
              send
            </button>
          </>
        )}
          <button
            className="bg-blue-500 px-3 rounded-sm py-[0.5] text-white"
            onClick={() => {
              handleAddClick(type, item);
              if(hasDocument){
                setEdit(true);
              }
            }}
          >
            {hasDocument?'update':'create'}
          </button>
      </div>
    );
  };

  return (
    <div className="bg-[#fff] p-4 mt-4">
      <CustomTable
        user_id
        check={false}
        list={[0, 0, 0]}
        selected={[]}
        setSelected={() => {}}
        headerData={["Document Name", "Status", "Actions"]}
        bodyData={
          <>
            <tr>
              <td className="py-2 px-4 border-b">Appointment Letter</td>
              <td className="py-2 px-4 border-b">
                <Tag
                  color={
                    item?.APPOINTMENT_LATTER?.length > 0 ? "default" : "error"
                  }
                >
                  {item?.APPOINTMENT_LATTER?.length > 0
                    ? "Generated"
                    : "Not Generated"}
                </Tag>
              </td>
              <td className="py-2 px-4 border-b">
                {renderDocumentActions(
                  item,
                  "APPOINTMENT_LATTER",
                  "appointment"
                )}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Offer Letter</td>
              <td className="py-2 px-4 border-b">
                <Tag
                  color={item?.OFFER_LATTER?.length > 0 ? "default" : "error"}
                >
                  {item?.OFFER_LATTER?.length > 0
                    ? "Generated"
                    : "Not Generated"}
                </Tag>
              </td>
              <td className="py-2 px-4 border-b">
                {renderDocumentActions(item, "OFFER_LATTER", "offer")}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Relieving Letter</td>
              <td className="py-2 px-4 border-b">
                <Tag
                  color={
                    item?.RELIEVING_LATTER?.length > 0 ? "default" : "error"
                  }
                >
                  {item?.RELIEVING_LATTER?.length > 0
                    ? "Generated"
                    : "Not Generated"}
                </Tag>
              </td>
              <td className="py-2 px-4 border-b">
                {renderDocumentActions(item, "RELIEVING_LATTER", "relieving")}
              </td>
            </tr>
          </>
        }
      />

      <CustomModal
        title={"Document Detail"}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        A4={screen.isDesktop}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      </CustomModal>

      <CustomModal
        title={`${!edit?'Add':'Update'} ${
          modalType.charAt(0).toUpperCase() + modalType.slice(1)
        } Letter`}
        isModalOpen={openModal}
        setIsModalOpen={setOpenModal}
        A4
      >
        {modalType === "appointment" && (
          <Appointment
            id={id}
            employeeId={employeeId}
            parentModal={openModal}
            setParentModal={setOpenModal}
            edit={edit}
          />
        )}
        {modalType === "offer" && (
          <OfferLetter
            id={id}
            employeeId={employeeId}
            parentModal={openModal}
            setParentModal={setOpenModal}
            edit={edit}
          />
        )}
        {modalType === "relieving" && (
          <RelievingLetter
            id={id}
            employeeId={employeeId}
            parentModal={openModal}
            setParentModal={setOpenModal}
            edit={edit}
          />
        )}
      </CustomModal>

      <Modal
        title="Resend Document"
        open={isConfirmationModalOpen}
        onOk={confirmResend}
        onCancel={() => setIsConfirmationModalOpen(false)}
        okText="Resend"
        cancelText="Cancel"
      >
        <p>Are you sure you want to resend this document?</p>
      </Modal>
      <Delete_Modal
        handleDelete={()=>handleDelete(type)}
        isModalOpen={deletedoc}
        setIsModalOpen={setDeletedoc}
      />
    </div>
  );
};

export default DocumentModal;
