import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

const useScreenSize = () => {
  // Define initial state with null values to handle SSR
  const [isMobile, setIsMobile] = useState(null);
  const [isTablet, setIsTablet] = useState(null);
  const [isDesktop, setIsDesktop] = useState(null);

  // Update state with media query results
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1023);
      setIsDesktop(window.innerWidth >= 1024);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Call handler to set initial values
    handleResize();

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile, isTablet, isDesktop };
};

export default useScreenSize;
