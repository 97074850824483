import React, { useState } from 'react';
import CustomButton from '../../components/CustomButton';

const SmallDrop = ({
  testCategorieswithId,
  setSelect_ids,
  selectId,
  data,
  onClose
}) => {
  const [checkedItems, setCheckedItems] = useState([]); // State to manage checked items

  // Handle checkbox click
  const handleCheckboxClick = (itt) => {
    console.log(itt)
    const isChecked = checkedItems.includes(itt.id);
    let updatedCheckedItems;
    
    if (isChecked) {
      // If already checked, remove from the list
      updatedCheckedItems = checkedItems.filter((id) => id !== itt.id);
    } else {
      // If not checked, add to the list
      updatedCheckedItems = [...checkedItems, itt.id];
    }

    // Update state
    setCheckedItems(updatedCheckedItems);
    setSelect_ids(updatedCheckedItems);
  };

  return (
    <div>
      {testCategorieswithId.get(data)?.map((itt, index) => (
        <div key={itt.id} className="flex items-center gap-2 mb-2">
          <input
            type="checkbox"
            checked={checkedItems.includes(itt.id)}
            onChange={() => handleCheckboxClick(itt)} // Handle checkbox change
          />
          <p>{itt?.name}</p>
        </div>
      ))}
      <CustomButton title={'OK'} className={'float-right'} onClick={onClose}/>
    </div>
  );
};

export default SmallDrop;
