import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import { toast } from "react-toastify";
import logo from "../../../assets/svg/Logo.svg";
import CustomButton from "../../../components/CustomButton";

const AppointmentFormat = ({
  recipientName = "",
  recipientAddress = "",
  city,
  state,
  zip,
  setParentModal,
  parentModal,
  setParentModal1,
  parentModal1,
  item,
  USER_ID,
  employeeId,
}) => {
  const letterRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("lavin"));
  console.log(item);
  console.log(employeeId);
  const sendFormData = async () => {
    const formData = new FormData();
    formData.append("RECIPIENT_NAME", item.RECIPIENT_NAME);
    formData.append("STATE", item.STATE);
    formData.append("CITY", item.CITY);
    formData.append("RECIPIENT_ADDRESS", item.RECIPIENT_ADDRESS);
    formData.append("ZIPCODE", item.ZIPCODE);
    formData.append("DATE", item.DATE);
    formData.append("POSITION", item.POSITION);
    formData.append("START_DATE", item.START_DATE);
    formData.append("DEPARTMENT_NAME", item.DEPARTMENT_NAME);
    formData.append("REPORTING_TO", item.REPORTING_TO);
    formData.append("WORKING_HOURS", item.WORKING_HOURS);
    formData.append("SALARY", item.SALARY);
    formData.append("EMAIL_ADDRESS", "kumag@gmail.com");
    formData.append("EMPLOYEE_ID", employeeId);
    const file = await generatePDF();
    formData.append("FILE", file);
    formData.append("USER_ID", USER_ID);
    try {
      const response = await fetch(
        "https://api.psklavinlab.com/api/hr-management-appointlatter/create",
        {
          method: "POST",
          headers: {
            Authorization: `bearer ${user?.TOKEN}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data");
      } else {
        toast.success("Appointment Letter Sent Successfully");
        window.location.reload();
      }
      alert("Appointment Letter Sent Successfully");
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const generatePDF = async () => {
    try {
      const input = letterRef.current;
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const scaleX = pdfWidth / imgWidth;
      const scaleY = pdfHeight / imgHeight;
      const scale = Math.min(scaleX, scaleY);
      const contentWidth = imgWidth * scale;
      const contentHeight = imgHeight * scale;

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        contentWidth,
        contentHeight,
        "",
        "FAST"
      );
      const pdfBlob = pdf.output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="font-calibri text-sm">
      <div className="border">
        <div
          className="p-3 px-8 max-w-[1000px] mx-auto bg-white text-[12px] my-4"
          ref={letterRef}
        >
          <div className="flex mt-3 pb-8 justify-end">
            <img src={logo} alt="" />
          </div>
          <p className="text-center text-[20px] font-bold">
            Appointment letter
          </p>
          <p className="text-[16px]">{moment().format("DD/MM/YYYY")}</p>
          <p className="text-[16px]">PSK LAVIN LABORATORIES PVT. LTD</p>
          <p className="text-[16px]">NMR Complex, 2nd Floor,</p>
          <p className="text-[16px]">Nacharam - Mallapur Rd, Bhavani Nagar</p>
          <p className="text-[16px]">Secunderabad, Telangana 500076</p>
          <p className="text-[16px]">9849265173</p>
          <p className="text-[16px]">lavinlaboratories@gmail.com</p>
          <br />
          <p className="text-[16px]">To,</p>
          <p className="text-[16px]">{recipientName}</p>
          <p className="text-[16px]">{recipientAddress}</p>
          <p className="text-[16px]">
            {city}, {state}, {zip}
          </p>
          <br />
          <p className="text-[16px]">Dear {recipientName},</p>
          <p className="text-[16px]">
            Subject: Appointment as{" "}
            <span className="font-bold">{item?.POSITION}</span>
          </p>
          <br />
          <p className="text-[16px]">
            We are pleased to inform you that you have been appointed as a{" "}
            <span className="font-bold">{item?.POSITION}</span> at PSK LAVIN
            LABORATORIES PVT. LTD with effect from {item?.START_DATE}. Your
            skills and experience in the field of microbiology have impressed
            us, and we believe you will be a valuable addition to our team.
          </p>
          <br />
          <p className="text-[16px]">
            The terms of your employment are as follows:
          </p>
          <p className="text-[16px]">
            Position : <span className="font-bold">{item?.POSITION}</span>
          </p>
          <p className="text-[16px]">
            Department :{" "}
            <span className="font-bold">{item?.DEPARTMENT_NAME}</span>
          </p>
          <p className="text-[16px]">
            Reporting To :{" "}
            <span className="font-bold">{item?.REPORTING_TO}</span>
          </p>
          <p className="text-[16px]">
            Start Date : <span className="font-bold">{item?.START_DATE}</span>
          </p>
          <p className="text-[16px]">
            Working Hours :{" "}
            <span className="font-bold">{item?.WORKING_HOURS}</span>
          </p>
          <p className="text-[16px]">
            Salary : <span className="font-bold">{item?.SALARY}</span>
          </p>
          <br />
          <p className="text-[16px]">Duties and Responsibilities:</p>
          <ul className="list-disc ml-6">
            <li className="text-[14px]">
              Conducting experiments and analyses in Microbiology department.
            </li>
            <li className="text-[14px]">
              Preparing reports and documentation.
            </li>
            <li className="text-[14px]">
              Ensuring compliance with safety and environmental regulations.
            </li>
          </ul>
          <br />
          <p className="text-[16px]">
            You are expected to abide by all company policies and procedures as
            outlined in the employee handbook. Your performance will be reviewed
            periodically, and your employment is subject to satisfactory
            performance and adherence to company policies.
          </p>
          <br />
          <p className="text-[16px]">
            Please sign and return a copy of this letter as a token of your
            acceptance of the terms and conditions of this appointment.
          </p>
          <p className="text-[16px]">
            We look forward to a long and successful association with you.
          </p>
          <br />
          <p className="text-[16px]">Yours sincerely,</p>
          <p className="text-[16px]">Managing Director</p>
          <p className="text-[16px]">PSK LAVIN LABORATORIES PVT. LTD</p>
        </div>
        <CustomButton
          className="px-4 float-right mt-4 py-2  text-white rounded self-center mb-4 mr-4"
          onClick={sendFormData}
          title={"Send Data"}
        />
      </div>
    </div>
  );
};

export default AppointmentFormat;
