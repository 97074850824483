// import React, { useEffect, useState } from "react";
// import HeaderText from "../../components/HeaderText";
// import CustomButton from "../../components/CustomButton";
// import { useLocation, useNavigate } from "react-router-dom";
// import CustomInput, { InputInline } from "../../components/CustomInput";
// import CustomDropdown from "../../components/CustomDropdown";
// import axios from "axios";
// import { IoArrowBack } from "react-icons/io5";
// import { openNotification } from "../../App";
// import { Modal } from "antd";
// import { store } from "../../redux/store";
// import { fetchLead_list } from "../../redux/slice/dataSlice";
// import { useSelector } from "react-redux";
// import CustomStepper from "../../components/CustomStepper";

// const UpdateLead = ({ isModalOpen, setIsModalOpen, detail = {}, onUpdate }) => {
//   const user = JSON.parse(localStorage.getItem("lavin"));
//   const navigate = useNavigate();
//   const { services_list } = useSelector((state) => state.allData);
//   const [currentStep, setCurrentStep] = useState(0);
//   const [data, setData] = useState({});
//   const [company_detail, setCompany_detail] = useState({});
//   const [service_detail, setService_detail] = useState({
//     FOOD_TESTING: "",
//     WATER_TESTING: "",
//     AIR_TESTING: "",
//   });
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState({});
//   const location = useLocation();
//   const [selected, setSelected] = useState([]);
//   const [select_ids, setSelect_ids] = useState([]);

//   useEffect(() => {
//     setCompany_detail(detail);
//     setSelected(detail?.SERVICE_REQUIRD);
//     setSelect_ids(detail?.SERVICE_REQUIRD?.map((v) => v.VALUE));
//     setCurrentStep(0);
//   }, [detail]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setData((prev) => {
//       return { ...prev, [name]: value?.trimStart() };
//     });
//     setErrors((prev) => {
//       return { ...prev, [name]: "" };
//     });
//   };
//   const handleCompanyChange = (e) => {
//     const { name, value } = e.target;
//     setCompany_detail((prev) => {
//       return { ...prev, [name]: value?.trimStart() };
//     });
//     setErrors((prev) => {
//       return { ...prev, [name]: "" };
//     });
//   };

//   const handleNext = () => {
//     // if (
//     //   !data.COMPANY_NAME ||
//     //   !data.c_type ||
//     //   !data.p_email ||
//     //   !data.p_name ||
//     //   !data.p_number ||
//     //   !data.department ||
//     //   !data.designation ||
//     //   !data.address ||
//     //   !data.branch ||
//     //   !data.country ||
//     //   !data.city ||
//     //   !data.state ||
//     //   !data.location
//     // ) {
//     //   setErrors({
//     //     ...errors,
//     //     c_name: !data.c_name ? "Please enter company name" : "",
//     //     c_type: !data.c_type ? "Please enter company type" : "",
//     //     p_email: !data.p_email ? "Please enter email address" : "",
//     //     p_name: !data.p_name ? "Please enter person name" : "",
//     //     p_number: !data.p_number ? "Please enter person contact number" : "",
//     //     department: !data.department ? "Please enter department" : "",
//     //     designation: !data.designation ? "Please enter designation" : "",
//     //     address: !data.address ? "Please enter address" : "",
//     //     branch: !data.branch ? "Please enter branch" : "",
//     //     country: !data.country ? "Please enter country" : "",
//     //     city: !data.city ? "Please enter city" : "",
//     //     state: !data.state ? "Please enter state " : "",
//     //     location: !data.location ? "Please enter location" : "",
//     //   });
//     //   return;
//     // }
//     setCurrentStep(1);
//   };
//   const handleSubmit = async () => {
//     // if (
//     //   !data.water_testing ||
//     //   !data.air_testing ||
//     //   !data.food_testing ||
//     //   !data.comment ||
//     //   !data.notes
//     // ) {
//     //   setErrors({
//     //     ...errors,
//     //     water_testing: !data.water_testing ? "" : "",
//     //     air_testing: !data.air_testing ? "" : "",
//     //     food_testing: !data.food_testing ? "" : "",
//     //     comment: !data.comment ? "" : "",
//     //     notes: !data.notes ? "" : "",
//     //   });
//     //   return;
//     // }

//     try {
//       setLoading(true);
//       let rawdata = JSON.stringify({
//         ID: detail?.ID,
//         USER_ID: user?.USER_ID,
//         IS_OTHER: company_detail.COMPANY_TYPE == "Others" ? true : false,
//         COMPANY_TYPE:
//           company_detail.COMPANY_TYPE == "Others"
//             ? !company_detail.other_type
//               ? "Demo 1"
//               : company_detail.other_type
//             : !company_detail.COMPANY_TYPE
//             ? "Demo 1"
//             : company_detail.COMPANY_TYPE,
//         COMPANY_NAME: !company_detail.COMPANY_NAME
//           ? "Demo 1"
//           : company_detail.COMPANY_NAME,
//         ADDRESS: !company_detail.ADDRESS ? "Demo 1" : company_detail.ADDRESS,
//         BRANCH: !company_detail.BRANCH ? "Demo 1" : company_detail.BRANCH,
//         CITY: !company_detail.CITY ? "Demo 1" : company_detail.CITY,
//         STATE: !company_detail.STATE ? "Demo 1" : company_detail.STATE,
//         COUNTRY: !company_detail.COUNTRY ? "Demo 1" : company_detail.COUNTRY,
//         // CONTACT_INFO: {
//         //   CONTACT_PERSON: !data.p_name ? "Demo 1" : data.p_name,
//         //   MOBILE_NUMBER: !data.p_number ? "Demo 1" : data.p_number,
//         //   DESIGNATION: !data.designation ? "Demo 1" : data.designation,
//         //   EMAIL_ID: !data.p_email ? "Demo@gmail.com" : data.p_email,
//         //   DEPARTMENT: !data.department ? "Demo 1" : data.department,
//         //   COMMENT: !data.comment ? "Demo 1" : data.comment,
//         //   NOTES: !data.notes ? "Demo 1" : data.notes,
//         // },
//         SERVICE_REQUIRD: selected?.map((item, index) => ({
//           NAME: item.label,
//           VALUE: item.value,
//         })),
//         LOCATION: !company_detail.LOCATION ? "Demo 1" : company_detail.LOCATION,
//       });

//       let config = {
//         method: "post",
//         // maxBodyLength: Infinity,
//         url: process.env.REACT_APP_API_KEY + "leads/update",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `bearer ${user?.TOKEN}`,
//         },
//         data: rawdata,
//       };
//       const response = await axios.request(config);
//       const res = await response.data;

//       setLoading(false);
//       if (res.success) {
//         openNotification("success", "Success", res.message);
//         store.dispatch(fetchLead_list());
//         onUpdate();
//         setIsModalOpen(false);
//       } else {
//         openNotification("error", "Error", res.message);
//       }
//     } catch (error) {
//       setLoading(false);
//     }
//   };
//   const allData = () => {
//     const orderedServiceIds = new Set(selected?.map((item) => item?.value));
//     return (
//       services_list
//         // ?.filter((service) => !orderedServiceIds.has(service?.ID))
//         .map((service) => ({
//           value: service?.ID,
//           label: service?.SERVICE_NAME,
//           desc: service?.DESCRIPTION,
//           price: service?.PRICE,
//         }))
//     );
//   };
//   return (
//     <Modal
//       title=""
//       open={isModalOpen}
//       onOk={() => setIsModalOpen(false)}
//       onCancel={() => setIsModalOpen(false)}
//       centered
//       className="w-[80%] lg:!w-[60%]"
//       footer={null} // Use `null` instead of `false` for footer prop
//       bodyStyle={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
//     >
//       <div className="flex items-center justify-between">
//         <HeaderText>Update Lead</HeaderText>
//       </div>
//       <div className="md:max-w-[60%] flex mx-auto">
//         <CustomStepper
//           active_tab={currentStep}
//           list={["Add Profile", "Required Services"]}
//         />
//       </div>

//       {currentStep == 0 && (
//         <div className="py-6 mt-4 gap-4 flex flex-col">
//           <p className="text-[#333333] font-medium text-[20px]">
//             Company Detail
//           </p>
//           <div className="flex gap-4 ">
//             <CustomDropdown
//               label={"Company Type"}
//               className={"flex-1"}
//               value={company_detail?.COMPANY_TYPE}
//               error={errors?.COMPANY_TYPE}
//               name={"COMPANY_TYPE"}
//               onChange={handleCompanyChange}
//             >
//               <option value={""}>Select Company Type</option>
//               <option value={"Demo 1"}>Demo 1</option>
//               <option value={"HOTEL"}>HOTEL</option>
//               <option value={"INDUSTRY"}>INDUSTRY</option>
//               <option value={"Factory"}>Factory</option>
//               <option value={"hospitals"}>hospitals</option>
//               <option value={"individuals"}>individuals</option>
//               <option value={"Others"}>Others</option>
//             </CustomDropdown>
//             {company_detail?.COMPANY_TYPE == "Others" ? (
//               <InputInline
//                 value={company_detail?.other_type}
//                 error={errors?.other_type}
//                 name={"other_type"}
//                 onChange={handleCompanyChange}
//                 label={"Company Type"}
//                 placeholder={"Enter Company Type"}
//                 className={"flex-1"}
//               />
//             ) : (
//               <div className="flex-1" />
//             )}
//           </div>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
//             <InputInline
//               value={company_detail?.COMPANY_NAME}
//               error={errors?.COMPANY_NAME}
//               name={"COMPANY_NAME"}
//               onChange={handleCompanyChange}
//               label={"Company Name"}
//               placeholder={"Enter Company Name"}
//               className={"flex-1"}
//             />
//             <InputInline
//               value={company_detail?.ADDRESS}
//               error={errors?.ADDRESS}
//               name={"ADDRESS"}
//               onChange={handleCompanyChange}
//               label={"Address"}
//               placeholder={"Enter Address"}
//               className={"flex-1"}
//             />
//           </div>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
//             <InputInline
//               value={company_detail?.BRANCH}
//               error={errors?.BRANCH}
//               name={"BRANCH"}
//               onChange={handleCompanyChange}
//               label={"Branch"}
//               placeholder={"Enter Branch"}
//               className={"flex-1"}
//             />
//             <InputInline
//               value={company_detail?.COUNTRY}
//               error={errors?.COUNTRY}
//               name={"COUNTRY"}
//               onChange={handleCompanyChange}
//               label={"Country"}
//               placeholder={"Enter Country Name"}
//               className={"flex-1"}
//             />
//           </div>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
//             <InputInline
//               value={company_detail?.STATE}
//               error={errors?.STATE}
//               name={"STATE"}
//               onChange={handleCompanyChange}
//               label={"State"}
//               placeholder={"Enter State Name"}
//               className={"flex-1"}
//             />
//             <InputInline
//               value={company_detail?.CITY}
//               error={errors?.CITY}
//               name={"CITY"}
//               onChange={handleCompanyChange}
//               label={"City"}
//               placeholder={"Enter City Name"}
//               className={"flex-1"}
//             />
//           </div>
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//             <InputInline
//               value={company_detail?.LOCATION}
//               error={errors?.LOCATION}
//               name={"LOCATION"}
//               onChange={handleCompanyChange}
//               label={"Location"}
//               placeholder={"Enter Location"}
//               className={"flex-1"}
//             />
//             <div className="flex-1"></div>
//           </div>

//           <div className="justify-center items-center flex mt-3">
//             <CustomButton title={"Next"} onClick={() => handleNext(1)} />
//           </div>
//         </div>
//       )}
//       {currentStep == 1 && (
//         <div className="py-2 gap-4 flex flex-col">
//           <div className=" flex flex-wrap gap-4">
//             <InputInline
//               drop
//               mode={"multiple"}
//               options={allData()}
//               value={select_ids}
//               // error={errors?.air_testing}
//               name={"air_testing"}
//               onChange={(e, item) => {
//                 setSelect_ids(e);
//                 setSelected(item);
//               }}
//               label={"Select Service"}
//               placeholder={"Select Service"}
//             />

//             <InputInline
//               value={data?.comment}
//               error={errors?.comment}
//               name={"comment"}
//               onChange={handleChange}
//               area={true}
//               label={"Comment"}
//               placeholder={"Enter Comment"}
//               className={"flex-1"}
//             />
//             <InputInline
//               value={data?.notes}
//               error={errors?.notes}
//               name={"notes"}
//               onChange={handleChange}
//               area={true}
//               label={"Notes"}
//               placeholder={"Enter Notes"}
//               className={"flex-1"}
//             />
//           </div>
//           <div className="justify-center items-center flex mt-3 gap-4">
//             <CustomButton title={"Back"} onClick={() => setCurrentStep(0)} />
//             <CustomButton
//               title={"Submit"}
//               onClick={handleSubmit}
//               loading={loading}
//             />
//           </div>
//         </div>
//       )}
//     </Modal>
//   );
// };

// export default UpdateLead;

import React, { useEffect, useState, useMemo } from "react";
import HeaderText from "../../components/HeaderText";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomInput, { InputInline } from "../../components/CustomInput";
import CustomDropdown from "../../components/CustomDropdown";
import axios from "axios";
import { Modal } from "antd";
import { store } from "../../redux/store";
import { fetchLead_list } from "../../redux/slice/dataSlice";
import { useSelector } from "react-redux";
import CustomStepper from "../../components/CustomStepper";
import { openNotification } from "../../App";
import { CustomModal } from "../../components/modals";

const UpdateLead = ({ isModalOpen, setIsModalOpen, detail = {}, onUpdate }) => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const navigate = useNavigate();
  const { services_list } = useSelector((state) => state.allData);

  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({
    comment: "",
    notes: "",
  });
  const [companyDetail, setCompanyDetail] = useState({
    COMPANY_TYPE: "",
    COMPANY_NAME: "",
    ADDRESS: "",
    BRANCH: "",
    CITY: "",
    STATE: "",
    COUNTRY: "",
    LOCATION: "",
    other_type: "",
  });
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCompanyDetail(detail);
    setSelected(detail?.SERVICE_REQUIRD || []);
    setCurrentStep(0);
  }, [detail]);

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const allData = useMemo(() => {
    const orderedServiceIds = new Set(
      selected.map((item) => item?.value)
    );
    return services_list
      ?.filter((service) => !orderedServiceIds.has(service?.ID))
      .map((service) => ({
        value: service?.ID,
        label: service?.SERVICE_NAME,
        desc: service?.DESCRIPTION,
        price: service?.PRICE,
      }));
  }, [services_list, selected]);

  const handleNext = () => {
    const requiredFields = [
      "COMPANY_NAME",
      "COMPANY_TYPE",
      "ADDRESS",
      "BRANCH",
      "CITY",
      "STATE",
      "COUNTRY",
      "LOCATION",
    ];

    const newErrors = requiredFields.reduce((acc, field) => {
      if (!companyDetail[field]) {
        acc[field] = `Please enter ${field.replace("_", " ").toLowerCase()}`;
      }
      return acc;
    }, {});

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
    } else {
      setCurrentStep(1);
    }
  };

  const handleSubmit = async () => {
    if (!data.comment || !data.notes) {
      setErrors({
        comment: !data.comment ? "Please enter comment" : "",
        notes: !data.notes ? "Please enter notes" : "",
      });
      return;
    }

    try {
      setLoading(true);

      const payload = {
        ID: detail?.ID,
        USER_ID: user?.USER_ID,
        IS_OTHER: companyDetail.COMPANY_TYPE === "Others",
        COMPANY_TYPE:
          companyDetail.COMPANY_TYPE === "Others"
            ? companyDetail.other_type || "Demo 1"
            : companyDetail.COMPANY_TYPE || "Demo 1",
        COMPANY_NAME: companyDetail.COMPANY_NAME || "Demo 1",
        ADDRESS: companyDetail.ADDRESS || "Demo 1",
        BRANCH: companyDetail.BRANCH || "Demo 1",
        CITY: companyDetail.CITY || "Demo 1",
        STATE: companyDetail.STATE || "Demo 1",
        COUNTRY: companyDetail.COUNTRY || "Demo 1",
        SERVICE_REQUIRD: selected.map((item) => ({
          NAME: item.label,
          VALUE: item.value,
        })),
        LOCATION: companyDetail.LOCATION || "Demo 1",
      };

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_KEY}leads/update`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.TOKEN}`,
        },
        data: JSON.stringify(payload),
      };

      const response = await axios.request(config);
      const res = await response.data;

      setLoading(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        setIsModalOpen(false);
        store.dispatch(fetchLead_list());
        onUpdate();
        
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <CustomModal
      title=""
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      centered
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      A4={true}
      className="lg:w-[70vh]"
      footer={null}
      bodyStyle={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
    >
      <div className="flex items-center justify-between">
        <HeaderText>Update Lead</HeaderText>
      </div>
      <div className="md:max-w-[60%] flex mx-auto">
        <CustomStepper
          active_tab={currentStep}
          list={["Add Profile", "Required Services"]}
        />
      </div>

      {currentStep === 0 && (
        <div className="py-6 mt-4 gap-4 flex flex-col">
          <p className="text-[#333333] font-medium text-[20px]">
            Company Detail
          </p>
          <div className="flex gap-4">
            <CustomDropdown
              label="Company Type"
              className="flex-1"
              value={companyDetail.COMPANY_TYPE}
              error={errors.COMPANY_TYPE}
              name="COMPANY_TYPE"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
            >
              <option value="">Select Company Type</option>
              <option value="Demo 1">Demo 1</option>
              <option value="HOTEL">HOTEL</option>
              <option value="INDUSTRY">INDUSTRY</option>
              <option value="Factory">Factory</option>
              <option value="hospitals">hospitals</option>
              <option value="individuals">individuals</option>
              <option value="Others">Others</option>
            </CustomDropdown>
            {companyDetail.COMPANY_TYPE === "Others" && (
              <InputInline
                value={companyDetail.other_type}
                error={errors.other_type}
                name="other_type"
                onChange={(e) => handleInputChange(e, setCompanyDetail)}
                label="Company Type"
                placeholder="Enter Company Type"
                className="flex-1"
              />
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputInline
              value={companyDetail.COMPANY_NAME}
              error={errors.COMPANY_NAME}
              name="COMPANY_NAME"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
              label="Company Name"
              placeholder="Enter Company Name"
              className="flex-1"
            />
            <InputInline
              value={companyDetail.ADDRESS}
              error={errors.ADDRESS}
              name="ADDRESS"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
              label="Address"
              placeholder="Enter Address"
              className="flex-1"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputInline
              value={companyDetail.BRANCH}
              error={errors.BRANCH}
              name="BRANCH"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
              label="Branch"
              placeholder="Enter Branch"
              className="flex-1"
            />
            <InputInline
              value={companyDetail.COUNTRY}
              error={errors.COUNTRY}
              name="COUNTRY"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
              label="Country"
              placeholder="Enter Country Name"
              className="flex-1"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputInline
              value={companyDetail.STATE}
              error={errors.STATE}
              name="STATE"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
              label="State"
              placeholder="Enter State Name"
              className="flex-1"
            />
            <InputInline
              value={companyDetail.CITY}
              error={errors.CITY}
              name="CITY"
              onChange={(e) => handleInputChange(e, setCompanyDetail)}
              label="City"
              placeholder="Enter City Name"
              className="flex-1"
            />
          </div>
          <InputInline
            value={companyDetail.LOCATION}
            error={errors.LOCATION}
            name="LOCATION"
            onChange={(e) => handleInputChange(e, setCompanyDetail)}
            label="Location"
            placeholder="Enter Location"
            className="flex-1"
          />
          <div className="flex justify-end mt-6">
            <CustomButton title={'Next'} onClick={handleNext}>Next</CustomButton>
          </div>
        </div>
      )}

      {currentStep === 1 && (
        <div className="py-6 mt-4 gap-4 flex flex-col">
          <p className="text-[#333333] font-medium text-[20px]">
            Required Services
          </p>
          <CustomInput
            label="Select Services"
            type="select"
            drop
            placeholder="Search Services"
            options={allData}
            disabled
            selectedOptions={selected}
            onSelect={(newSelected) => setSelected(newSelected)}
            error={errors.SERVICE_REQUIRD}
          />
          <InputInline
            value={data.comment}
            error={errors.comment}
            name="comment"
            onChange={(e) => handleInputChange(e, setData)}
            label="Comment"
            placeholder="Enter Comment"
            className="flex-1"
          />
          <InputInline
            value={data.notes}
            error={errors.notes}
            name="notes"
            onChange={(e) => handleInputChange(e, setData)}
            label="Notes"
            placeholder="Enter Notes"
            className="flex-1"
          />
          <div className="flex justify-between mt-6">
            <CustomButton title={'Back'} onClick={() => setCurrentStep(0)}>Previous</CustomButton>
            <CustomButton title={'Submit'} onClick={handleSubmit} loading={loading}>
              Submit
            </CustomButton>
          </div>
        </div>
      )}
    </CustomModal>
  );
};

export default UpdateLead;
