import React, { useEffect, useState } from "react";
import HeaderText from "../../components/HeaderText";
import CustomButton from "../../components/CustomButton";
import { FaEye, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import { CustomModal, Delete_Modal } from "../../components/modals";
import CustomTable from "../../components/CustomTable";
import TableHeader from "../../components/TableHeader";
import Loader from "../../components/Loader";
import { CiEdit, CiTrash } from "react-icons/ci";
import moment from "moment";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useNavigate } from "react-router-dom";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const QuotationList = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [quotationList, setQuotationList] = useState([]);
  const { quotationLoading } = useSelector((state) => state.allData);
  const [selected, setSelected] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [pdf, setPdf] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const user = JSON.parse(localStorage.getItem("lavin"));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuotations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}quick-quotation/list`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.TOKEN}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const jsonData = await response.json();
        setQuotationList(jsonData?.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchQuotations();
  }, [user?.TOKEN]);

  useEffect(() => {
    if (!viewModal) setDetail({});
  }, [viewModal]);

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async (type) => {
    setLoading(true);
    const body = JSON.stringify({
      ID: type === "single" ? [detail.ID] : selected,
    });
    const res = await postApiToken("quotation/delete-quotation", JSON.stringify(body));
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      setDeleteModal(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const filteredData = quotationList?.filter((item) =>
    [item.COMPANY_NAME, item.QUOTAION_NUM]
      .join(" ")
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>Quotation List</HeaderText>
        <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Quotation"}
          onClick={() => {
            setActiveTab(0);
            setAddModal(true);
            navigate("/quick-quotation/generate-quotation");
          }}
        />
      </div>
      {quotationLoading && quotationList?.length === 0 ? (
        <Loader />
      ) : (
        <div className="bg-white p-4  mt-4">
          <div className="">
            <TableHeader
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              tableHeaders={[
                "SR. No.",
                "COMPANY NAME",
                "Quotation No.",
                "Created Date",
              ]}
              tableData={filteredData.map((e, i) => [
                i + 1,
                e.COMPANY_NAME,
                e.QUOTAION_NUM,
                moment(e.CREATED_AT).format("DD-MM-YYYY"),
              ])}
              onDeleteAll={() => handleDelete("all")}
              tableName={"Quotations"}
              fileName={"quotations"}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={(page) => setCurrentPage(page)}
              onLimitChange={(limit) => setItemsPerPage(limit)}
              indexOfFirstItem={indexOfFirstItem}
              indexOfLastItem={indexOfLastItem}
            />
          </div>
          <div className="">
            <CustomTable
              list={quotationList}
              selected={selected}
              setSelected={setSelected}
              headerData={[
                "SR No.",
                "COMPANY NAME",
                "Quotation No.",
                "Created Date",
                "Actions",
              ]}
              bodyData={currentItems?.map((item, index) => (
                <tr key={item.ID}>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selected.includes(item.ID)}
                      onChange={() => handleSelect(item.ID)}
                    />
                  </td>
                  <td className="py-2 px-4 border-b">{index + 1}</td>

                  <td
                    className="py-2 px-4 cursor-pointer border-b"
                    onClick={() => {
                      setDetail(item);
                      setActiveTab(item?.STEP);
                      setViewModal(true);
                    }}
                  >
                    {item.COMPANY_NAME}
                  </td>
                  <td
                    onClick={() => {
                      setDetail(item);
                      setActiveTab(item?.STEP);
                      setViewModal(true);
                    }}
                    className="py-2 px-4 cursor-pointer border-b text-blue-600"
                  >
                    {item.QUOTAION_NUM}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {moment(item.CREATED_AT).format("DD-MM-YYYY")}
                  </td>
                  <td className="py-2 px-4 border-b">
                    <div className="flex gap-4">
                      <FaEye
                        size={20}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => {
                          console.log(item);
                          setPdf("https://api.psklavinlab.com/" + item?.URL);
                          setShowPdf(true);
                        }}
                      />
                      <CiTrash
                        size={20}
                        color="#0363B0"
                        className="cursor-pointer"
                        onClick={() => {
                          setDetail(item);
                          setDeleteModal(true);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            />
          </div>
        </div>
      )}

      <CustomModal
        small={true}
        isModalOpen={showPdf}
        setIsModalOpen={setShowPdf}
        A4
      >
        <div style={modalStyles}>
          <Worker
            className="no_scroll"
            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
          >
            <div style={viewerStyles}>
              <Viewer fileUrl={pdf} plugins={[defaultLayoutPluginInstance]} />
            </div>
          </Worker>
        </div>
      </CustomModal>

      <Delete_Modal
        handleDelete={() => handleDelete("single")}
        isModalOpen={deleteModal}
        setIsModalOpen={setDeleteModal}
        loading={loading}
      />
    </>
  );
};

export default QuotationList;

const modalStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  overflow: "auto",
};

const viewerStyles = {
  width: "80vw", // 80% of the viewport width
  height: "80vh", // 80% of the viewport height
};
