import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../../redux/store";
import { fetchLead_list, fetchService_list } from "../../../redux/slice/dataSlice";
import { postApiToken } from "../../../utils/apis";
import { openNotification } from "../../../App";
import { generateQuotationNumber } from "../../../utils/utils";
import CustomInput, { InputInline } from "../../../components/CustomInput";
import { CustomModal } from "../../../components/modals";
import AppointmentFormat from "./AppointmentFormat";

const Appointment = ({ id, employeeId,parentModal,edit,setParentModal }) => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const quotationRef = useRef();
  const navigate = useNavigate();
  const { lead_list, services_list } = useSelector((state) => state.allData);
  console.log(employeeId)

  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [quotationDetail, setQuotationDetail] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [singleData, setSingleData] = useState({
    service: {},
    value: "",
    qty: "",
    price: "",
  });

  useEffect(() => {
    store.dispatch(fetchLead_list());
    store.dispatch(fetchService_list());
  }, []);

  const validateField = (name, value) => {
    if (!value.trim()) return "This field is required";

    switch (name) {
      case "EMAIL":
        return !validateEmail(value) ? "Invalid email format" : "";
      case "ZIPCODE":
      case "SALARY":
      case "WORKING_HOURS":
        return !validateNumber(value) ? "Must be a positive number" : "";
      default:
        return "";
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateNumber = (number) => !isNaN(number) && number > 0;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuotationDetail((prev) => ({ ...prev, [name]: value.trimStart() }));
    setErrors((prev) => ({ ...prev, [name]: validateField(name, value) }));
  };

  const getSubServicePriceById = (subServiceId, qty) => {
    const service = services_list.find((service) => service.ID === subServiceId);
    return service ? service.PRICE * qty : null;
  };

  const removeService = (index) => {
    setSelectedServices((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddService = () => {
    if (!singleData.value || !singleData.price || !singleData.qty) {
      openNotification("info", "Info", "Please fill all the fields");
      return;
    }
    setSelectedServices([...selectedServices, singleData]);
    setSingleData({ service: {}, value: "", qty: "", price: "" });
  };

  const handlePreview = () => {
    const requiredFields = [
      "DATE", "RECIPIENT_NAME", "RECIPIENT_ADDRESS", "ZIPCODE", "POSITION",
      "START_DATE", "DEPARTMENT_NAME", "REPORTING_TO", "WORKING_HOURS", "SALARY"
    ];

    const newErrors = requiredFields.reduce((acc, field) => {
      const error = validateField(field, quotationDetail[field] || "");
      if (error) acc[field] = error;
      return acc;
    }, {});

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      openNotification("info", "Info", "Please fill all the fields");
      return;
    }

    setShowPreview(true);
  };

  const quotationNumber = generateQuotationNumber({
    invoiceNumber: 1,
    consumerFirstName: quotationDetail?.CONTACT_PERSON,
    consumerLastName: "L",
    date: new Date(),
    quotationCountForUser: 1,
  });

  return (
    <div className="bg-white p-4">
      <h1 className="text-[20px] font-semibold mb-4">{edit?'Update Appointment':'Appointmehnt'} Letter</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {["RECIPIENT_NAME", "STATE", "CITY", "DATE", "RECIPIENT_ADDRESS", "ZIPCODE", "POSITION", "START_DATE", "DEPARTMENT_NAME", "REPORTING_TO", "WORKING_HOURS", "SALARY"].map((field) => (
          <InputInline
            key={field}
            label={field.replace("_", " ")}
            placeholder={`Enter ${field.replace("_", " ")}`}
            value={quotationDetail[field] || ""}
            error={errors[field]}
            name={field}
            onChange={handleChange}
            type={field==='DATE'||field==='START_DATE'?'date':''}
          />
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <CustomButton title="Preview" onClick={handlePreview} />
      </div>

      <CustomModal A4 title="" isModalOpen={showPreview} setIsModalOpen={setShowPreview}>
        <AppointmentFormat
          quotationRef={quotationRef}
          quotation_detail={quotationDetail}
          list={selectedServices}
          mail={quotationDetail?.EMAIL}
          company_name={quotationDetail?.COMPANY_NAME}
          recipientName={quotationDetail?.RECIPIENT_NAME}
          quotation_number={quotationNumber}
          recipientAddress={`${quotationDetail?.RECIPIENT_ADDRESS}, ${quotationDetail?.STATE}, ${quotationDetail?.CITY}, ${quotationDetail?.ZIPCODE}`}
          reference={quotationDetail?.REFERENCE}
          setClose={setShowPreview}
          setDetail={setQuotationDetail}
          city={quotationDetail?.CITY}
          state={quotationDetail?.STATE}
          zip={quotationDetail?.ZIPCODE}
          item={quotationDetail}
          USER_ID={id}
          employeeId={employeeId}
          setParentModal={setShowPreview}
          parentModal={showPreview}
          setParentModal1={setParentModal}
          parentModal1={parentModal}
        />
      </CustomModal>
    </div>
  );
};

export default Appointment;
