import React, { useState } from 'react';
import { TextField, Button, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
  gap: '20px',
  justifyContent: 'center',
  alignItems: 'flex-start',
});

const ProfileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  maxWidth: '300px',
  width: '100%',
  height: '100% !important',
  padding: '50px 60px 40px 50px',
});

const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  flex: 1,
});

const ProfileImage = styled('img')({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  marginBottom: '20px',
  cursor: 'pointer',
});

const ProfileInput = styled(TextField)({
  marginBottom: '16px',
});

const SaveButton = styled(Button)({
  alignSelf: 'flex-end',
  marginTop: '20px',
});

const CreateUserProfile = () => {
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    address: '',
    state: '',
    city: '',
    zip: '',
    about: '',
    publicProfile: true,
  });

  const options = countryList().getData();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Container>
      <ProfileContainer className="">
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          id="profile-image-upload"
          onChange={handleProfileImageChange}
        />
        <label htmlFor="profile-image-upload">
          <ProfileImage
            src={profileImage || 'https://via.placeholder.com/100'}
            alt="Profile"
            onClick={() => document.getElementById('profile-image-upload').click()}
          />
        </label>
        <p className="text-center text-sm">
          Allowed *.jpeg, *.jpg, *.png, *.gif <br />
          max size of 3 Mb
        </p>
        <div className="flex flex-col mt-10 gap-6">
          <FormControlLabel
            control={
              <Switch
                checked={userData.publicProfile}
                onChange={(e) =>
                  setUserData((prevData) => ({
                    ...prevData,
                    publicProfile: e.target.checked,
                  }))
                }
              />
            }
            label="Public profile"
          />
        </div>
      </ProfileContainer>

      <FormContainer className="">
        <div className="flex gap-6">
          <div>
            <ProfileInput
              label="Name"
              name="name"
              value={userData.name}
              onChange={handleInputChange}
              fullWidth
            />
            <ProfileInput
              label="Email address"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              fullWidth
            />
            <div
              style={{
                marginBottom: '16px',
                position: 'relative',
                bottom: '20px',
                marginTop: '10px',
              }}
            >
              <label>Phone number</label>
              <PhoneInput
                country={'us'}
                value={phone}
                onChange={setPhone}
                inputStyle={{ width: '100%', height: '60px' }}
                buttonStyle={{ border: 'none', background: 'none' }}
              />
            </div>
            <ProfileInput
              style={{ position: 'relative', bottom: '25px' }}
              label="Address"
              name="address"
              value={userData.address}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div style={{ position: 'relative', bottom: '20px' }}>
            <div style={{ marginBottom: '16px' }}>
              <label>Country</label>
              <Select
                options={options}
                value={country}
                onChange={setCountry}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '56px',
                    background: 'white',
                  }),
                  
                }}
              />
            </div>
            <ProfileInput
              label="State/region"
              name="state"
              value={userData.state}
              onChange={handleInputChange}
              fullWidth
            />
            <ProfileInput
              style={{ marginTop: '10px' }}
              label="City"
              name="city"
              value={userData.city}
              onChange={handleInputChange}
              fullWidth
            />
            <ProfileInput
              label="Zip/code"
              name="zip"
              value={userData.zip}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
        </div>
        <ProfileInput
          label="About"
          name="about"
          value={userData.about}
          onChange={handleInputChange}
          multiline
          rows={4}
          fullWidth
        />
        <SaveButton variant="contained" color="primary">
          Save changes
        </SaveButton>
      </FormContainer>
    </Container>
  );
};

export default CreateUserProfile;
