import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import HeaderText from "../../components/HeaderText";
import LineGraph from "./LineGraph";
import BarGraph from "./BarGraph";
import PieGraph from "./PieGraph";
import { GrServices } from "react-icons/gr";
import { SlGraph } from "react-icons/sl";
import { FiUsers } from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import { FaApple } from "react-icons/fa";
import { BiLogoPlayStore } from "react-icons/bi";
import { Avatar } from "antd";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import {
  fetchAdmin_list,
  fetchCustomer_list,
  fetchEmployee_list,
  fetchManager_list,
  fetchService_list,
} from "../../redux/slice/dataSlice";
import moment from "moment";

const Dashboard = () => {
  const [employee_list,setEmployee_list]=useState([]);
  const {
    services_list,
    services_loading,
    customer_list,
    admin_list,
    manager_list,
  } = useSelector((state) => state.allData);
  const user = JSON.parse(localStorage.getItem("lavin"));

  const handleData = async () => {
    try {
      const response = await fetch(
        "https://api.psklavinlab.com/api/team-structure/employee-list",
        {
          headers: {
            Authorization: `bearer ${user?.TOKEN}`,
          },
        }
      );
      const resJson = await response.json();
      console.log(resJson?.data);
      setEmployee_list(resJson?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    store.dispatch(fetchService_list());
    store.dispatch(fetchCustomer_list());
    store.dispatch(fetchAdmin_list());
    store.dispatch(fetchManager_list());
    handleData();
  }, []);
  return (
    <div>
      <HeaderText>Dashboard</HeaderText>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-3">
        <Card>
          <div className="flex gap-3 items-center">
            <div className="bg-slate-200 rounded-full p-2 self-start items-start">
              <GrServices size={20} color="#0363B0" />
            </div>
            <div>
              <h1 className="text-[#0363B0] text-[18px] font-semibold font-calibri">
                {services_list?.length}
              </h1>
              <p className="text-[#010101] text-[14px] font-normal font-calibri m-0 p-0">
                Total Services
              </p>
            </div>
          </div>
          {/* <div className="flex items-center gap-2 justify-end">
            <SlGraph size={18} color="#0363B0" />
            <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
              4% High
            </p>
          </div> */}
        </Card>
        <Card>
          <div className="flex gap-3 items-center">
            <div className="bg-slate-200 rounded-full p-2 self-start items-start">
              <FiUsers size={20} color="#0363B0" />
            </div>
            <div>
              <h1 className="text-[#0363B0] text-[18px] font-semibold font-calibri">
                {customer_list?.length}
              </h1>
              <p className="text-[#010101] text-[14px] font-normal font-calibri m-0 p-0">
                Total Customers
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-end">
            <div className="flex items-center gap-2 ">
              <div className="p-[3px] bg-green-700 rounded-full" />
              <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
                Active 150
              </p>
            </div>
            <div className="flex items-center gap-2 ">
              <div className="p-[3px] bg-red-700 rounded-full" />
              <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
                Inactive 50
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex gap-3 items-center">
            <div className="bg-slate-200 rounded-full p-2 self-start items-start">
              <FiUsers size={20} color="#0363B0" />
            </div>
            <div>
              <h1 className="text-[#0363B0] text-[18px] font-semibold font-calibri">
                {admin_list?.length}
              </h1>
              <p className="text-[#010101] text-[14px] font-normal font-calibri m-0 p-0">
                Total Admins
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-end">
            <SlGraph size={18} color="#0363B0" />
            <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
              4% High
            </p>
          </div>
        </Card>
        <Card>
          <div className="flex  gap-3 items-center">
            <div className="bg-slate-200 rounded-full p-2 self-start items-start">
              <FiUsers size={20} color="#0363B0" />
            </div>
            <div>
              <h1 className="text-[#0363B0] text-[18px] font-semibold font-calibri">
                {employee_list?.length}
              </h1>
              <p className="text-[#010101] text-[14px] font-normal font-calibri m-0 p-0">
                Total Employees
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-end">
            <SlGraph size={18} color="#0363B0" />
            <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
              4% High
            </p>
          </div>
        </Card>
        <Card>
          <div className="flex gap-3 items-center">
            <div className="bg-slate-200 rounded-full p-2 self-start items-start">
              <FiUsers size={20} color="#0363B0" />
            </div>
            <div>
              <h1 className="text-[#0363B0] text-[18px] font-semibold font-calibri">
                {manager_list?.length}
              </h1>
              <p className="text-[#010101] text-[14px] font-normal font-calibri m-0 p-0">
                Total Managers
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-end">
            <div className="flex items-center gap-2 ">
              <div className="p-[3px] bg-green-700 rounded-full" />
              <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
                Active 100
              </p>
            </div>
            <div className="flex items-center gap-2 ">
              <div className="p-[3px] bg-red-700 rounded-full" />
              <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
                Inactive 45
              </p>
            </div>
          </div>
        </Card>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
        <div className="bg-white shadow-lg p-3 w-full h-[350px] rounded-xl my-shadow ">
          {/* <p></p> */}
          <LineGraph />
        </div>
        <div className="bg-white shadow-lg p-3 w-full h-[350px] rounded-xl my-shadow ">
          <BarGraph />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 mt-3">
        <div className="bg-white shadow-lg p-2 w-full h-[250px] rounded-xl my-shadow  ">
          <PieGraph />
        </div>
        <div className="bg-white shadow-lg p-2 w-full h-[250px] rounded-xl my-shadow ">
          <PieGraph />
        </div>
        <div className="bg-white shadow-lg p-3 w-full h-[250px] rounded-xl my-shadow overflow-hidden ">
          <div className="flex items-center justify-between">
            <p className="text-[#010101] text-[16px] font-bold font-calibri m-0 p-0">
              Total Customers
            </p>
            <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
              View All
            </p>
          </div>
          <div className="overflow-hidden flex flex-col gap-2 mt-2">
            {customer_list?.slice(0, 6)?.map((v, i) => (
              <FarmerView item={v} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ children }) => {
  return (
    <div className="bg-white p-3 w-full rounded-xl my-shadow flex gap-2 flex-col">
      {children}
    </div>
  );
};

const FarmerView = ({ item }) => {
  console.log(item);
  return (
    <div className="flex gap-3">
      <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
      <div className="flex flex-col flex-1">
        <p className="text-[#010101] text-[12px] font-semibold font-calibri m-0 p-0">
          {item?.CUSTOMER_NAME}
        </p>
        <p className="text-gray-400 text-[10px] font-normal font-calibri m-0 p-0">
          Created at {moment(item?.CREATED_AT).format("DD-MMM-YYYY")}
        </p>
      </div>
      <div className="flex items-center gap-2 ">
        <div className="p-[3px] bg-green-700 rounded-full" />
        <p className="text-[#010101] text-[12px] font-normal font-calibri m-0 p-0">
          Online
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
