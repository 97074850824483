// export function generateQuotationNumber({
//   companyName,
//   invoiceNumber,
//   consumerFirstName,
//   consumerLastName,
//   date,
//   quotationCountForUser,
// }) {
//   // Extract the first letter of each word in the company name
//   const companyInitials = companyName
//     .split(" ")
//     .map((word) => word[0])
//     .join("")
//     .toUpperCase();

//   // Get the first letter of the consumer's first and last name
//   const consumerInitials =
//     consumerFirstName[0].toUpperCase() + consumerLastName[0].toUpperCase();

//   // Format the date
//   const day = String(date.getDate()).padStart(2, "0"); // Two-digit day
//   const month = String(date.getMonth() + 1).padStart(2, "0"); // Two-digit month (getMonth() returns 0-based month)

//   // Combine all parts to form the quotation number
//   const quotationNumber = `${companyInitials}${invoiceNumber}${consumerInitials}${day}${month}${String(
//     quotationCountForUser
//   ).padStart(2, "0")}`;

//   return quotationNumber;
// }


export function generateQuotationNumber({
  invoiceNumber,
  consumerFirstName = "",
  consumerLastName = "",
  date,
  quotationCountForUser,
}) {
  // Extract the first letter of each word in the company name
  //   const companyInitials = companyName
  //     .split(" ")
  //     .map((word) => word[0])
  //     .join("")
  //     .toUpperCase();

  // Get the first letter of the consumer's first and last name
  const consumerInitials =
    consumerFirstName[0]?.toUpperCase() + consumerLastName[0]?.toUpperCase();

  // Format the date
  const day = String(date.getDate()).padStart(2, "0"); // Two-digit day
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Two-digit month (getMonth() returns 0-based month)

  // Combine all parts to form the quotation number
  //   const quotationNumber = `${companyInitials}${invoiceNumber}${consumerInitials}${day}${month}${String(
  const quotationNumber = `LL/${invoiceNumber}${consumerInitials}/${day}${month}/${String(
    quotationCountForUser
  ).padStart(2, "0")}`;

  return quotationNumber;
}
export function generatePONumber({ name = "", TOTALPO=0 }) {
  const words = name.split(" ");
  let Name;

  if (words.length >= 2) {
    const firstLetter = words[0][0];
    const secondLetter = words[1][0]; // Fallback to the second letter of the first word if the second letter of the second word doesn't exist
    Name = firstLetter?.toUpperCase() + secondLetter?.toUpperCase();
  } else {
    const firstTwoLetters = words[0].slice(0, 2);
    Name = firstTwoLetters?.toUpperCase();
  }

  // Format the date
  const day = String(new Date().getDate()).padStart(2, "0"); // Two-digit day
  const month = String(new Date().getMonth() + 1).padStart(2, "0"); // Two-digit month (getMonth() returns 0-based month)
  const year = String(new Date().getFullYear()).slice(-2); // Last two digits of the year

  const PONumber = `LL/PO/${String(TOTALPO).padStart(
    2,
    "0"
  )}/${day}${month}${year}`;

  return PONumber;
}
export function generateCustomerNumber({ name = "", TOTALCN }) {
  const words = name.split(" ");

  let Name;

  if (words.length >= 2) {
    const firstLetter = words[0][0];
    const secondLetter = words[1][0]; // Fallback to the second letter of the first word if the second letter of the second word doesn't exist
    Name = firstLetter?.toUpperCase() + secondLetter?.toUpperCase();
  } else {
    const firstTwoLetters = words[0].slice(0, 2);
    Name = firstTwoLetters?.toUpperCase();
  }
  // Format the date
  const day = String(new Date().getDate()).padStart(2, "0"); // Two-digit day
  const month = String(new Date().getMonth() + 1).padStart(2, "0"); // Two-digit month (getMonth() returns 0-based month)
  const year = String(new Date().getFullYear()).slice(-2); // Last two digits of the year

  const CNNUMBER = `LL/${Name}/${String(TOTALCN).padStart(
    2,
    "0"
  )}/${day}${month}${year}`;

  return CNNUMBER;
}

export function validateGST(gstNumber) {
  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/;
  return gstRegex.test(gstNumber);
}

export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
