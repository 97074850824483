import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from '../../../assets/svg/Logo.svg'
import CustomButton from "../../../components/CustomButton";

const OfferFormat = ({ item, employeeId, USER_ID }) => {
  console.log(item);
  const letterRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("lavin"));

  const sendFormData = async () => {
    const formData = new FormData();
    console.log(item)
    formData.append("RECIPIENT_NAME", item.RECIPIENT_NAME);
    formData.append("STATE", item.STATE);
    formData.append("CITY", item.CITY);
    formData.append("RECIPIENT_ADDRESS", item.RECIPIENT_ADDRESS);
    formData.append("ZIPCODE", item.ZIPCODE);
    formData.append("DATE", item.DATE);
    formData.append("POSITION", item.POSITION);
    formData.append("START_DATE", item.START_DATE);
    formData.append("DEPARTMENT_NAME", item.DEPARTMENT_NAME);
    formData.append("REPORTING_TO", item.REPORTING_TO);
    formData.append("WORKING_HOURS", item.WORKING_HOURS);
    formData.append("SALARY", item.SALARY);
    formData.append("EMAIL_ADDRESS", "kumag@gmail.com"); // Replace with actual email if needed
    formData.append("EMPLOYEE_ID", employeeId);
    console.log(formData)

    try {
      const file = await generatePDF();
      formData.append("FILE", file);
      formData.append("USER_ID", USER_ID);
      console.log(formData)

      const response = await fetch(
        "https://api.psklavinlab.com/api/hr-management-offerlatter/create",
        {
          method: "POST",
          headers: {
            Authorization: `bearer ${user?.TOKEN}`, // Ensure `user` and `TOKEN` are defined in your context
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data");
      }
      else{
        
        window.location.reload()
      }
      alert('offer letter released')
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const generatePDF = async () => {
    try {
      const input = letterRef.current;
      const canvas = await html2canvas(input, {
        scale: 2,
      });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const scaleX = pdfWidth / imgWidth;
      const scaleY = pdfHeight / imgHeight;
      const scale = Math.min(scaleX, scaleY);
      const contentWidth = imgWidth * scale;
      const contentHeight = imgHeight * scale;

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        contentWidth,
        contentHeight,
        "",
        "FAST"
      );
      const pdfBlob = pdf.output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="font-calibri text-sm">
      <div className="border">
        <div
          className="p-8 max-w-[1000px] mx-auto bg-white text-[12px] my-4"
          ref={letterRef}
        >
          <div className="flex pb-8 justify-end">
            <img src={logo} alt="" />
          </div>
          <p className="text-center text-[18px] font-bold">OFFER LETTER</p>
          <p className="text-right text-[14px]">Date: {item?.START_DATE}</p>
          <br />
          <p className="text-[16px] my-1">{item?.RECIPIENT_NAME}</p>
          <p className="text-[16px] my-1">
            {item?.RECIPIENT_ADDRESS.replace(/\n/g, "<br />")}
          </p>
          <br />
          <p className="text-[16px] my-1">Sub: Offer Letter</p>
          <p className="text-[16px] my-1">
            Dear {item?.RECIPIENT_NAME},
          </p>
          <p className="text-[16px] my-1">Congratulations!</p>
          <p className="text-[16px] my-1">
            This has with reference to your application and subsequent interview
            you had with us. We are pleased to offer you that you have been
            selected for the position of <strong>{item?.POSITION}</strong>. You
            will be paid salary gross pay of <strong>{item?.SALARY}</strong> as
            discussed at the time of your interview with us.
          </p>
          <p className="text-[16px] my-1">
            You will be entitled to benefits as per company's policy in force
            from time to time and we look forward to your joining our team for a
            long and successful association.
          </p>
          <p className="text-[16px] my-1">
            On the day of reporting, you may bring the following documents for
            our verification and records:
          </p>
          <ul className="list-disc text-[14px] ml-8">
            <li>Updated Resume</li>
            <li>
              All Educational Credentials – Originals and one photocopy each
              (incl Marks list)
            </li>
            <li>
              Current Employer’s Relieving Letter / Resignation approval (If
              applicable)
            </li>
            <li>Last 3 pay slips (with Salary break-up)</li>
            <li>Personal ID proof</li>
            <li>Residence proof</li>
            <li>2 Similar Passport size Colour Photographs</li>
          </ul>
          <p className="text-[16px] my-1">
            We would issue the detailed Order of Appointment after your joining.
            Please sign the duplicate copy of this Offer Letter as token of
            acceptance of offer.
          </p>
          <p className="text-[16px] my-1">
            You will be under probation for an initial period of six months
            after which a performance review will be conducted to assess the
            performances to-date, and to clarify or modify these arrangements,
            as the need may arise.
          </p>
          <p className="text-[16px] my-1">
            We are confident you will be able to make a significant contribution
            to the success of our (Lavin Laboratories, Hyderabad) and look
            forward to working with you.
          </p>
          <br />
          <p className="text-[16px] my-1">For Lavin laboratories</p>
          <p className="text-[16px] my-1">Dr. Pabba Shivakrishna</p>
          <p className="text-[16px] my-1">Authorized Signatory</p>
          <br />
          <p className="text-[16px] my-1">
            <img
              src="path/to/stamp/image.png"
              alt="Stamp"
              style={{ width: "100px", height: "auto" }}
            />
          </p>
          <p className="text-[16px] my-1">PSK Lavin Laboratories Pvt. Ltd.</p>
        </div>
        <CustomButton
          className="px-4 float-right mt-4 py-2  text-white rounded self-center mb-4 mr-4"
          onClick={sendFormData}
          title={'Send Data'}
        />
      </div>
    </div>
  );
};

export default OfferFormat;
