// import React from "react";
// import { InboxOutlined } from "@ant-design/icons";
// import { message, Upload } from "antd";
// import CustomButton from "../../components/CustomButton";
// import { IoArrowBack } from "react-icons/io5";
// const { Dragger } = Upload;
// const props = {
//   name: "file",
//   multiple: true,
//   //   action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };
// const Attechment = ({ setActive_tab }) => (
//   <div>
//     <Dragger {...props} multiple>
//       <p className="ant-upload-drag-icon">
//         <InboxOutlined />
//       </p>
//       <p className="ant-upload-text">
//         Click or drag file to this area to upload
//       </p>
//       <p className="ant-upload-hint">
//         Support for a single or bulk upload. Strictly prohibited from uploading
//         company data or other banned files.
//       </p>
//     </Dragger>
//     <div className="flex justify-end mt-4 gap-4">
//       <CustomButton
//         icon={<IoArrowBack size={20} />}
//         title={"Back"}
//         onClick={() => setActive_tab(1)}
//       />
//       <CustomButton
//         title={"Save "}
//         onClick={() => setActive_tab(2)}
//         //   onClick={() => handleSubmit(false, {})}
//         // loading={loading}
//       />
//     </div>
//   </div>
// );
// export default Attechment;

import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import CustomButton from "../../components/CustomButton";
import { IoArrowBack } from "react-icons/io5";
import axios from "axios"; // Assuming you're using axios for API requests
import { useSelector } from "react-redux";
import { openNotification } from "../../App";
import { store } from "../../redux/store";
import { fetchCustomer_list } from "../../redux/slice/dataSlice";

const { Dragger } = Upload;

const Attechment = ({ setActive_tab, setAdd_modal, Datadetail }) => {
  const user = JSON.parse(localStorage.getItem("lavin"));
  const [fileList, setFileList] = useState([]);
  const { customer_list } = useSelector((state) => state.allData);
  const [loading, setLoading] = useState(false);

  const handleChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
    setFileList(info.fileList);
  };

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("FILES", file.originFileObj); // 'files' is the key for the file array
    });
    formData.append("STEP", "3");
    formData.append("ID", Datadetail?.ID);
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.psklavinlab.com/api/customer-po/create",
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
        data: formData,
      };

      const response = await axios.request(config);

      const res = await response.data;
      console.log(res);
      setLoading(false);
      if (res.success) {
        openNotification("success", "Success", res.message);
        store.dispatch(fetchCustomer_list());
        setAdd_modal(false);
      } else {
        openNotification("error", "Error", res.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
      message.error("File upload failed.");
      openNotification("error", "Error", "File upload failed.");
    }
  };

  return (
    <div>
      <Dragger
        name="file"
        multiple
        beforeUpload={(file) => {
          setFileList((prevList) => [...prevList, file]);
          return false; // Prevent automatic upload
        }}
        onChange={handleChange}
        fileList={fileList}
        onDrop={(e) => {
          console.log("Dropped files", e.dataTransfer.files);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from
          uploading company data or other banned files.
        </p> 
      </Dragger>
      <div className="flex justify-end mt-4 gap-4">
        {/* <CustomButton
          icon={<IoArrowBack size={20} />}
          title={"Back"}
          onClick={() => setActive_tab(1)}
        /> */}
        <CustomButton title={"Save"} onClick={handleUpload} loading={loading} />
      </div>
    </div>
  );
};

export default Attechment;
