export const testCategories = new Map([
    [
      "Water Testing",
      [
        "STP Water (Inlet)",
        "STP Water (Outlet)",
        "Dialysis Water",
        "Water Analysis C+M 22",
        "ETP (Inlet)",
        "ETP (Outlet)",
        "WTP Treated Water",
        "Domestic Water",
        "Swimming Pool Water",
        "Dialysis Water Chem",
        "Aquarium Water /Fishery",
        "Borewell Water",
        "Sump Water",
        "Cooling Tower Water",
        "RO Filter Water",
        "Canned Water",
        "Chiller Water"
      ]
    ],
    [
      "Soil Testing",
      [
        // Add any soil-related tests if available
      ]
    ],
    [
      "Air Testing",
      [
        "DG/Boiler Stack Monitoring",
        "AAQ Monitoring",
        "Compressed Air"
      ]
    ],
    [
      "Food Testing",
      [
        "Food Analysis",
        "OT-Validation",
        "Swab Analysis"
      ]
    ]
  ]);
  
  export const testCategoriesMap = {
    'STP Water (Inlet)': 'Water Testing',
    'STP Water (Outlet)': 'Water Testing',
    'Dialysis Water': 'Water Testing',
    'Water Analysis C+M 22': 'Water Testing',
    'ETP (Inlet)': 'Water Testing',
    'ETP (Outlet)': 'Water Testing',
    'WTP Treated Water': 'Water Testing',
    'Domestic Water': 'Water Testing',
    'Swimming Pool Water': 'Water Testing',
    'Dialysis Water Chem': 'Water Testing',
    'Aquarium Water /Fishery': 'Water Testing',
    'Borewell Water': 'Water Testing',
    'Sump Water': 'Water Testing',
    'Cooling Tower Water': 'Water Testing',
    'RO Filter Water': 'Water Testing',
    'Canned Water': 'Water Testing',
    'Chiller Water': 'Water Testing',
    'DG/Boiler Stack Monitoring': 'Air Testing',
    'AAQ Monitoring': 'Air Testing',
    'Compressed Air': 'Air Testing',
    'Food Analysis': 'Food Testing',
    'OT-Validation': 'Food Testing',
    'Swab Analysis': 'Food Testing'
  };

  export const testCategorieswithId = new Map([
    [
      "Water Testing",
      [
        { id: "e749f74f-9307-4a01-90e6-885703dc8543", name: "STP Water (Inlet)" },
        { id: "a4519a75-9b52-4e25-bfb3-946daa80d37e", name: "STP Water (Outlet)" },
        { id: "556af741-ae2e-443a-ab15-3c965fbdc50c", name: "Dialysis Water" },
        { id: "227e6827-952b-47d2-9131-4aea6003db48", name: "Water Analysis C+M 22" },
        { id: "ca371b72-3d22-4dc5-8c44-c1443c99ac39", name: "ETP (Inlet)" },
        { id: "033cf3f1-8e79-49a1-9d4d-c49f5485c0b5", name: "ETP (Outlet)" },
        { id: "589cbe66-77e8-4624-8139-48a1470681a4", name: "WTP Treated Water" },
        { id: "c6077224-6ef9-420d-9fd5-9831a2d9be1c", name: "Domestic Water" },
        { id: "e9a0fbdd-85a0-4a59-9a98-e00d543cab71", name: "Swimming Pool Water" },
        { id: "7db0f0d7-d649-4c7d-a123-1052f7138e0b", name: "Dialysis Water Chem" },
        { id: "294ae3e5-41ad-418b-b022-8e6274ab4e6e", name: "Aquarium Water / Fishery" },
        { id: "ca9afd8a-a5d0-4583-9c32-09149052fe5a", name: "Borewell Water" },
        { id: "d5e87d59-7332-40ae-9aa1-83b5815fe9c3", name: "Sump Water" },
        { id: "b8cacd8e-72d7-48c2-913f-b9e86dfc255c", name: "Cooling Tower Water" },
        { id: "a3d42c32-803b-455b-b38c-77c36ff16ce3", name: "RO Filter Water" },
        { id: "4ba91fc0-c9da-4f42-bf58-f845ce53f978", name: "Canned Water" },
        { id: "5b6b7b3f-1bda-4f8f-8756-14dd4422bfa6", name: "Chiller Water" }
      ]
    ],
    [
      "Soil Testing",
      [
        // Add soil-related tests if available
      ]
    ],
    [
      "Air Testing",
      [
        { id: "e1141f7d-1c49-4e9d-9862-e02e405468b1", name: "DG/Boiler Stack Monitoring" },
        { id: "9ce972f4-d730-4192-826a-ea66f42823ef", name: "AAQ Monitoring" },
        { id: "42984b72-c5e0-451e-9ee8-94b3e9dab785", name: "Compressed Air" }
      ]
    ],
    [
      "Food Testing",
      [
        { id: "087b3ffc-68bb-490f-a792-193f8feefb6d", name: "Food Analysis" },
        { id: "df4bcc4d-1895-474b-a959-ada24abcf00b", name: "OT-Validation" },
        { id: "74d5c1a1-93f3-4b26-994d-1924ba5a133e", name: "Swab Analysis" }
      ]
    ]
  ]);
  