import React, { useEffect, useState } from "react";
import CustomTable from "../../components/CustomTable";
import CustomButton from "../../components/CustomButton";
import HeaderText from "../../components/HeaderText";
import { useNavigate } from "react-router-dom";
import { CiEdit, CiTrash } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import TableHeader from "../../components/TableHeader";
import { Modal } from "antd";
import { postApiToken } from "../../utils/apis";
import { openNotification } from "../../App";
import AddLead from "./AddLead";
import UpdateLead from "./UpdateLead";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { fetchLead_list, fetchService_list } from "../../redux/slice/dataSlice";
import Loader from "../../components/Loader";
import { CustomModal } from "../../components/modals";

const LeadManagement = () => {
  const navigate = useNavigate();
  const { lead_list, lead_loading } = useSelector((state) => state.allData);
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);


  

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    store.dispatch(fetchService_list());
    store.dispatch(fetchLead_list());
  }, []);
  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  const handleStatusChange = (id, newStatus) => {
    // const updatedItems = currentItems.map((item) =>
    //   item.ID === id ? { ...item, STATUS: newStatus } : item
    // );
    // setCurrentItems(updatedItems);
  };

  const handleDelete = async (type) => {
    setLoading(true);
    const body = JSON.stringify({
      ID: type === "single" ? [detail.ID] : selected,
    });
    console.log(type, selected);
    const res = await postApiToken(`leads/delete-lead`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      store.dispatch(fetchLead_list());
      setIsModalOpen(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Filtered and paginated data
  const filteredData = lead_list.filter(
    (item) =>
      item.COMPANY_NAME.toLowerCase().includes(search.toLowerCase()) ||
      item.COMPANY_TYPE.toLowerCase().includes(search.toLowerCase()) ||
      item.CITY.toLowerCase().includes(search.toLowerCase()) ||
      item.LOCATION.toLowerCase().includes(search.toLowerCase())
  );

  const handleStatusUpdate = async (id, status) => {
    setLoading(true);
    const body = JSON.stringify({
      ID: id,
      STATUS: status,
    });
    const res = await postApiToken(`leads/update-status`, body);
    setLoading(false);
    if (res.success) {
      openNotification("success", "Success", res.message);
      setIsModalOpen(false);
    } else {
      openNotification("error", "Error", res.message);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div className="flex items-center justify-between">
        <HeaderText>All Lead Data</HeaderText>
        {/* <CustomButton
          icon={<FaPlus size={20} />}
          title={"Add Lead"}
          onClick={() => {
            setIsModalOpen1(true);
          }}
        /> */}
      </div>

      {lead_loading && lead_list?.length == 0 ? (
        <Loader />
      ) : (
        <div className="bg-[#fff] p-4 mt-4">
          <TableHeader
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            tableHeaders={["Company Name", "Company Type", "City", "Location"]}
            tableData={filteredData.map((e) => [
              e.COMPANY_NAME,
              e.COMPANY_TYPE,
              e.CITY,
              e.LOCATION,
            ])}
            onDeleteAll={() => handleDelete("all")}
            tableName={"Abhishek"}
            fileName={"pddd"}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={filteredData.length}
            onPageChange={(page) => setCurrentPage(page)}
            onLimitChange={(limit) => setItemsPerPage(limit)}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
          />
          <CustomTable
            list={lead_list}
            selected={selected}
            setSelected={setSelected}
            headerData={[
              "Company Name",
              "Created At",
              "Status",
              "Actions",
              "Follow Up",
            ]}
            bodyData={currentItems.map((item, index) => (
              <tr key={item.ID}>
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.ID)}
                    onChange={() => handleSelect(item.ID)}
                  />
                </td>

                <td
                  className="py-2 px-4 border-b cursor-pointer text-[#0363B0]"
                  onClick={() =>
                    navigate("/lead-generation/leads/follow-up", {
                      state: item,
                    })
                  }
                >
                  {item.COMPANY_NAME}
                </td>
                <td className="py-2 px-4 border-b cursor-pointer text-[#0363B0]">
                  {formattedDate(item?.CREATED_AT)}
                </td>
                {/* <td className="py-2 px-4 border-b">{item.COMPANY_TYPE}</td>
                <td className="py-2 px-4 border-b">{item.CITY}</td>
                <td className="py-2 px-4 border-b">{item.LOCATION}</td> */}
                <td className="py-2 px-4 border-b">
                  <select
                    value={item.STATUS} // Assuming item.STATUS contains the current status
                    onChange={(e) =>{
                      handleStatusUpdate(item.ID, e.target.value)
                      store.dispatch(fetchLead_list());
                    }
                    } // Trigger the status update on change
                    className="border border-gray-300 rounded-lg py-2 px-4 bg-white text-sm text-gray-700 shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in-out duration-150 transform hover:scale-105 cursor-pointer"
                  >
                    <option value="INITIATED">Initiated</option>
                    <option value="PENDING">Pending</option>{" "}
                    {/* Corrected the duplicate "initiated" */}
                    <option value="CONVERTED">Converted</option>
                    <option value="IN-REVIEW">In Review</option>
                    <option value="QUOTATION-SENT">Quotation Sent</option>
                  </select>
                </td>

                <td className="py-2 px-4 border-b ">
                  <div className="flex gap-4">
                    <CiEdit
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setDetail(item);
                        setIsModalOpen2(true);
                      }}
                    />
                    <CiTrash
                      size={20}
                      color="#0363B0"
                      className="cursor-pointer"
                      onClick={() => {
                        setDetail(item);
                        showModal();
                      }}
                    />
                  </div>
                </td>
                <td className="py-2 px-4 border-b">
                  <div>
                    <CustomButton
                      title={"Follow Up"}
                      onClick={() =>
                        navigate("/lead-generation/leads/follow-up", {
                          state: item,
                        })
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          />
        </div>
      )}
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={
          <div className="flex gap-4 justify-end">
            <CustomButton
              title={"Cancel"}
              className={"h-[28px] min-w-[80px]"}
              onClick={handleCancel}
            />
            <CustomButton
              loading={loading}
              onClick={() => handleDelete("single")}
              title={"Delete"}
              className={"h-[28px] min-w-[80px] bg-[#F00]"}
            />
          </div>
        }
      >
        <p className="font-calibri font-normal text-[16px]">
          Are you sure you want to delete detail
        </p>
      </Modal>
      {/* <CustomModal
        title={"Add Lead"}
        A4={true}
        isModalOpen={isModalOpen1}
        setIsModalOpen={setIsModalOpen1}
      >
        <AddLead setIsModalOpen={setIsModalOpen1} />
      </CustomModal> */}
      <UpdateLead
        isModalOpen={isModalOpen2}
        setIsModalOpen={setIsModalOpen2}
        detail={detail}
      />
    </>
  );
};

export default LeadManagement;
