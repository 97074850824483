import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";
import logo from '../../../assets/svg/Logo.svg'
import CustomButton from "../../../components/CustomButton";

const RelievingFormat = ({ item, employeeId, USER_ID }) => {
  const letterRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("lavin"));
  console.log(item);

  const sendFormData = async () => {
    const formData = new FormData();
    console.log(item);
    formData.append("RECIPIENT_NAME", item.RECIPIENT_NAME);
    formData.append("STATE", item.STATE);
    formData.append("CITY", item.CITY);
    formData.append("RECIPIENT_ADDRESS", item.RECIPIENT_ADDRESS);
    formData.append("ZIPCODE", item.ZIPCODE);
    formData.append("DATE", item.DATE);
    formData.append("POSITION", item.POSITION);
    formData.append("START_DATE", item.START_DATE);
    formData.append("END_DATE", item.END_DATE);
    formData.append("DEPARTMENT_NAME", item.DEPARTMENT_NAME);
    formData.append("REPORTING_TO", item.REPORTING_TO);
    formData.append("WORKING_HOURS", item.WORKING_HOURS);
    formData.append("SALARY", item.SALARY);
    formData.append("EMAIL_ADDRESS", "kumag@gmail.com"); // Replace with actual email if needed
    formData.append("EMPLOYEE_ID", employeeId);
    formData.append("POSSESSIVE_DETERMINER", item?.POSSESSIVE_DETERMINER);

    console.log(formData, "abhishek");

    try {
      const file = await generatePDF();
      formData.append("FILE", file);
      formData.append("USER_ID", USER_ID);
      console.log(formData);

      const response = await fetch(
        "https://api.psklavinlab.com/api/hr-management-relievinglatter/create",
        {
          method: "POST",
          headers: {
            Authorization: `bearer ${user?.TOKEN}`, // Ensure `user` and `TOKEN` are defined in your context
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data");
      }else{
        toast.success('Relieving Letter has been generated successfully');
        window.location.reload();
      }
      alert('Relieving Letter has been generated successfully');
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const generatePDF = async () => {
    try {
      const input = letterRef.current;
      const canvas = await html2canvas(input, {
        scale: 2,
      });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const scaleX = pdfWidth / imgWidth;
      const scaleY = pdfHeight / imgHeight;
      const scale = Math.min(scaleX, scaleY);
      const contentWidth = imgWidth * scale;
      const contentHeight = imgHeight * scale;

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        contentWidth,
        contentHeight,
        "",
        "FAST"
      );
      const pdfBlob = pdf.output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="font-sans text-sm">
      <div
        className="p-8 max-w-[1000px] mx-auto bg-white text-[12px] my-4"
        ref={letterRef}
      >
        <div className="flex pb-8 justify-end">
            <img src={logo} alt="" />
          </div>
        <p className="text-[20px] font-semibold text-center">
          RELIEVING LETTER
        </p>
        <p className="text-right">{new Date().getDate("DD-MMM-YYYY")}</p>
        <br />
        <p className="font-bold text-[18px]">PSK LAVIN LABORATORIES PVT. LTD</p>
        <p className='text-[16px]'>NMR Complex, 2nd Floor,</p>
        <p className='text-[16px]'>Nacharam - Mallapur Rd, Bhavani Nagar</p>
        <p className='text-[16px]'>Secunderabad, Telangana 500076</p>
        <p className='text-[16px]'>9849265173</p>
        <p className='text-[16px]'>lavilaboratories@gmail.com</p>
        <br />
        <p className='text-[16px]'>To</p>
        <p className="font-bold">{item?.RECIPIENT_NAME}</p>
        <p className='text-[16px]'>
          {item?.RECIPIENT_ADDRESS.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <p className='text-[16px]'>
          {item?.CITY}, {item?.STATE}, {item?.ZIPCODE}
        </p>
        <br />
        <p className='text-[16px]'>Dear {item?.RECIPIENT_NAME.split(" ")[1]},</p>
        <p className="font-bold">Subject: Relieving Letter</p>
        <br />
        <p className='text-[16px]'>
          This is to certify that{" "}
          <span className="font-bold">{item?.RECIPIENT_NAME}</span> was employed
          with PSK LAVIN LABORATORIES PVT LTD as a{" "}
          <span className="font-bold">{item?.POSITION}</span> from{" "}
          <span className="font-bold">{item?.START_DATE}</span> to{" "}
          <span className="font-bold">{item?.END_DATE}</span>. During their
          tenure, {item?.POSSESSIVE_DETERMINER} exhibited a high level of
          professionalism and dedication to {item?.POSSESSIVE_DETERMINER} work.
        </p>
        <br />
        <p className='text-[16px]'>
          <span className="font-bold">{item?.RECIPIENT_NAME}</span> has decided
          to resign from {item?.POSSESSIVE_DETERMINER} position for personal
          reasons, and we respect {item?.POSSESSIVE_DETERMINER} decision.{" "}
          {item?.POSSESSIVE_DETERMINER.charAt(0).toUpperCase() +
            item?.POSSESSIVE_DETERMINER.slice(1)}{" "}
          have completed all the necessary formalities and handed over{" "}
          {item?.POSSESSIVE_DETERMINER} responsibilities.
        </p>
        <br />
        <p className='text-[16px]'>
          We appreciate{" "}
          <span className="font-bold">{item?.RECIPIENT_NAME}</span>'s
          contributions to the company and wish {item?.POSSESSIVE_DETERMINER}{" "}
          all the best in {item?.POSSESSIVE_DETERMINER} future endeavors.
        </p>
        <br />
        <p className='text-[16px]'>
          Please feel free to contact us if you need any further information.
        </p>
        <br />
        <p className='text-[16px]'>Yours sincerely,</p>
        <p className="font-bold">Managing Director</p>
        <p className='text-[16px]'>PSK LAVIN LABORATORIES PVT. LTD</p>
      </div>
      <div className="px-[74px] flex justify-end">
      <CustomButton
          className="px-4 float-right mt-4 py-2  text-white rounded self-center mb-4 mr-4"
          onClick={sendFormData}
          title={'Send Data'}
        />
      </div>
    </div>
  );
};

export default RelievingFormat;
