import { Input } from "antd";

export const InputInline = ({
    label,
    placeholder,
    value,
    onChange,
    error,
    name,
    dis,
    special,
    type
    
  }) => {
    return (
      <div className="flex flex-col flex-1 font-calibri">
        <div className={`flex gap-6 items-center flex-1 ${special?'':'min-w-[300px]'}`}>
          {!dis && (
            <>
              <label className={`flex ${label?'min-w-[70px]':''}`}>{label}</label>{label&&':'}
            </>
          )}
          <Input
            variant="borderless"
            size="middle"
            className=" max-w-full font-calibri bg-[#e1e5e7] h-[35px] border-[#f1f1f1] bottom-0 outline-none rounded-none focus:outline-none hover:outline-none"
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            inputMode=""
            type={type}
          />
        </div>
        {error && (
          <label className="font-normal font-calibri text-[14px] text-[#f00] mt-1">
            {error}
          </label>
        )}
      </div>
    );
  };
  