import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import dataSlice from "./slice/dataSlice";

const rootReducer = combineReducers({
  allData: dataSlice,
});
// const persistConfig = {
//   key: "root",
//   storage: storage,
// };
// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //       immutableCheck: false,
  //       serializableCheck: false,
  //     }),
});
// export const persistor = persistStore(store);
