import React from "react";
import MainLayouts from "../Layouts";
import { Link, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import LeadManagement from "../pages/leadManagement";
import LeadDetail from "../pages/leadManagement/LeadDetail";
import FollowUp from "../pages/leadManagement/FollowUp";
import Quotation from "../pages/quotation";
import TeamAdmin from "../pages/team_structure/TeamAdmin";
import TeamManager from "../pages/team_structure/TeamManager";
import TeamEmployee from "../pages/team_structure/TeamEmployee";
import Login from "../pages/auth/Login";
import Attendance from "../pages/attendance";
import LAB from "../pages/lab";
import Customer from "../pages/customer";
import LeadStatus from "../pages/leadManagement/LeadStatus";
import LeadRecycleBin from "../pages/leadManagement/LeadRecycleBin";
import ServicesList from "../pages/services";
import SubService from "../pages/services/SubService";
import ContactList from "../pages/contactInfo";
import ContactTrash from "../pages/contactInfo/ContactTrash";
import CustomerTrash from "../pages/customer/CustomerTrash";
import QuotationList from "../pages/quotation/QuotationList";
import OfferLetter from "../pages/hr-portal/Offer";
import RelievingLetter from "../pages/hr-portal/Relieving";
import HrPortal from "../pages/hr-portal";
import JobApplicationForm from "../pages/JobApplication";
import ListOfLeads from "../pages/leadManagement/ListOfLeads";
import LeadAdd from "../pages/leadManagement/LeadAdd";
import ConvertedLeads from "../pages/leadManagement/ConvertedLeads";

const MainRouter = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayouts />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/quick-quotation" element={<Outlet />}>
            <Route
              path="/quick-quotation/generate-quotation"
              element={<Quotation />}
            />
            <Route path="/quick-quotation/list" element={<QuotationList />} />
          </Route>
          <Route path="/lead-generation" element={<Outlet />}>
            {/* <Route path="/lead-generation/leads" element={<LeadManagement />} /> */}
            <Route
              path="/lead-generation/list-of-leads"
              element={<ListOfLeads />}
            />
            <Route path="/lead-generation/add-lead" element={<LeadAdd />} />
            <Route path="/lead-generation/converted-leads" element={<ConvertedLeads />} />
            <Route
              path="/lead-generation/lead-status"
              element={<LeadStatus />}
            />
            {/* <Route
              path="/lead-generation/recycle-bin"
              element={<LeadRecycleBin />}
            /> */}
            <Route path="/lead-generation/details" element={<LeadDetail />} />
            <Route
              path="/lead-generation/leads/follow-up"
              element={<FollowUp />}
            />
          </Route>
          <Route path="/attendance" element={<Attendance />} />
          <Route path="/lab" element={<LAB />} />
          <Route path="/customer" element={<Outlet />}>
            <Route path="/customer/info" element={<Customer />} />
            <Route path="/customer/recycle-bin" element={<CustomerTrash />} />
          </Route>
          <Route path="/teams" element={<Outlet />}>
            <Route path="/teams/admin" element={<TeamAdmin />} />
            <Route path="/teams/manager" element={<TeamManager />} />
            <Route path="/teams/executive" element={<TeamEmployee />} />
          </Route>
          <Route path="/contact" element={<Outlet />}>
            <Route path="/contact/info" element={<ContactList />} />
            <Route path="/contact/recycle-bin" element={<ContactTrash />} />
          </Route>
          <Route path="/hr-portal" element={<Outlet />}>
            <Route path="/hr-portal/home" element={<HrPortal />} />
            <Route
              path="/hr-portal/send-application-form"
              element={<JobApplicationForm />}
            />
          </Route>
          <Route path="/services" element={<ServicesList />} />
          {/* <Route path="/" element={<Outlet />}>
            <Route path="/sub-services" element={<SubService />} />
          </Route> */}
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link to={"/"} className="text-[#0363B0]">
        Go to Home Page
      </Link>
    </div>
  );
};
const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("lavin"));

  if (user) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
};

export default MainRouter;
