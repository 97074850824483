import React from "react";

const CustomStepper = ({ list = [], active_tab = 0 }) => {
  return (
    <div className="w-full">
      <div className={`relative flex justify-between w-full`}>
        <ol className="relative w-full z-10 flex justify-between ">
          <div className="absolute h-0.5 left-[45px] right-[45px] top-[17px] bg-gray-200 z-0">
            <div
              className={`h-0.5 bg-[#0363B0] z-0`}
              style={{
                width: `calc(100% / ${list.length - 1} * ${active_tab})`,
              }}
            ></div>
          </div>
          {list.map((item, index) => (
            <li
              className="flex z-10 flex-col items-center gap-1 flex-1 p-2 max-w-[90px] text-center"
              key={index}
            >
              <span
                className={`size-5 rounded-full text-center text-[10px]/5 font-normal ${
                  active_tab >= index
                    ? "text-[#fff] bg-[#0363B0]"
                    : "text-[#000] bg-gray-100"
                }`}
              >
                {index + 1}
              </span>
              <span
                className={`break-words text-[12px] font-normal  ${
                  active_tab >= index ? "text-[#0363B0]" : "text-[#000]"
                } `}
              >
                {item}
              </span>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default CustomStepper;
