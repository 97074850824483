import React, { useState } from "react";
import Logo from "../../assets/svg/Logo.svg";
import { InputInline } from "../../components/hr-portal/InputInline";
import { FiTrash } from "react-icons/fi";

const JobApplicationForm = () => {
  const [formData, setFormData] = useState({
    FULL_NAME: "",
    DATE_OF_BIRTH: "",
    ADDRESS: "",
    EMAIL: "",
    PHONE: "",
    NATIONALITY: "",
    POSITION: "",
    DIVISION: "",
    DESIRED_SALARY: "",
    START_DATE: "",
    RESUME: null,
    COVER_LETTER: null,
    EDUCATION: [{ DEGREE: "", INSTITUTION: "", YEAR_OF_COMPLETION: "" }],
    PROFESSIONAL: [
      {
        COMPANY_NAME: "",
        JOB_TITLE: "",
        RESPONSIBILITIES: "",
        WORK_DURATION: "",
      },
    ],
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleEducationChange = (index, e) => {
    const { name, value } = e.target;
    const updatedEducation = formData.EDUCATION.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData({ ...formData, EDUCATION: updatedEducation });
  };

  const handleProfessionalChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProfessional = formData.PROFESSIONAL.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData({ ...formData, PROFESSIONAL: updatedProfessional });
  };

  const addEducation = () => {
    setFormData({
      ...formData,
      EDUCATION: [
        ...formData.EDUCATION,
        { DEGREE: "", INSTITUTION: "", YEAR_OF_COMPLETION: "" },
      ],
    });
  };

  const addProfessional = () => {
    setFormData({
      ...formData,
      PROFESSIONAL: [
        ...formData.PROFESSIONAL,
        {
          COMPANY_NAME: "",
          JOB_TITLE: "",
          RESPONSIBILITIES: "",
          WORK_DURATION: "",
        },
      ],
    });
  };

  const deleteEducation = (index) => {
    const updatedEducation = formData.EDUCATION.filter((_, i) => i !== index);
    setFormData({ ...formData, EDUCATION: updatedEducation });
  };

  const deleteProfessional = (index) => {
    const updatedProfessional = formData.PROFESSIONAL.filter((_, i) => i !== index);
    setFormData({ ...formData, PROFESSIONAL: updatedProfessional });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can add form validation and submission logic
    console.log("Form Data Submitted:", formData);
  };

  return (
    <div className="flex justify-center p-4 sm:p-8">
      <div className="w-full max-w-3xl">
        <div className="flex py-4 bg-white px-6 justify-between items-center">
          <p className="text-2xl sm:text-3xl md:text-[36px] font-semibold">
            Application for Employment
          </p>
          <img src={Logo} alt="Company Logo" className="h-8 sm:h-12" />
        </div>
        <form
          className="bg-white p-4 sm:p-8 rounded-lg shadow-md"
          onSubmit={handleSubmit}
        >
          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">
            Personal Information
          </h2>
          <div className="grid gap-4 max-w-full sm:gap-6 mb-4 sm:mb-6">
            <div className="grid gap-4 sm:gap-6 max-w-full sm:!grid-cols-1 md:grid-cols-2">
              <InputInline
                value={formData.FULL_NAME}
                error={errors.FULL_NAME}
                name={"FULL_NAME"}
                onChange={handleChange}
                label={"Full Name"}
                placeholder={"Enter Full Name"}
              />
              <InputInline
                value={formData.DATE_OF_BIRTH}
                error={errors.DATE_OF_BIRTH}
                name={"DATE_OF_BIRTH"}
                onChange={handleChange}
                label={"Date of Birth"}
                placeholder={"Enter Date of Birth"}
              />
            </div>
            <InputInline
              value={formData.ADDRESS}
              error={errors.ADDRESS}
              name={"ADDRESS"}
              onChange={handleChange}
              label={"Address"}
              placeholder={"Enter Address"}
              className="col-span-2"
            />
            <div className="grid gap-4 sm:gap-6" style={{ gridTemplateColumns: "1fr 0.7fr" }}>
              <InputInline
                value={formData.EMAIL}
                error={errors.EMAIL}
                name={"EMAIL"}
                onChange={handleChange}
                label={"Email"}
                placeholder={"Enter Email"}
              />
              <InputInline
                value={formData.PHONE}
                error={errors.PHONE}
                name={"PHONE"}
                onChange={handleChange}
                label={"Phone"}
                placeholder={"Enter Phone"}
              />
            </div>
            <InputInline
              value={formData.NATIONALITY}
              error={errors.NATIONALITY}
              name={"NATIONALITY"}
              onChange={handleChange}
              label={"Nationality"}
              placeholder={"Enter Nationality"}
              className="col-span-2"
            />
          </div>

          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">
            Position Information
          </h2>
          <div className="grid gap-4 sm:gap-6 mb-4 sm:mb-6">
            <InputInline
              value={formData.POSITION}
              error={errors.POSITION}
              name={"POSITION"}
              onChange={handleChange}
              label={"Position Applied For"}
              placeholder={"Enter Position"}
            />
            <div className="grid gap-4 sm:gap-6" style={{ gridTemplateColumns: "1fr 0.7fr" }}>
              <InputInline
                value={formData.DIVISION}
                error={errors.DIVISION}
                name={"DIVISION"}
                onChange={handleChange}
                label={"Division"}
                placeholder={"Enter Division"}
              />
              <InputInline
                value={formData.DESIRED_SALARY}
                error={errors.DESIRED_SALARY}
                name={"DESIRED_SALARY"}
                onChange={handleChange}
                label={"Desired Salary"}
                placeholder={"Enter Desired Salary"}
              />
            </div>
            <InputInline
              value={formData.START_DATE}
              error={errors.START_DATE}
              name={"START_DATE"}
              onChange={handleChange}
              label={"Date Available to Start"}
              placeholder={"Enter Start Date"}
              className="col-span-3"
            />
          </div>

          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">
            Educational Background
          </h2>
          <div className="grid grid-cols-3 gap-2 sm:gap-6 mb-2">
            <p>Degree</p>
            <p>Institution</p>
            <p>Year of completion</p>
          </div>
          {formData.EDUCATION.map((edu, index) => (
            <div className="grid grid-cols-3 gap-2 sm:gap-6 mb-4 sm:mb-6" key={index}>
              <InputInline
                special
                value={edu.DEGREE}
                error={errors[`DEGREE_${index}`]}
                name={"DEGREE"}
                onChange={(e) => handleEducationChange(index, e)}
                placeholder={"Enter Degree"}
                dis
              />
              <InputInline
                special
                value={edu.INSTITUTION}
                error={errors[`INSTITUTION_${index}`]}
                name={"INSTITUTION"}
                onChange={(e) => handleEducationChange(index, e)}
                placeholder={"Enter Institution"}
                dis
              />
              <div className="flex items-center gap-2">
                <InputInline
                  special
                  value={edu.YEAR_OF_COMPLETION}
                  error={errors[`YEAR_OF_COMPLETION_${index}`]}
                  name={"YEAR_OF_COMPLETION"}
                  onChange={(e) => handleEducationChange(index, e)}
                  placeholder={"Enter Year of Completion"}
                  dis
                />
                <button
                  type="button"
                  onClick={() => deleteEducation(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FiTrash size={20} />
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addEducation}
            className="text-blue-500 hover:text-blue-700 mb-6"
          >
            + Add Education
          </button>

          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">
            Professional Background
          </h2>
          <div className="grid grid-cols-4 gap-2 sm:gap-6 mb-2">
            <p>Company Name</p>
            <p>Job Title</p>
            <p>Responsibilities</p>
            <p>Duration</p>
          </div>
          {formData.PROFESSIONAL.map((pro, index) => (
            <div className="grid grid-cols-4 gap-2 sm:gap-6 mb-4 sm:mb-6" key={index}>
              <InputInline
                special
                value={pro.COMPANY_NAME}
                error={errors[`COMPANY_NAME_${index}`]}
                name={"COMPANY_NAME"}
                onChange={(e) => handleProfessionalChange(index, e)}
                placeholder={"Enter Company Name"}
                dis
              />
              <InputInline
                special
                value={pro.JOB_TITLE}
                error={errors[`JOB_TITLE_${index}`]}
                name={"JOB_TITLE"}
                onChange={(e) => handleProfessionalChange(index, e)}
                placeholder={"Enter Job Title"}
                dis
              />
              <InputInline
                special
                value={pro.RESPONSIBILITIES}
                error={errors[`RESPONSIBILITIES_${index}`]}
                name={"RESPONSIBILITIES"}
                onChange={(e) => handleProfessionalChange(index, e)}
                placeholder={"Enter Responsibilities"}
                dis
              />
              <div className="flex items-center gap-2">
                <InputInline
                  special
                  value={pro.WORK_DURATION}
                  error={errors[`WORK_DURATION_${index}`]}
                  name={"WORK_DURATION"}
                  onChange={(e) => handleProfessionalChange(index, e)}
                  placeholder={"Enter Duration"}
                  dis
                />
                <button
                  type="button"
                  onClick={() => deleteProfessional(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <FiTrash size={20} />
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addProfessional}
            className="text-blue-500 hover:text-blue-700 mb-6"
          >
            + Add Professional Experience
          </button>

          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Attachments</h2>
          <div className="grid gap-4 sm:gap-6 mb-4 sm:mb-6">
            <InputInline
              type={"file"}
              error={errors.RESUME}
              name={"RESUME"}
              onChange={handleFileChange}
              label={"Upload Resume"}
              className="col-span-3"
            />
            <InputInline
              type={"file"}
              error={errors.COVER_LETTER}
              name={"COVER_LETTER"}
              onChange={handleFileChange}
              label={"Upload Cover Letter"}
              className="col-span-3"
            />
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Submit Application
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobApplicationForm;
