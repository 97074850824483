import React from "react";
import { ScaleLoader } from "react-spinners";
import HashLoader from "react-spinners/HashLoader";

const Loader = () => {
  return (
    <div className="my-10 items-center justify-center flex flex-col">
      <HashLoader color="#0363B0" size={50} />
      {/* <ScaleLoader color="#0363B0" size={50} /> */}
    </div>
  );
};

export default Loader;
