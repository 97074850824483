import React, { useState } from "react";

const CustomTable = ({
  list = [],
  headerData = [],
  bodyData,
  selected = [],
  setSelected,
  check = true,
  user_id = false,
}) => {
  return (
    <div className="container mx-auto overflow-auto ">
      <table className="min-w-full bg-white relative font-calibri">
        <thead className="sticky top-0 ">
          <tr>
            {check && (
              <td className="py-2 px-4 border-b">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    setSelected(
                      e.target.checked
                        ? list.map((item) => (user_id ? item.USER_ID : item.ID))
                        : []
                    )
                  }
                  checked={selected.length === list.length}
                />
              </td>
            )}
            {headerData?.map((item, index) => (
              <td
                className="py-2 min-w-fit px-4 border-b text-[#000] font-semibold text-left"
                key={index}
              >
                {item}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>{bodyData}</tbody>
      </table>
      {list?.length == 0 && (
        <div className="flex w-full items-center justify-center  my-6">
          <p className="text-[24px] text-center ">No Data Found</p>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
